<template>
  <p v-if="block" class="is-size-7 hero" :class="classColor">
    {{ $t('plan.blocks.'+block.name.toLowerCase()) }}
  </p>
</template>

<script>
export default{
  name: 'training-block-box',
  props: ['date'],
  data(){
    return {
      block: null,
    }
  },
  computed: {
    classColor(){
      if(this.block){
        return {
          'is-warning': this.block.name == 'BASE',
          'is-primary': this.block.name == 'BUILD',
          'is-info': this.block.name == 'RECOVERY' || this.block.name == 'PREPARATION',
          'is-secondary-light': this.block.name == 'TAPER' || this.block.name == 'TESTING',
        };
      }
      return {};
    },
  },
  methods: {
    setBlock(){
      this.block = this.ugetTrainingBlockForDay(this.date);
    },
  },
  mounted(){
    this.setBlock();
  },
  watch: {
    date(){
      this.setBlock();
    },
  },
}
</script>
