<template>
  <b-modal v-model="isActive" has-modal-card trap-focus full-screen>
    <div class="modal-card w-100p">
      <FeaturesList title="landing.our_offers" :showSelect="true"/>
    </div>
  </b-modal>
</template>

<script>
import FeaturesList from './FeaturesList';

export default{
  name: 'FeaturesListModal',
  data(){
    return {
      isActive: false,
    };
  },
  methods: {

  },
  mounted(){
    this.$eventBus.$on('show-modal-features', () => {
      this.isActive = true;
    });
    this.$eventBus.$on('close-modal-features', () => {
      this.isActive = false;
    });
    this.$eventBus.$on('features-selected-plan', () => {
      this.isActive = false;
    });
  },
  components: {
    FeaturesList,
  },
};
</script>

<style lang="scss" scoped>
.modal-card{
  overflow: auto;
}
</style>
