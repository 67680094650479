<template>
  <div class="Curve has-text-centered">
    <b-field  position="is-centered" :message="lastDaysText">
      <b-input custom-class="w-6" type="number" v-model.number="nbDays"/>
    </b-field>
    <div class="curveContainer">
      <CurveChart class="curveChart" ref="curveChart" v-if="entries && entries.length"
        :entries="entries" :bottom="30" :top="60" :right="80" :curveType="curveType" :domainStartAt="0"
        :name="name" :globalCurve="globalCurve" :powerModelCurve="powerModelCurve" :dates="dates"/>
      <div class="powerModel" v-if="powerModel && $root.isTablet()">
        <h4>{{ $t('curves.power_model') }}</h4>
        <table>
          <tr>
            <td><b>{{ $t('metrics.threshold_power') }}</b></td>
            <td>{{ powerModel.criticalPower }} W</td>
          </tr>
          <tr>
            <td><b>{{ $t('metrics.anaerobic_capacity') }}</b></td>
            <td>{{ (powerModel.anaerobicCapacity/1000).toFixed(1) }} kJ</td>
          </tr>
          <tr>
            <td><b>{{ $t('metrics.max_power') }}</b></td>
            <td>{{ powerModel.pmax }} W</td>
          </tr>
          <tr>
            <td><b>{{ $t('metrics.stamina') }}</b></td>
            <td>{{ (powerModel.stamina*100).toFixed(1) }} %</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import CurveChart from './CurveChart.vue';
const PowerModel =  require('@/../shared/powerModel.js');
import debounce from 'debounce';

export default {
  name: 'curve',
  props: {
    data: { type: Array, default: null },
    dataType: { type: String, default: 'globalData' },
    lastDate: { type: [Date, Object], default: () => new Date() },
    curveType: { type: String, default: 'power' },
  },
  computed: {
    lastDaysText(){ return this.$t(this.curveType === 'power' ? 'curves.power_last_days' : 'curves.heart_rate_last_days'); }
  },
  data() {
    return {
      name: this.curveType === 'power' ? this.$t('performance.power_curve') : this.$t('performance.heart_rate_curve'),
      entries: null,
      globalCurve: null,
      powerModel: null,
      powerModelCurve: null,
      dates: null,
      nbDays: 90,
      nbDaysLoaded: 90,
      reloadDebounce: debounce(this.reloadCurve, 1000),
    };
  },
  methods: {

    firstDate(){
      return this.unextDay(this.lastDate, -this.nbDays);
    },

    generatePowerModel(powerCurve = null){
      if(powerCurve){
        this.powerModel = PowerModel.fromPowerCurve(powerCurve);
      }
    },

    buildPowerModelCurve(){
      if(this.powerModel)
        return this.powerModel.buildPowerCurve(72000);
      return null;
    },

    createActivityPowerCurve(){
      this.get('/power_curve?first_date='+this.firstDate().getTime()+'&last_date='+this.lastDate.getTime()).then(data => {
        this.entries = this.data;
        this.globalCurve = data.power_curve;
        this.powerModelCurve = null;
        this.dates = null;
      }).catch(err => {
        console.error(err);
        this.entries = this.data;
        this.glovalCurve = null;
        this.powerModelCurve = null;
        this.dates = null;
      }).finally(() => {
        this.$nextTick(()=>{
          this.$refs.curveChart.rebuild();
        });
      });
    },

    createActivityHeartRateCurve(){
      this.get('/heart_rate_curve?first_date='+this.firstDate().getTime()+'&last_date='+this.lastDate.getTime()).then(data => {
        this.entries = this.data;
        this.globalCurve = data.heart_rate_curve;
        this.powerModelCurve = null;
        this.dates = null;
      }).catch(err => {
        console.error(err);
        this.entries = this.data;
        this.globalCurve = null;
        this.powerModelCurve = null;
        this.dates = null;
      }).finally(() => {
        this.$nextTick(()=>{
          this.$refs.curveChart.rebuild();
        });
      });
    },

    createActivityCurve(){
      switch(this.curveType){
        case 'power': return this.createActivityPowerCurve();
        case 'heart_rate': return this.createActivityHeartRateCurve();
      }
      console.error('Curve : not supported curve type: '+this.curveType);
    },

    createGlobalPowerCurve(){
      this.get('/power_curve?first_date='+this.firstDate().getTime()+'&last_date='+this.lastDate.getTime()).then(data => {
        this.entries = data.power_curve.curve_data;
        this.globalCurve = null;
        this.generatePowerModel(this.entries);
        this.powerModelCurve = this.buildPowerModelCurve();
        this.dates = data.power_curve.dates;
        this.$nextTick(()=>{
          this.$refs.curveChart.rebuild();
        });
      }).catch(err => {
        console.error(err);
      });
    },

    createGlobalHeartRateCurve(){
      this.get('/heart_rate_curve?first_date='+this.firstDate().getTime()+'&last_date='+this.lastDate.getTime()).then(data => {
        this.entries = data.heart_rate_curve.curve_data;
        this.globalCurve = null;
        this.powerModelCurve = null;
        this.dates = data.heart_rate_curve.dates;
        this.$nextTick(()=>{
          this.$refs.curveChart.rebuild();
        });
      }).catch(err => {
        console.error(err);
      });
    },

    createGlobalCurve(){
      switch(this.curveType){
        case 'power': return this.createGlobalPowerCurve();
        case 'heart_rate': return this.createGlobalHeartRateCurve();
      }
      console.error('Curve : not supported curve type: '+this.curveType);
    },

    createCurve(){
      switch(this.dataType){
        case 'activityData': return this.createActivityCurve();
        case 'globalData': return this.createGlobalCurve();
      }
      console.error('Curve : not supported data type: '+this.dataType);
    },

    reloadCurve(){
      //Prevent useless reloading when nbdays final change after debounce is the same
      if(this.nbDays != this.nbDaysLoaded){
        this.createCurve();
        this.nbDaysLoaded = this.nbDays;
      }
    }
  },
  mounted: function(){
    this.createCurve();
  },
  watch: {
    nbDays: function(){
      this.reloadDebounce();
    }
  },
  components: {
    CurveChart,
  },
}
</script>

<style lang="scss">
.curveContainer{
  width: 100%;
}
.curveChart{
  padding: 0;
}

.curveChart .line{
  fill: none;
  stroke-width: 1px;
  stroke: blue;
}

.curveChart .line1{
  fill: none;
  stroke-width: 1px;
  stroke: red;
}

.powerModel{
  border-style: solid;
  width: 250px;
  position: absolute;
  right: 20%;
  top: 250px;
}
</style>
