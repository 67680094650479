<!-- Editable Location -->
<!-- Editable field with location suggestion while typing it -->
<!-- Will save both the text and the coordinates -->
<template>
  <div class="editable-location">
    <div class="editable-location-title">
      <b>{{ title }}: </b>
    </div>
    <div v-show="!edit" v-on:click="startEdit()" class="value is-clickable">
      {{ value.name }}
    </div>
    <div v-show="edit" class="value">
      <b-field>
        <b-input @input.native="loadResults()" type="text" v-model="locationValue.name"/>
        <button class="button is-info" @click="save()">{{ $t('settings.save') }}</button>
        <button class="button" @click="cancel()">X</button>
      </b-field>
      <ul class='results' v-show="showResults">
        <li v-for="(result, i) in results" :key="i" class="result" v-on:click="setLocation(result)">
          {{ result.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import debounce from 'debounce';

export default{
  name: 'editableLocation',
  props: ['title', 'value'],
  data() {
    return {
      edit: false,
      locationValue: { name:'', lat: 0, lon: 0 },
      results: [],
      showResults: false,
      debounce: debounce(this.getResults, 1000), //1sec of delay when input change to reload results
    };
  },
  methods: {
    startEdit(){
      this.edit = true;
    },
    save(){
      //save only if there is a value
      if(this.locationValue.name.length > 0){
        //if lat and lon are not defined, take the coordinates of the first results (if no results don't save)
        if(this.locationValue.lat == 0 && this.locationValue.lon == 0){
          if(this.results.length == 0)
            return;
          this.locationValue.lat = this.results[0].lat;
          this.locationValue.lon = this.results[0].lon;
        }

        this.$emit('input', this.locationValue);
        this.edit = false;
      }
    },

    cancel(){
      this.edit = false;
    },

    setLocation(result){
      this.locationValue = result;
      this.showResults = false;
    },

    loadResults(){
      this.showResults = false;
      this.debounce();
    },

    //Fetch results to display from locationiq api
    //TODO: user locationiq server side to hide api token ?
    getResults(){
      this.results = [];
      if(this.locationValue.name.length > 0){
        fetch('https://eu1.locationiq.com/v1/search.php?key='+ process.env.VUE_APP_LOCATION_TOKEN +'&q='+ this.locationValue.name +'&format=json')
          .then(results => results.json()).then(results => {
            results.forEach(res => {
              this.results.push({ name: res.display_name, lat: res.lat, lon: res.lon });
            });
            if(this.results.length > 0){
              this.showResults = true;
              this.locationValue.lat = this.results[0].lat;
              this.locationValue.lon = this.results[0].lon;
            }
          }).catch(err => {
            console.error(err);
          });
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.editable-location{
  display: flex;
  align-items: flex-start;
}

.editable-location-title{
  width: 200px;
  height: 20px;
  text-align: right;
  padding-right: 10px;
}

.results{
  position: relative;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
  background-color: white;
}

.result{
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.result:hover {
  background-color: #4AAE9B;
  color: white;
}

.title{
  width: 200px;
  height: 20px;
  text-align: right;
  padding-right: 10px;
}

.value{
  padding-left: 10px;
  text-align: left;
  width: 500px;
  height: 20px;
}

</style>
