<template>
  <div class="weeksummary has-text-left">
    <h3 class="title is-4 mb-1">{{ $t('dashboard.week_summary') }}</h3>
    <h2 class="title is-6 capitalize mb-2 pl-3" v-if="block">{{ $t('plan.blocks.type_block', { block: $t('plan.blocks.'+block.name.toLowerCase()) }) }}</h2>
    <div class="columns is-desktop is-vcentered p-4">
      <div class="column is-flexible-desktop">
        <div class="week box">
          <table class="w-100p">
            <tr>
              <td class="td-rect"> <div class="day-rect" v-bind:style="getStyle(0)"/> <div class="day-rect planned-rect" v-bind:style="getPlannedStyle(0)"/> </td>
              <td class="td-rect"> <div class="day-rect" v-bind:style="getStyle(1)"/> <div class="day-rect planned-rect" v-bind:style="getPlannedStyle(1)"/></td>
              <td class="td-rect"> <div class="day-rect" v-bind:style="getStyle(2)"/> <div class="day-rect planned-rect" v-bind:style="getPlannedStyle(2)"/></td>
              <td class="td-rect"> <div class="day-rect" v-bind:style="getStyle(3)"/> <div class="day-rect planned-rect" v-bind:style="getPlannedStyle(3)"/></td>
              <td class="td-rect"> <div class="day-rect" v-bind:style="getStyle(4)"/> <div class="day-rect planned-rect" v-bind:style="getPlannedStyle(4)"/></td>
              <td class="td-rect"> <div class="day-rect" v-bind:style="getStyle(5)"/> <div class="day-rect planned-rect" v-bind:style="getPlannedStyle(5)"/></td>
              <td class="td-rect"> <div class="day-rect" v-bind:style="getStyle(6)"/> <div class="day-rect planned-rect" v-bind:style="getPlannedStyle(6)"/></td>
            </tr>
            <tr class="has-text-weight-semibold has-text-centered">
              <td>{{ $t('dates.monday').slice(0,3) }}</td>
              <td>{{ $t('dates.tuesday').slice(0,3) }}</td>
              <td>{{ $t('dates.wednesday').slice(0,3) }}</td>
              <td>{{ $t('dates.thursday').slice(0,3) }}</td>
              <td>{{ $t('dates.friday').slice(0,3) }}</td>
              <td>{{ $t('dates.saturday').slice(0,3) }}</td>
              <td>{{ $t('dates.sunday').slice(0,3) }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="column is-narrow-desktop">
        <div :class="$root.isDesktop() ? [] : ['columns', 'is-mobile', 'is-multiline', 'is-clipped']">
          <div class="columns is-mobile is-vcentered" :class="$root.isDesktop() ? [] : ['column', 'is-half']">
            <div class="column is-narrow">
              <div class="is-circle has-background-accent-lighter p-2">
                <b-icon icon="chart-timeline-variant" size="is-medium"/>
              </div>
            </div>
            <div class="column">
              <p class="is-uppercase">{{ $t('metrics.distance') }}</p>
              <p class="is-size-5 has-text-neutral-dark has-text-weight-bold">{{ showDistance(weekDistance, 0) }}</p>
            </div>
          </div>
          <div class="columns is-mobile is-vcentered" :class="$root.isDesktop() ? [] : ['column', 'is-half']">
            <div class="column is-narrow">
              <div class="is-circle has-background-accent-lighter p-2">
                <b-icon icon="timer-outline" size="is-medium"/>
              </div>
            </div>
            <div class="column is-narrow">
              <p class="is-uppercase">{{ $t('metrics.duration') }}</p>
              <p class="is-size-5 has-text-neutral-dark has-text-weight-bold">
                  {{ utimeToString(weekTime) || 0 }} <span v-show="plannedWeekTime">/ {{ utimeToString(plannedWeekTime) }}</span>
              </p>
            </div>
          </div>
          <div class="columns is-mobile is-vcentered" :class="$root.isDesktop() ? [] : ['column', 'is-half']">
            <div class="column is-narrow">
              <div class="is-circle has-background-accent-lighter p-2">
                <b-icon icon="image-filter-hdr" size="is-medium"/>
              </div>
            </div>
            <div class="column is-narrow">
              <p class="is-uppercase">{{ $t('metrics.ascent') }}</p>
              <p class="is-size-5 has-text-neutral-dark has-text-weight-bold">{{ showHeight(weekAscent) }}</p>
            </div>
          </div>
          <div class="columns is-mobile is-vcentered" :class="$root.isDesktop() ? [] : ['column', 'is-half']">
            <div class="column is-narrow">
              <div class="is-circle has-background-accent-lighter p-2">
                <b-icon icon="thermometer" size="is-medium"/>
              </div>
            </div>
            <div class="column is-narrow">
              <p class="is-uppercase">{{ $t('metrics.load') }}</p>
              <p class="is-size-5 has-text-neutral-dark has-text-weight-bold">
                  {{ weekLoad }} <span v-show="plannedWeekLoad">/ {{ plannedWeekLoad }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'weeksummary',
  data() {
    return {
      weekDistance: 0,
      monthDistance: 0,
      yearDistance: 0,
      totalDistance: 0,
      daysTime: [0,0,0,0,0,0,0],
      plannedDaysTime: [0,0,0,0,0,0,0],
      maxWeekTime: 0,
      weekTime: 0,
      plannedWeekTime: 0,
      monthTime: 0,
      yearTime: 0,
      totalTime: 0,
      weekAscent: 0,
      weekCalories: 0,
      weekLoad: 0,
      plannedWeekLoad: 0,
      block: null,
    };
  },
  computed: {
    activities(){ return this.$store.getters.activities; },
    workouts(){ return this.$store.getters.workouts; },
  },
  methods: {
    getStyle(day){
      var height = this.daysTime[day] > 0 ? (this.daysTime[day]/this.maxWeekTime)*100 : 0;
      return 'height: ' + height + 'px;';
    },

    getPlannedStyle(day){
      var height = this.plannedDaysTime[day] > 0 ? (this.plannedDaysTime[day]/this.maxWeekTime)*100 : 0;
      return 'height: ' + height + 'px;';
    },

    calcSummary(){
      Object.assign(this.$data, this.$options.data.apply(this));
      const now = this.LocalDate.today();
      const firstDayOfWeek = now.firstDayOfWeek();

      const firstDayOfMonth = now.firstDayOfMonth();
      const firstDayOfYear = now.firstDayOfYear();
      for(let activity of this.activities){
        let distance = activity.data.distance;
        let duration = activity.duration;
        let dateTime = activity.date.getTime();
        let ascent = activity.data.total_ascent ? activity.data.total_ascent : 0;
        let calories = activity.data.calories ? activity.data.calories : 0;
        let load = activity.data.load ? activity.data.load : 0;

        this.totalDistance += distance;
        this.totalTime += duration;
        if(!duration)
          console.log(activity);
        if(dateTime >= firstDayOfYear.getTime()){
          this.yearDistance += distance;
          this.yearTime += duration;
          if(dateTime >= firstDayOfMonth.getTime()){
            this.monthDistance += distance;
            this.monthTime += duration;
          }
        }


        if(dateTime >= firstDayOfWeek.getTime() && dateTime < (new Date()).getTime()){
          const index = (activity.date.getDay()+6)%7;
          this.$set(this.daysTime, index, this.daysTime[index] + duration);
          if(duration > this.maxWeekTime)
            this.maxWeekTime = duration;

          this.weekDistance += distance;
          this.weekTime += duration;
          this.weekAscent += ascent;
          this.weekCalories += calories;
          this.weekLoad += load;
        }
      }


      const dayOfNextWeek = now.lastDayOfWeek().addDay(1);
      this.plannedWeekTime = 0;
      this.plannedWeekLoad = 0;
      for(let workout of this.workouts){
        if(workout.date >= firstDayOfWeek.getTime() && workout.date < dayOfNextWeek){
          this.plannedWeekTime += workout.duration;
          this.plannedWeekLoad += (workout.data && workout.data.load) ? workout.data.load : 0;

          this.$set(this.plannedDaysTime, (workout.date.getDay()+6)%7, workout.duration);
          if(workout.duration > this.maxWeekTime)
            this.maxWeekTime = workout.duration;
        }
      }

      this.block = this.ugetTrainingBlockForDay(this.LocalDate.now());

    },
  },
  mounted: function(){
    this.calcSummary();
  },
  watch: {
    activities: function(){
      this.calcSummary();
    },
  }
}
</script>

<style lang="scss" scoped>


.week{
  margin: auto;
  width: 100%;
  max-width: 600px;
}

.td-rect{
  position: relative;
  height: 100px;
}

.day-rect{
  border-collapse:collapse;
  border-radius: 10px;
  background-color: $secondary-light;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
}

.planned-rect{
  opacity: 0.5;
}
</style>
