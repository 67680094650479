<template>
  <b-step-item :label="$t('infos.infos')" value="profile" class="has-text-left">
    <h2 class="title is-3">{{ $t('account.your_profile')}}</h2>
    <p class="is-italic">{{ $t('general.required_fields') }}</p>
    <h4 class="title is-5 has-text-secondary-light has-text-weight-bold">{{ $t('infos.personal_informations')}}*</h4>
    <section class="block">
      <b-field :label="$t('infos.sex')">
        <b-radio v-model="sex" native-value="male">
          {{ $t('infos.male') }}
        </b-radio>
        <b-radio v-model="sex" native-value="female">
          {{ $t('infos.female') }}
        </b-radio>
      </b-field> <br/>

      <b-field :label="$t('infos.birthday')" class="max-width-200px">
        <b-datepicker v-model="birthday" icon="calendar" editable trap-focus :first-day-of-week="1"/>
      </b-field>


    </section>

    <h4 class="title is-5 has-text-secondary-light has-text-weight-bold">{{ $t('account.complementary_informations' )}}</h4>


    <section class="block">
      <b-field grouped>
        <b-field>
          <template #label>
            {{ $t('metrics.resting_heart_rate') }}
            <Helper :label="$t('metrics.help.resting_heart_rate')"/>
          </template>
          <b-input type="number" v-model.number="restingHeartRate" class="max-width-100px"/> <span class="pl-1 pt-2"><b>bpm</b></span>
        </b-field>
        <b-field>
          <template #label>
            {{ $t('metrics.weight') }}
            <Helper :label="$t('metrics.help.weight')"/>
          </template>
          <UnitInput v-model.number="weight" unit="weight"/>
        </b-field>
      </b-field>
    </section>

    <section class="block">
      <h4 class="title is-5 has-text-secondary-light has-text-weight-bold">
        {{ $t('equipment.equipment') }}
        <Helper :label="$t('equipment.help')"/>
      </h4>
      <b-field grouped>
        <b-checkbox v-model="heartRateMonitor">{{$t('equipment.heart_rate_monitor')}}</b-checkbox>
        <b-checkbox v-model="powerMeter">{{$t('equipment.power_meter')}}</b-checkbox>
        <b-checkbox v-model="homeTrainerPower">{{$t('equipment.home_trainer_power')}}</b-checkbox>
      </b-field>
    </section>
  </b-step-item>
</template>

<script>
import UnitInput from '@/components/UnitInput.vue';
import Helper from '@/components/Helper.vue';

export default{
  name: 'ProfileStep',
  data(){
    return {
      birthday: this.$store.getters.user.infos.birthday,
      sex: this.$store.getters.user.infos.sex,
      weight: this.ugetLastMeasure(this.LocalDate.now()).weight,
      restingHeartRate: this.ugetLastMeasure(this.LocalDate.now()).resting_heart_rate,
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      heartRateMonitor: this.$store.getters.user.infos.equipment.heart_rate_monitor || false,
      powerMeter: this.$store.getters.user.infos.equipment.power_meter || false,
      homeTrainerPower: this.$store.getters.user.infos.equipment.home_trainer_power || false,
    };
  },
  methods: {
    checkStep(){
      this.post('/log', { log: 'Options Step done' }).then().catch(err=>{});
      //if(!this.sex || !this.birthday)
      //  return false;
      return true;
    },

    showError(err){
      this.$buefy.toast.open({
        duration: 3000,
        message: this.$t(err),
        position: 'is-bottom',
        type: 'is-danger'
      });
    },
  },
  components:{
    Helper,
    UnitInput,
  },
}
</script>

<style lang="scss" scoped>
</style>
