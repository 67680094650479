<template>
  <div class="modelbox is-relative" @contextmenu.prevent="$refs.modelMenu.open">
    <span class="pos-top-right is-clickable" @click.stop="$refs.modelMenu.open"><b-icon size="is-small" icon="dots-vertical"/></span>
    <div class="hero is-primary">
      <h4 class="title is-6">{{ $t('model.new_model') }}</h4>
    </div>
    <ul class="mt-1">
      <li v-if="model.threshold_power != previousModel.threshold_power"><b>{{ $t('metrics.threshold_power') }}: </b>{{ model.threshold_power }} W</li>
      <li v-if="model.anaerobic_capacity != previousModel.anaerobic_capacity"><b>{{ $t('metrics.anaerobic_capacity') }}: </b>{{ Math.round(model.anaerobic_capacity/100)/10 }} Kj</li>
      <li v-if="model.max_power != previousModel.max_power"><b>{{ $t('metrics.max_power') }}: </b>{{ model.max_power }} W</li>
      <li v-if="model.stamina != previousModel.stamina"><b>{{ $t('metrics.stamina') }}: </b>{{ Math.round(model.stamina*100) }} %</li>
      <li v-if="model.threshold_heart_rate != previousModel.threshold_heart_rate"><b>{{ $t('metrics.threshold_heart_rate') }}: </b>{{ model.threshold_heart_rate }} bpm</li>
      <li v-if="model.max_heart_rate != previousModel.max_heart_rate"><b>{{ $t('metrics.max_heart_rate') }}: </b>{{ model.max_heart_rate }} bpm</li>
      <li v-if="model.resting_heart_rate != previousModel.resting_heart_rate"><b>{{ $t('metrics.resting_heart_rate') }}: </b>{{ model.resting_heart_rate }} bpm</li>
    </ul>



    <vue-context ref="modelMenu">
    </vue-context>
  </div>
</template>

<script>
import VueContext from 'vue-context';

export default {
  name: 'modelbox',
  props: ['model'],
  data() {
    return {
      previousModel: {}
    };
  },
  methods: {

  },
  mounted: function(){
    this.previousModel = this.ugetLastModel(this.model.date);
  },
  components: {
    VueContext
  }
}
</script>

<style lang="scss">
.modelbox{
  border-bottom: solid;
}
</style>
