<template>
  <div class="activity p-4" v-if="this.activity">
    <div class="has-text-left">
      <h2 class="title is-4 is-inline-block">{{ activity.name }}</h2>
      <b-button size="medium" type="is-secondary" class="is-pulled-right" icon-right="pencil"
         @click="$eventBus.$emit('show-modal-edit-activity', { activity: activity })"/>
      <p><a id="view_strava" v-if="activity.data.strava_link" :href="activity.data.strava_link">{{ $t('activity.view_strava') }}</a></p>
    </div>

    <b-tabs multiline destroy-on-hide> <!-- TODO: Remove destroy on hide and correct visual bugs (map and activity charts) -->
      <b-tab-item :label="$t('activity.summary')">
        <ActivitySummary :activity="activity" :model="model"/>
        <ActivityCourse :activity="activity" :activityData="activityData" v-if="!$root.isMobile()"/>
      </b-tab-item>

      <b-tab-item :label="$t('activity.course')" v-if="$root.isMobile()">
        <ActivityCourse :activity="activity" :activityData="activityData"/>
      </b-tab-item>

      <!--<b-tab-item :label="$t('activity.charts')">
      </b-tab-item>-->

      <b-tab-item :label="$t('activity.records')" :disabled="!$store.getters.hasStandardAccess">
        <b-tabs>
          <!-- show power curve tab as first tab to premium users -->
          <b-tab-item :label="$t('activity.power_curve')" v-if="activityData.power_curve && activityData.power_curve.length > 0 && $store.getters.hasPremiumAccess">
            <div>
              <Curve :data="activityData.power_curve" :lastDate="activity.date" :dataType="'activityData'" :curveType="'power'"/>
            </div>
          </b-tab-item>
          <b-tab-item :label="$t('activity.heart_rate_curve')" v-if="activityData.heart_rate_curve && activityData.heart_rate_curve.length > 0">
            <div>
              <Curve :data="activityData.heart_rate_curve" :lastDate="activity.date" :dataType="'activityData'" :curveType="'heart_rate'"/>
            </div>
          </b-tab-item>
          <b-tab-item :label="$t('activity.power_curve')" v-if="!$store.getters.hasPremiumAccess" disabled="true"/> <!-- Show disabled power curve in last position to non premium user -->
        </b-tabs>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import Curve from '../curves/Curve.vue';
import ActivitySummary from './ActivitySummary.vue';
import ActivityCourse from './ActivityCourse.vue';

export default {
  name: 'activity',
  props: ['activityToView'],
  data() {
    return {
      model: {},
      activity: null,
      activityData: {},
      activityDataLoaded: false,
    };
  },
  methods: {
    calculateWbal(powerData){
      var cp = this.model.threshold_power;
      var W = this.model.anaerobic_capacity;
      if(!cp || !W)
        return null;
      var wbal = new Array(powerData.length);
      var wexp = 0;
      for(var i=0; i<powerData.length; i++){
        if(powerData[i] > cp)
          wexp += (powerData[i]-cp);
        else
          wexp -= wexp/W*(cp-powerData[i]);
        wbal[i] = Math.round(W-wexp)/1000;
      }
      return wbal;
    },

    calculateVo2(powerData){
      var weight = this.ugetLastMeasure(this.LocalDate.now()).weight;
      if(!weight)
        return null;

      const smoothing = 1;
      const seconds = 25;

      var vo2 = new Array(powerData.length);

      var previous = 0;
      const ratio = smoothing/(seconds+1);
      for(var i=0; i<powerData.length; i++){
        var value = powerData[i]*0.0108 + weight*0.007;
        var current = value*ratio + previous*(1 - ratio);
        vo2[i] = (current/weight)*1000;
        previous = current;
      }

      return vo2;
    },


    async loadActivity(){
      if(this.activity.activity_data_id){
        this.activityData = (await this.get('/activity_data?activity_data_id='+this.activity.activity_data_id.toString())).activity_data;
        this.activityDataLoaded = true;
        if(this.activityData.power.length > 0){
          this.activityData.wbal = this.calculateWbal(this.activityData.power);
          this.activityData.vo2 = this.calculateVo2(this.activityData.power);
        }
        this.$nextTick(() =>{ //Update power and hr tiz on next tick
          if(this.$refs.powerChart)
            this.powerSelection = this.$refs.powerChart.getArrayFromDomain();
          if(this.$refs.hrChart)
            this.hrSelection = this.$refs.hrChart.getArrayFromDomain();
        });
      }
    },

  },
  mounted: async function(){
    try{
      this.activity = this.activityToView;
      if(!this.activity){
        this.activity = this.ugetActivityFromSerial(this.$route.query.serial);
      }
      if(!this.activity){
        this.activity = (await this.get('/activity?serial='+this.$route.query.serial)).activity;
        this.activity.date = this.LocalDate.new(this.activity.date);
      }
      this.model = this.ugetLastModel(this.activity.date);
      await this.loadActivity();
    }catch(err){
      console.error(err);
      console.error(err.stack);
    }
  },
  components: {
    ActivitySummary,
    ActivityCourse,
    Curve,
  },
}
</script>

<style lang="scss">


#view_strava{
  display: inline-block;
  padding-bottom: 10px;
  color: #FC4C02;
}
</style>
