<!-- Editable text -->
<!-- Component that display text and can be edited when clicked on -->
<template>
  <div class="editable-text">
    <b-field horizontal :label="title" class="is-clickable">
      <p v-if="!edit" v-on:click="startEdit()" class="h-100p">
        {{ value }}
      </p>
      <template v-if="edit">
        <b-input type="text" v-model="textValue"/>
        <p class="control">
          <button class="button is-info" v-on:click="save()">{{ $t('settings.save') }}</button>
          <button class="button" v-on:click="cancel()">X</button>
        </p>
      </template>
    </b-field>
  </div>
</template>

<script>
export default{
  name: 'editableText',
  props: ['title', 'value'],
  data(){
    return {
      edit: false,
      textValue: ''
    };
  },
  methods: {
    startEdit(){
      this.edit = true;
      this.textValue = this.value;
    },

    save(){
      if(this.textValue.length > 0){
        this.$emit('input', this.textValue);
        this.edit = false;
      }
    },

    cancel(){
      this.edit = false;
    }
  },
  watch: {
    value(){
      this.textValue = this.value;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
