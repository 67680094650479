<script>
import * as d3 from 'd3';
import LineChart from '@/helpers/LineChart.vue';
import { calcNPower } from '@/helpers/util.js'

export default{
  name: 'ActivityChart',
  extends: LineChart,
  props: {
    distance: {
      type: Array,
    },
    avgCountZeroes: {
      type: Boolean,
      default: true,
    },
    convertFunction: {
      type: Function,
      default: d => d,
    },
    isPowerChart: {
      type: Boolean,
      default: false,
    },
    axisUnit: {
      type: String,
      default: 'time',
    },
  },
  computed: {
    margin(){
      var margin = LineChart.computed.margin.call(this);
      margin.right += 60; //More margin for the info text on the right
      return margin;
    },
  },
  data(){
    return{

    };
  },
  methods: {
    initialize(){
      LineChart.methods.initialize.call(this); //super initialize

      this.data = [new Array(this.entries.length)];
      //Activity data is entry, time and distance
      for(var i=0; i<this.entries.length; i++){
        this.data[0][i] = { data: this.convertFunction(this.entries[i]), date: i*1000, distance: this.distance[i] };
      }
      this.maxDistance = this.distance[this.distance.length-1];
      this.isAxisTime = true;
    },

    createChart(){
      //Create line chart from super create chart function
      LineChart.methods.createChart.call(this);
      //And Add activity elements on top (text infos)
      this.createChartElements();
      this.updateInfoText();
    },

    createLines(){
      this.lines = [];

      var min = this.axisX.domain()[0];
      var max = this.axisX.domain()[1];
      var linesData = [];
      this.data.forEach(lineData => {
        var curX = -1;
        var attr = this.isAxisTime ? 'date' : 'distance';       //Line data will be different if its date or distance
        linesData.push(lineData.filter((data, i) => {
          var val = data[attr];
          if(val < min || val > max) //Don't render if it's not in domain
            return false;
          var x = Math.floor(this.axisX(val));
          if(x > curX){ //Only add a point if there is a progress in time/distance (x axis)
            curX = x;
            return true;
          }
          return false;
        }));
      });

      //create path
      linesData.forEach(lineData => {
        this.lines.push(this.lineArea.append("path")
          .datum(lineData)
          .attr("class", "line line"+this.lines.length)
          .attr("d", this.lineFunction));
      });
    },

    recreateLines(){
      this.svg.selectAll('.line').remove();
      this.createLines();
    },

    createChartElements(){
      var self = this;

      //Display value of current data hovered
      this.textValue = this.svg.append("text")
        .attr("class", "text-value")
        .attr("fill", "#000")
        .attr("x", -this.margin.left/3)
        .attr("y", this.domainHeight/2)
        .attr("dy", "0.5em")
        .style("text-anchor", "middle")
        .text("0");

      //Info box containing min max values display
      this.infoContainer = this.svg.append("rect")
        .attr("x", this.domainWidth+2)
        .attr("y", 0)
        .attr("width", this.margin.right-2)
        .attr("height", this.height-2)
        .attr("fill", "white")
        .attr("stroke", "black")
        .attr("overflow", "visible")
        .attr("class", "infoContainer");

      this.infoText = this.svg.append("text")
        .style("font-size", "13px")
        .style("font-family", "Segoe UI")
        .style("color", "#333333")
        .style("fill", "#333333")
        .attr("class", "text-value")
        .attr("transform", "translate(" + (this.domainWidth+5) + "," + 5 + ")");

      this.infoText.append("tspan")
        .attr("dy", "0.5em")
        .text(this.$t('metrics.max')+": ");

      this.maxValueText = this.infoText.append("tspan")
        .style("font-weight", "bold");

      this.infoText.append("tspan")
        .attr("x", "0")
        .attr("dy", "1.2em")
        .text(this.$t('metrics.avg')+": ");

      this.averageValueText = this.infoText.append("tspan")
        .style("font-weight", "bold");

      this.infoText.append("tspan")
        .attr("x", "0")
        .attr("dy", "1.2em")
        .text(this.$t('metrics.min')+": ");

      this.minValueText = this.infoText.append("tspan")
        .style("font-weight", "bold");


      //Power chart will also display NPower
      if(this.isPowerChart){
        this.infoText.append("tspan")
          .attr("x", "0")
          .attr("dy", "1.2em")
          .text(this.$t('metrics.npower')+": ");

        this.npValueText = this.infoText.append("tspan")
          .style("font-weight", "bold");
      }
    },

    getArrayFromDomain(){
      if(!this.isZoomed())
        return this.data[0];
      var min = this.axisX.domain()[0];
      var max = this.axisX.domain()[1];
      if(this.isAxisTime)
        return this.data[0].filter(d => d.date >= min && d.date <= max);
      return this.data[0].filter(d => d.distance >= min && d.distance <= max);
    },

    //Update info displayed value when zooming
    updateInfoText(){
      var arrayData = this.getArrayFromDomain();
      this.maxValueText.text(numberFormat(d3.max(arrayData, d => d.data)));
      this.averageValueText.text(numberFormat(d3.mean(this.avgCountZeroes ? arrayData : arrayData.filter(d => d.data !=0), d => d.data)));
      this.minValueText.text(numberFormat(d3.min(arrayData, d => d.data)));

      if(this.isPowerChart){
        var powerData = this.getArrayFromDomain().map(d => d.data);
        this.npValueText.text(numberFormat(calcNPower(powerData)));
      }
    },

    //On mouse move update value displayed
    mouseMoveCall(event, mouse_x, mouse_y){
      if(this.data[0][this.indexOfPosition(mouse_x)])
        this.textValue.text(numberFormat(this.data[0][this.indexOfPosition(mouse_x)].data));
    },

    //On mouse up (zoom change) recreate lines and update info text
    mouseUpCall(event, mouse_x, mouse_y){
      this.recreateLines();
      this.updateInfoText();
    },

    axisXFormat(nb){
      if(this.isAxisTime){ //Time format
        var formatSecond = d3.utcFormat("%S"), formatMinute = d3.utcFormat("%M:%S"), formatHour = d3.utcFormat("%H:%M");
        return (nb < 3600000 ? (nb < 60000  ? formatSecond : formatMinute) : formatHour)(nb);
      }
      return this.showDistance(nb); //Distance format
    },

    indexOfPosition(posX){
      if(this.isAxisTime)
        return Math.round(this.axisX.invert(posX)/1000);
      return d3.bisector(d => d.distance).left(this.data[0], this.axisX.invert(posX));
    },

    getCurrentDomainIndexes(){
      if(this.isAxisTime)
        return [Math.round(this.getCurrentDomain()[0]/1000), Math.round(this.getCurrentDomain()[1]/1000)];
      return [d3.bisector(d => d.distance).left(this.data[0], this.getCurrentDomain()[0]), d3.bisector(d => d.distance).left(this.data[0], this.getCurrentDomain()[1])];
    },

    setAxis(){
      if(this.axisUnit === 'time'){
        this.isAxisTime = true;
        LineChart.methods.setAxis.call(this); //Line chart default set axis is axis time
      }else if(this.axisUnit === 'distance'){
        this.isAxisTime = false;
        this.axisX = d3.scaleLinear().range([0, this.domainWidth]); //Scale linear instead of scale utc if distance
        this.axisY = d3.scaleLinear().range([this.domainHeight, 0]);
        this.axisX.domain(this.getMaxDomain());
        this.axisY.domain([this.minDomain, this.maxDomain]);
        if(this.fillArea)
          this.lineFunction = d3.area().x((d) => { return this.axisX(d.distance); }).y1((d) => { return this.axisY(d.data); }).y0(this.domainHeight);
        else
          this.lineFunction = d3.line().x((d) => { return this.axisX(d.distance); }).y((d) => { return this.axisY(d.data); });
      }
    },

    //Change to distance axis
    setDistanceAsAxis(){
      var di = this.getCurrentDomainIndexes();
      this.setAxis();
      this.axisX.domain([this.data[0][di[0]].distance, this.data[0][di[1]].distance]);
      this._updateAxis();
    },

    //Change to time axis
    setTimeAsAxis(){
      var di = this.getCurrentDomainIndexes();
      this.setAxis();
      this.axisX.domain([this.data[0][di[0]].date, this.data[0][di[1]].date]);
      this._updateAxis();
    },

    //Update axis and line display
    _updateAxis(){
      this.lineArea.selectAll('.line')
        .transition()
        .duration(1000)
        .attr("d", this.lineFunction);

      if(this.xAxis)
        this.axisXGraphic.transition().duration(1000).call(this.axisXCall());

      this.zoomListener();
    },

    getMaxDomain(){
      if(this.isAxisTime)
        return LineChart.methods.getMaxDomain.call(this); //if axis time return line chart super value
      return [0, this.maxDistance];
    },
  },
  watch: {
    axisUnit(){
      if(this.axisUnit === 'time'){
        this.setTimeAsAxis();
      }else if(this.axisUnit === 'distance'){
        this.setDistanceAsAxis();
      }
    }
  },
};

//Return number to string with max one decimal digit
function numberFormat(number){
  if(!number)
    return number;
  if(Number.isInteger(number))
    return number.toString();
  return number.toFixed(1);
}
</script>
<style lang="scss" scoped>
.linechart{
  height: 120px;
}
</style>
