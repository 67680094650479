<template>
  <div class="MyZones">
    <div class="columns is-variable is-5-desktop is-8-widescreen">
      <div class="column">
        <h3 class="title is-4">{{ $t('zone.heart_rate_zones') }}</h3>
        <ZonesTable type="heart_rate"/>
      </div>
      <div class="column">
        <div v-show="$store.getters.hasPremiumAccess">
          <h3 class="title is-4">{{ $t('zone.power_zones') }}</h3>
          <ZonesTable type="power"/>
        </div>
      </div>

    </div>

    <SetModelField/>
  </div>
</template>

<script>
import ZonesTable from '@/components/ZonesTable.vue';
import SetModelField from './SetModelField.vue';

export default{
  name: 'MyZones',
  components: {
    ZonesTable,
    SetModelField,
  },
};
</script>

<style lang="scss" scoped>
</style>
