<template>
  <b-step-item :label="$t('plan.training_time')" value="trainingTime">
    <h2 class="title is-4">{{ $t('plan.training_time')}}</h2>

    <section class="block columns is-flex is-centered">
      <b-field>
        <template #label>
          {{ $t('plan.week_training_time') }}
          <Helper :label="$t('plan.help.week_training_time')"/>
        </template>
        <DurationPicker class="is-inline-block" v-model="weekTime" :minutesIncrement="15" :maxHours="40"/>
      </b-field>
    </section>
    <!--<section class="block columns is-centered">
      <b-field>
        <template #label>
          {{ $t('plan.estimated_annual_time') }}
          <Helper :label="$t('plan.help.estimated_annual_time')"/>
        </template>
        <p class="box has-text-weight-semibold is-size-5 is-inline-block"> {{ Math.round((weekTime * 50)/3600) }} h</p>
      </b-field>
    </section>-->


    <section class="block columns is-flex is-centered">
      <b-field>
        <template #label>
          {{ $t('plan.max_week_training_time') }}
          <Helper :label="$t('plan.help.max_week_training_time')"/>
        </template>
        <DurationPicker class="is-inline-block" v-model="maxWeekTime" :minutesIncrement="15" :maxHours="40"/>
      </b-field>
    </section>

    <section class="block">
      <b-field grouped group-multiline position="is-centered">
        <template #label>
          {{ $t('plan.day_training_time') }}
          <Helper :label="$t('plan.help.day_training_time')"/>
          <p v-if="$store.getters.isBaseAccount" class="has-text-weight-normal" v-html="$t('plan.free_limit_days')"/>
        </template>

        <template v-for="(i, day) in (0,7)">
          <b-field :label="$t('dates.week_days.'+day)" :key="day" grouped>
            <div class="is-flex is-flex-direction-column">
              <DurationPicker v-model="daysTime[day]" :minutesIncrement="15" :maxHours="6"/>
              <TrainingTypePicker v-model="trainingTypeDays[day]"/>
            </div>
          </b-field>
        </template>
      </b-field>
    </section>

  </b-step-item>
</template>

<script>
import Helper from '@/components/Helper.vue';
import DurationPicker from '@/components/DurationPicker.vue';
import TrainingTypePicker from '@/components/TrainingTypePicker.vue';

export default{
  name: 'training-time-step',
  data(){
    return {
      weekTime: 0,
      maxWeekTime: 0,
      daysTime: [0,0,0,0,0,0,0],
      trainingTypeDays: ['outdoor', 'outdoor', 'outdoor', 'outdoor', 'outdoor', 'outdoor', 'outdoor'],
    };
  },
  methods: {
    //Check for base account that it's within the limit of 3 days and one indoor day
    checkForBaseAccount(){
      if(this.$store.getters.hasStandardAccess) //If not base account return
        return true;

      //Check number of training days and indoor days
      return this.daysTime.filter(t => t > 0).length <= 3 && this.trainingTypeDays.filter(t => t === 'indoor').length <=1;
    },

    checkStep(){
      var totalDaysTime = this.daysTime.reduce((acc, time) => acc + time, 0);
      var daysTraining = this.daysTime.reduce((acc, time) => acc + (time > 0 ? 1 : 0), 0);

      if(this.weekTime == 0){
        this.showError('errors.plan.week_time_0');
        return false;
      }

      if(totalDaysTime == 0){
        this.showError('errors.plan.days_time_0');
        return false;
      }

      if(daysTraining < 2){
        this.showError('errors.plan.training_days_less_2');
        return false;
      }

      if(!this.checkForBaseAccount()){
        this.showError('errors.plan.free_limit_days');
        return false;
      }

      return true;
    },

    showError(err){
      this.$buefy.toast.open({
        duration: 5000,
        message: this.$t(err),
        position: 'is-bottom',
        type: 'is-danger'
      });
    },
  },
  mounted(){
    const training_plan_data = this.$store.getters.user.training_plan_data;
    if(training_plan_data){
      this.weekTime = training_plan_data.week_available_time || 0;
      this.maxWeekTime = training_plan_data.max_week_available_time || this.weekTime;
      if(training_plan_data.available_time_days && training_plan_data.available_time_days.length == 7)
        this.daysTime = training_plan_data.available_time_days.map(t => t || 0);
    }
  },
  watch: {
    weekTime(){
      if(this.weekTime > this.maxWeekTime)
        this.maxWeekTime = this.weekTime;
    },
    maxWeekTime(){
      if(this.maxWeekTime < this.weekTime)
        this.weekTime = this.maxWeekTime;
    },
  },
  components: {
    Helper,
    DurationPicker,
    TrainingTypePicker,
  },
}
</script>

<style lang="scss">
</style>
