<template>
  <div id="generate-training-plan" class="container pt-5">
    <Steps v-model="currentStep" ref="steps" :check="checkStep" :validate="validate" :title="$t('plan.generate_training_plan')">
      <PlanInfosStep ref="infos"/>
      <TrainingTimeStep ref="trainingTime"/>
      <ObjectivesStep ref="objectives"/>
      <OptionsStep ref="options"/>
    </Steps>
    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
      <div class="box">
        <h1 class="title is-2"> {{ $t('plan.generating_plan')}} </h1>
        <section class="block">
          <b-icon icon="sync" size="is-large" custom-class="mdi-spin-reverse"/>
        </section>
      </div>
    </b-loading>
  </div>
</template>

<script>
import Steps from '@/components/Steps.vue';
import TrainingTimeStep from './TrainingTimeStep.vue';
import PlanInfosStep from './PlanInfosStep.vue';
import ObjectivesStep from './ObjectivesStep.vue';
import OptionsStep from './OptionsStep.vue';

export default{
  name: 'generate-training-plan',
  data(){
    return {
      currentStep: /*parseInt(localStorage.getItem('planCurrentStep')) ||*/ 0,
      steps: [],
      isLoading: false,
    };
  },

  mounted(){
    this.steps = [this.$refs.infos, this.$refs.trainingTime, this.$refs.objectives, this.$refs.options];
  },

  methods: {
    checkStep(){
      return this.steps[this.currentStep].checkStep();
    },

    getData(){
      return {
        weekTrainingTime: this.$refs.trainingTime.weekTime,
        maxWeekTrainingTime: this.$refs.trainingTime.maxWeekTime,
        daysTrainingTime: this.$refs.trainingTime.daysTime,
        practiceYears: this.$refs.infos.practiceYears,
        trainingLevel: this.$refs.infos.trainingLevel,
        startDate: this.LocalDate.fromDate(this.$refs.infos.startDate),
        weight: this.$refs.infos.weight,
        restingHeartRate: this.$refs.infos.restingHeartRate,
        equipment: {
          heart_rate_monitor: this.$refs.infos.heartRateMonitor,
          power_meter: this.$refs.infos.powerMeter,
          home_trainer_power: this.$refs.infos.home_trainer_power,
        },
        preparationWeek: this.$refs.options.preparationWeek,
        testingWeek: this.$refs.options.testingWeek,
      };
    },

    trackPlanGeneration(){
      try{
        this.$gtag.event('generate_plan', {
          event_category: 'engagement',
        });
      }catch(err){
        console.error(err);
      }
    },


    async validate(){
      //If plan already exist warn user that it will be regenerated fully from the beginning as a new plan
      if(this.$planExist()){
        const confirmResult = await new Promise((resolve, reject) => {
          this.$buefy.dialog.confirm({
            title: this.$t('plan.want_regenerate'),
            message: this.$t('plan.regenerate_details'),
            cancelText: this.$t('general.no'),
            confirmText: this.$t('plan.regenerate_my_plan'),
            onConfirm: () => resolve(true),
            onCancel: () => resolve(false),
          });
        });
        if(!confirmResult)
          return;
      }

      this.isLoading = true;
      try{
        var data = this.getData();

        var measure = this.ugetLastMeasure(this.LocalDate.now());
        if((data.weight && data.weight != measure.weight) || (data.restingHeartRate && data.restingHeartRate != measure.resting_heart_rate)){

          measure.weight = data.weight || measure.weight;
          measure.resting_heart_rate = data.restingHeartRate || measure.resting_heart_rate;
          measure.date = this.LocalDate.today();
          await this.$store.dispatch('newMeasure', measure);
        }

        var infos = this.$store.getters.user.infos;
        infos.equipment = data.equipment;

        await this.$store.dispatch('updateUserInfos', infos);

        const trainingPlanData = {
          max_week_available_time: data.maxWeekTrainingTime,
          week_available_time: data.weekTrainingTime,
          available_time_days: data.daysTrainingTime,
          practice_years: data.practiceYears,
          training_level: data.trainingLevel,
          start_date: data.startDate,
          preparation_week: data.preparationWeek,
          testing_week: data.testingWeek,
          available_time_on_week: this.$store.getters.user.training_plan_data.available_time_on_week || {},
        };


        await this.$store.dispatch('generateTrainingPlan', trainingPlanData);

        this.$buefy.toast.open({
          duration: 2000,
          message: this.$t('plan.success'),
          position: 'is-bottom',
          type: 'is-success'
        });

        this.trackPlanGeneration();

        localStorage.setItem('planCurrentStep', 0);
        setTimeout(() => { this.$router.push('/calendar'); }, 2000);
        setTimeout(() => this.$eventBus.$emit('show-modal-syncs-if-no-syncs'), 3000);

      }catch(err){
        console.error(err);
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
      }finally{
        this.isLoading = false;
        //loadingComponent.close();
      }

      window.fbq('track', 'CustomizeProduct');
    }
  },

  watch: {
    currentStep: function(){
      localStorage.setItem('planCurrentStep', this.currentStep);
    }
  },

  components: {
    Steps,
    TrainingTimeStep,
    PlanInfosStep,
    ObjectivesStep,
    OptionsStep,
  }
}
</script>

<style lang="scss">
</style>
