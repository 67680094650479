<template>
  <div id="settings" class="">
    <div id="profile">
      <h2 class="title is-4">{{ $t('settings.profile') }}</h2>
      <EditableText :title="$t('account.user_name')" v-model='username'/>
      <EditableLocation :title="$t('account.location')" v-model='settings.location'/>

      <h2 class="title is-5">{{ $t('infos.infos') }}</h2>
      <b-field grouped group-multiline>
        <b-field :label="$t('infos.sex')" class="m-auto">
          <b-radio v-model="infos.sex" native-value="male">
            {{ $t('infos.male') }}
          </b-radio>
          <b-radio v-model="infos.sex" native-value="female">
            {{ $t('infos.female') }}
          </b-radio>
        </b-field>
        <b-field :label="$t('infos.birthday')" class="m-auto">
          <b-datepicker v-model="infos.birthday" icon="calendar" editable trap-focus :first-day-of-week="1"/>
        </b-field>
      </b-field>
      <b-field grouped :label="$t('equipment.equipment')">
        <b-checkbox v-model="infos.equipment.heart_rate_monitor">{{$t('equipment.heart_rate_monitor')}}</b-checkbox>
        <b-checkbox v-model="infos.equipment.power_meter">{{$t('equipment.power_meter')}}</b-checkbox>
        <b-checkbox v-model="infos.equipment.home_trainer_power">{{$t('equipment.home_trainer_power')}}</b-checkbox>
      </b-field>

      <div v-if="coachName">
        <h4 class="title is-4">{{ $t('coaching.coach') }}</h4>
        <p><b>{{ coachName }}</b> {{ $t('coaching.is_your_current_coach') }}</p>
        <b-button size="is-large" @click="revokeCoach()">{{ $t('coaching.revoke_coach') }}</b-button>
      </div>
    </div>

    <InstallButton class="section"/>

    <Syncs class="section"/>

    <Notifications class="section"/>

    <div id="display-preferences" class="section">
      <h2 class="title is-5">{{ $t('settings.display_preferences') }}</h2>
      <b-field :label="$t('metrics.distance')" horizontal>
        <b-select v-model="settings.display_units.distance" name="distance-units">
          <option value="km">{{ $t('metrics.kilometers') }}</option>
          <option value="mi">{{ $t('metrics.miles') }}</option>
        </b-select>
      </b-field>
      <b-field :label="$t('metrics.height')" horizontal>
        <b-select v-model="settings.display_units.height" name="height-units">
          <option value="m">{{ $t('metrics.meters') }}</option>
          <option value="ft">{{ $t('metrics.feets') }}</option>
        </b-select>
      </b-field>
      <b-field :label="$t('metrics.weight')" horizontal>
        <b-select v-model="settings.display_units.weight" name="weight-units">
          <option value="kg">{{ $t('metrics.kilograms') }}</option>
          <option value="lb">{{ $t('metrics.pounds') }}</option>
        </b-select>
      </b-field>
      <b-field :label="$t('metrics.temperature')" horizontal>
        <b-select v-model="settings.display_units.temperature" name="temperature-units">
          <option value="C">{{ $t('metrics.celsius') }}</option>
          <option value="F">{{ $t('metrics.fahrenheit') }}</option>
          <option value="K">{{ $t('metrics.kelvin') }}</option>
        </b-select>
      </b-field>
    </div>

    <div class="language">
      <LanguageSwitch :inputType="'select'"/>
    </div>

    <div class="change-password section">
      <h3 class="title is-5">{{ $t('account.change_password') }}</h3>
      <form @submit.prevent="changePassword()">
        <b-field :label="$t('account.password')" label-position="on-border">
          <b-input v-model="password"  name="password" type="password" autocomplete="off"/>
        </b-field>
        <b-field :label="$t('account.new_password')" label-position="on-border">
          <b-input v-model="newPassword"  name="password" type="password" autocomplete="off"/>
        </b-field>
        <b-field :label="$t('account.confirm_new_password')" label-position="on-border">
          <b-input v-model="renewPassword"  name="repassword" type="password" autocomplete="off"/>
        </b-field>

        <b-button size="is-large" class="is-primary" @click="changePassword()">{{ $t('account.change_password') }}</b-button>
        <p class="has-text-success" v-show="passwordChangeMessage">{{ $t(passwordChangeMessage) }}</p>
        <p class="has-text-danger" v-show="passwordChangeErrorMessage">{{ $t(passwordChangeErrorMessage) }}</p>
      </form>
    </div>

    <div class="account-delete section">
      <h3 class="title is-5">{{ $t('account.delete_account') }}</h3>
      <b-button size="is-large" class="is-primary" @click="requestAccountDeletion()">{{ $t('account.delete_account') }}</b-button>
      <p class="has-text-success" v-show="accountDeleteMessage">{{ $t(accountDeleteMessage) }}</p>
      <p class="has-text-danger" v-show="accountDeleteErrorMessage">{{ $t(accountDeleteErrorMessage) }}</p>
    </div>
  </div>
</template>

<script>
import EditableText from '@/components/EditableText.vue';
import EditableLocation from '@/components/EditableLocation.vue';
import LanguageSwitch from '@/components/LanguageSwitch.vue';
import Syncs from './syncs/Syncs.vue';
import Notifications from './Notifications.vue';
import InstallButton from '@/components/InstallButton.vue';

export default{
  name: 'settings',
  computed: {
    user(){ return this.$store.getters.user; }
  },
  data(){
    return {
      username: '',
      coachName: null,
      settings: {
        location: {
          name: '',
          lat: 0,
          lon: 0
        },
        display_units: {
          distance: '',
          height: '',
          weight: '',
          temperature: ''
        },
      },
      infos: {
        sex: '',
        birthday: undefined,
        equipment: {
          power_meter: false,
          heart_rate_monitor: false,
          home_trainer_power: false,
        },
      },
      password: '',
      newPassword: '',
      renewPassword: '',
      passwordChangeMessage: '',
      passwordChangeErrorMessage: '',
      accountDeleteMessage: '',
      accountDeleteErrorMessage: '',
    };
  },
  methods: {
    applyWatchers(){
      this.$watch('username', () => {
        this.$store.dispatch('updateUsername', this.username).then(()=>{
          this.$buefy.toast.open({
            duration: 2000,
            message: this.$t('account.username_saved'),
            position: 'is-bottom',
            type: 'is-success'
          });
        }).catch(err => {
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$t(err),
            position: 'is-bottom',
            type: 'is-danger'
          });
          this.username = this.user.username;
        });
      });
      this.$watch('settings', () => {
        this.$store.dispatch('updateSettings', this.settings).then(()=>{
          this.$buefy.toast.open({
            duration: 2000,
            message: this.$t('settings.settings_saved'),
            position: 'is-bottom',
            type: 'is-success'
          });
        }).catch(err => {
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$t(err),
            position: 'is-bottom',
            type: 'is-danger'
          });
        });
      }, { deep: true });
      this.$watch('infos', () => {
        this.saveInfos();
      }, { deep: true });
    },

    saveInfos(){
      this.$store.dispatch('updateUserInfos', this.infos).then(()=>{
        this.$buefy.toast.open({
          duration: 2000,
          message: this.$t('infos.saved'),
          position: 'is-bottom',
          type: 'is-success'
        });
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
      });
    },

    requestAccountDeletion(){
      this.accountDeleteMessage = '';
      this.accountDeleteErrorMessage = '';
      this.post('/request_delete_account').then(()=>{
        this.accountDeleteMessage = 'account.delete_account_mail';
      }).catch(err => {
        this.accountDeleteErrorMessage = err;
        console.error(err);
      });
    },

    checkPassword(){
      this.passwordChangeErrorMessage = 'errors.account.';
      if(this.password == '' || this.newPassword == ''){
        this.passwordChangeErrorMessage += 'enter_password';
        return false;
      }
      if(this.newPassword != this.renewPassword){
        this.passwordChangeErrorMessage += 'different_password';
        return false;
      }
      this.passwordChangeErrorMessage = '';
      return true;
    },

    changePassword(){
      this.passwordChangeMessage = '';
      if(this.checkPassword()){
        this.post('/change_password', { password: this.password, new_password: this.newPassword }).then(()=>{
          this.passwordChangeMessage = 'account.password_changed';
        }).catch(err => {
          this.passwordChangeErrorMessage = err;
          console.error(err);
        });
      }
    },

    async getCoachName(){
      if(this.user.coach){
        try{
          this.coachName = await this.get('/username_of_id?id='+this.user.coach);
        }catch(err){
          console.log(err);
        }
      }
    },

    async revokeCoach(){
      try{
        await this.post('/revoke_coach');
        this.$buefy.toast.open({
          duration: 2000,
          message: this.$t('coaching.coach_revoked'),
          position: 'is-bottom',
          type: 'is-success'
        });
        this.coachName = null;
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
      }
    },
  },
  mounted: function(){
    this.username = this.user.username;
    this.settings = this.user.settings;
    this.infos = this.user.infos;
    this.getCoachName();
    this.applyWatchers();
  },
  components: {
    EditableText,
    EditableLocation,
    LanguageSwitch,
    Syncs,
    Notifications,
    InstallButton,
  },
};

</script>

<style lang="scss" scoped>
#settings .section{
  border-top: solid 1px;
  padding-bottom: 10px;
}

</style>
