<template>
  <b-step-item :label="$t('subscription.subscription')" value="subscribe" class="">
    <h2 class="title is-3">{{ $t('subscription.subscription')}}</h2>



    <!-- Show when subscription have been done -->
    <section v-if="isSuccess">
      <h3 class="title is-3">{{ $t('subscription.thanks') }}</h3>
      <div v-if="$store.getters.hasStandardAccess">

      </div>
      <div v-else>
        <div v-html="$t('subscription.being_treated')"/>
      </div>
    </section>
    <section v-else>
      <div v-if="$store.getters.hasStandardAccess && !isSuccess" class="mb-5">
        <p v-if="$store.getters.user.has_trial"><b>{{ $t('subscription.currently_free_trial', { type: $store.getters.user.account_type, date: new Date($store.getters.user.premium_end_date).toLocaleDateString() }) }}</b></p>
        <p v-else><b>{{ $t('subscription.currently_subscribed', { type: $store.getters.user.account_type, date: new Date($store.getters.user.premium_end_date).toLocaleDateString() }) }}</b></p>
      </div>
      <div v-if="(!$store.getters.user.has_stripe_account || $store.getters.hasTrial || !$store.getters.hasStandardAccess)">
        <section v-if="!selectedPlan">
          <p>{{ $t('subscription.select_plan_text') }}</p>
          <b-button size="is-large" type="is-primary" @click="selectPlan">
            {{ $t('subscription.select_plan') }}
          </b-button>
        </section>
        <section v-else-if="selectedPlan">
          <p class="has-text-bold is-size-3 has-text-danger" v-if="cancelled">{{ $t('subscription.payment_cancelled') }}</p>

          <p class="is-size-5" v-if="selectedPlan === firstSelectedPlan">{{ $t('subscription.recommended_plan') }}</p>

          <p v-if="selectedPlan !== 'free'">{{ $t('subscription.monthly' )}} <b-switch v-model="billAnnually"/> {{ $t('subscription.annually' )}}</p>

          <div class="" v-if="selectedPlan === 'free'">
            <h3 class="title is-3">Gratuit</h3>
            <p>Vous bénéficiez de la version gratuite par défaut. Si vous souhaitez vous abonnez par la suite pour profiter de fonctionnalités plus avancé, vous pourrez le faire depuis votre profil</p>
          </div>

          <div class="box max-width-450px m-auto" v-if="selectedPlan === 'standard'">
            <h3 class="title is-3">Standard</h3>

            <p v-if="billAnnually"><span class="is-size-2 has-text-weight-bold has-text-black">49.99€</span>/ {{ $t('dates.year') }} <br/> (<span class="has-text-weight-bold has-text-black">4.15€</span>/{{ $t('dates.month') }})</p>
            <p v-else><span class="is-size-2 has-text-weight-bold has-text-black">4.99€</span>/ {{ $t('dates.month') }}</p>

            <b-button size="is-large" type="is-primary" @click="subscribe('STANDARD')" v-if="$store.getters.user.account_type === 'BASE' || $store.getters.user.has_trial">
              {{ $t('subscription.subscribe_now') }}
            </b-button>


          </div>

          <div class="box max-width-450px m-auto" v-if="selectedPlan === 'premium'">
            <h3 class="title is-3">Premium</h3>
            <p v-if="billAnnually"><span class="is-size-2 has-text-weight-bold has-text-black">119€</span>/ {{ $t('dates.year') }} <br/> (<span class="has-text-weight-bold has-text-black">9.99€</span>/{{ $t('dates.month') }})</p>
            <p v-else><span class="is-size-2 has-text-weight-bold has-text-black">12.99€</span>/ {{ $t('dates.month') }}</p>

            <b-button size="is-large" type="is-primary" @click="subscribe('PREMIUM')" v-if="$store.getters.user.account_type === 'BASE' || $store.getters.user.has_trial">
              {{ $t('subscription.subscribe_now') }}
            </b-button>
          </div>

          <a @click="selectPlan">
            {{ $t('subscription.select_another_plan') }}
          </a>
        </section>
      </div>
    </section>

    <div class="mt-5">
      <PromoCode/>
      <ManageBilling/>
    </div>


    <FeaturesListModal/>
  </b-step-item>
</template>

<script>
import FeaturesListModal from '@/views/landing/FeaturesListModal.vue';
import ManageBilling from '@/components/ManageBilling.vue';
import PromoCode from '@/components/PromoCode.vue';


function getStoredSelectedPlan(){
  let plan = localStorage.getItem('selected_plan');
  if(plan === 'undefined')
    plan = undefined;
  return plan;
}

export default{
  name: 'SubscribeStep',
  data(){
    return {
      billAnnually: true,
      stipe: null,
      selectedPlan: getStoredSelectedPlan(),
      firstSelectedPlan: getStoredSelectedPlan(),
    };
  },
  computed: {
    cancelled(){ return this.$route.query.cancelled == 'true'; },
    isSuccess(){ return this.$route.query.session_id },
    billing(){ return this.billAnnually ? 'annually' : 'monthly'; },
  },
  methods: {
    checkStep(){
      return true;
    },
    selectPlan(){
      this.$eventBus.$emit('show-modal-features');
    },
    async subscribe(subType){
      try{
        let redirect = await this.post('/subscribe', { sub_type: subType, billing: this.billing, setupRedirect: true });
        window.location.href = redirect;
      }catch(err){
        console.error(err);
      }
    },
  },
  mounted(){
    console.log(this.selectedPlan)
    this.$eventBus.$on('features-selected-plan', (plan) => {
      this.selectedPlan = plan;
      this.$router.replace({'query': null});
    });
  },
  components:{
    ManageBilling,
    FeaturesListModal,
    PromoCode,
  },
}
</script>

<style lang="scss" scoped>
</style>
