<template>
  <div class="workout-step">
    <!--<b-field>
      <b-select v-model="name">
        <option :value="$t('workout.interval')">{{ $t('workout.interval') }}</option>
        <option :value="$t('workout.recovery')">{{ $t('workout.recovery') }}</option>
        <option :value="$t('workout.warmup')">{{ $t('workout.warmup') }}</option>
        <option :value="$t('workout.cooldown')">{{ $t('workout.cooldown') }}</option>
      </b-select>
    </b-field>-->
    <div class="columns is-vcentered">
      <b-field class="column">
        <TimeInput v-model="duration"/>
      </b-field>
      <b-field class="column">
        <b-input type="number" name="cadence" min="0" v-model.number="cadence_target"/><span class="control">rpm</span>
      </b-field>
      <b-field>
        <b-checkbox v-model="end_lap_button">{{ $t('workout.end_lap_button') }}</b-checkbox>
      </b-field>
    </div>
    <div class="columns">
      <b-field class="column is-one-quarter">
        <b-input type="number" name="target" min="0" :max="target_unit === 'rpe' ? 10 : 999" v-model.number="target"/><span class="control" v-if="target_unit !== 'rpe'">%</span>
      </b-field>
      <b-field class="column" v-if="target_unit !== 'rpe'">
        <b-select v-model="target_type" name="target-type">
          <option value="threshold">{{ $t('metrics.threshold') }}</option>
          <!-- <option value="aet">{{ $t('metrics.aet') }}</option> -->
          <option value="max">{{ $t('metrics.max') }}</option>
          <option value="anaerobic">{{ $t('metrics.anaerobic') }}</option>
          <option value="map">{{ $t('metrics.max_aerobic') }}</option>
        </b-select>
      </b-field>
      <b-field class="column">
        <b-select v-model="target_unit" name="target-unit">
          <option value="power">{{ $t('metrics.power') }}</option>
          <option value="heart_rate">{{ $t('metrics.heart_rate') }}</option>
          <option value="rpe">{{ $t('metrics.rpe') }}</option>
        </b-select>
      </b-field>
    </div>
  </div>
</template>

<script>
import TimeInput from '@/components/TimeInput.vue';

export default{
  name: 'workout-step',
  props: ['value'],
  data(){
    return {
      name: this.value.name,
      notes: this.value.notes,
      duration: this.value.duration,
      target: this.value.target,
      target_unit: this.value.target_unit,
      target_type: this.value.target_type,
      cadence_target: this.value.cadence_target,
      end_lap_button: this.value.end_lap_button,
    }
  },
  methods: {
    onChange(){
      var step = {
        type: 'STEP',
        name: this.name,
        notes: this.notes,
        duration: this.duration,
        target: parseInt(this.target) || 0,
        target_unit: this.target_unit,
        target_type: this.target_type,
        cadence_target: this.cadence_target || 0,
        end_lap_button: this.end_lap_button || false,
      };

      this.$emit('input', step);
    }
  },
  watch: {
    value: {
      handler(){
        this.name = this.value.name;
        this.notes = this.value.notes;
        this.duration = this.value.duration;
        this.target = this.value.target;
        this.target_unit = this.value.target_unit;
        this.target_type = this.value.target_type;
        this.cadence_target = this.value.cadence_target;
        this.end_lap_button = this.value.end_lap_button;
      },
      deep: true
    }
  },
  updated(){
    this.onChange();
  },
  components: {
    TimeInput,
  },
}
</script>

<style lang="scss">
</style>
