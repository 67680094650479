<template>
  <div class="day h-100p" @mouseenter="hovered = true" @mouseleave="hovered = false">
    <TrainingBlockBox :date="date"/>
    <p :class="{ 'has-text-primary-dark': date.isToday(),
     'has-text-neutral-light': currentMonth && date.getMonth() != currentMonth }">{{ date.getDate() }}</p>
    <div class="">
      <template v-for="(activity, i) in ugetActivitiesForDay(date)">
        <ActivityBox :key="'a'+i" :activity="activity"/>
      </template>
      <template v-for="(workout, i) in ugetWorkoutsForDay(date)">
        <WorkoutBox :key="'w'+i" :workout="workout"/>
      </template>
      <MeasureBox v-if="ugetMeasure(date)" :measureData="ugetMeasure(date)"/>
      <ModelBox v-if="ugetModel(date)" :model="ugetModel(date)"/>
      <template v-for="(objective, i) in ugetObjectivesForDay(date)">
        <ObjectiveBox :key="'o'+i" :objective="objective"/>
      </template>
      <template v-for="(trainingRide, i) in ugetTrainingRidesForDay(date)">
        <TrainingRideBox :key="'t'+i" :trainingRide="trainingRide"/>
      </template>
      <div class="is-clickable add-block" :class="{ 'hide': !hovered }" @click="addToCalendar">
        <p><b-icon icon="plus" size="is-large" type="is-accent-light"/></p>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityBox from './ActivityBox.vue';
import WorkoutBox from './WorkoutBox.vue';
import MeasureBox from './MeasureBox.vue';
import ModelBox from './ModelBox.vue';
import ObjectiveBox from './ObjectiveBox.vue';
import TrainingRideBox from './TrainingRideBox.vue';
import TrainingBlockBox from './TrainingBlockBox.vue';

export default {
  name: 'day',
  props: ['date', 'currentMonth'],
  data(){
    return {
      hovered: false,
    };
  },
  methods: {
    addToCalendar(){
      this.$eventBus.$emit('show-modal-add-to-calendar', { date: this.date });
    },
  },
  components: {
    ActivityBox,
    WorkoutBox,
    MeasureBox,
    ModelBox,
    ObjectiveBox,
    TrainingRideBox,
    TrainingBlockBox,
  }
}
</script>

<style lang="scss" scoped>


.day{
  height: 100%;
  width: 100%;
  padding: 0;
}

.add-block{
  border: solid 1px;
  border-color: $accent-light;
}
</style>
