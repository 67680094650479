<template>
  <div class="workout-step-list column">
    <button class="button" @click="addStep()">{{ $t('workout.add_step') }}</button>
    <button class="button" @click="addRepeat()">{{ $t('workout.add_repeat') }}</button>
    <ul class="steps">
      <li v-for="(step, index) in steps" v-bind:key="index" class="box">
        <WorkoutStep v-if="step.type==='STEP'" v-model="steps[index]" />
        <WorkoutStepRepeat v-if="step.type==='REPEAT'" v-model="steps[index]" />
        <div class="steps-action">
          <div>
            <button class="button" :title="$t('workout.duplicate_step')" @click="clone(index)"><b-icon icon="content-copy"/></button>
          </div>
          <div class="up-down">
            <button class="button" @click="upStep(index)"><b-icon icon="arrow-up"/></button>
            <button class="button" @click="downStep(index)"><b-icon icon="arrow-down"/></button>
          </div>
          <a class="delete" @click="removeStep(index)"></a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import WorkoutStep from './WorkoutStep.vue';
import WorkoutStepRepeat from './WorkoutStepRepeat.vue';

export default{
  name: 'workout-step-list',
  props: ['value'],
  data(){
    return {
      steps: this.value,
    }
  },
  methods: {
    addStep(){
      this.steps.push({
        type: 'STEP',
        name: this.$t('general.title'),
        notes: '',
        duration: 0,
        target: 0,
        target_unit: 'power',
        target_type: 'threshold'
      });
    },

    addRepeat(){
      this.steps.push({
        type: 'REPEAT',
        name: this.$t('general.title'),
        notes: '',
        duration: 0,
        repeatitions: 1,
        steps: [],
      });
    },

    removeStep(i){
      this.$delete(this.steps, i);
    },

    clone(i){
      this.steps.push(Object.assign({}, this.steps[i]));
    },

    upStep(i){
      if(i > 0){
        var step = this.steps[i];
        this.$set(this.steps, i, this.steps[i-1]);
        this.$set(this.steps, i-1, step);
      }
    },

    downStep(i){
      if(i < this.steps.length-1){
        var step = this.steps[i];
        this.$set(this.steps, i, this.steps[i+1]);
        this.$set(this.steps, i+1, step);
      }
    },

    getDuration(){
      return this.steps.reduce((duration, step) => duration+step.duration, 0);
    },
  },
  watch: {
    value: {
      handler(){ this.steps = this.value; },
      deep: true
    }
  },
  updated(){
    this.$emit('input', this.steps);
  },
  components: {
    WorkoutStep,
    WorkoutStepRepeat
  },
}
</script>

<style lang="scss" scoped>

.steps{
  list-style: none;
  overflow-y: auto;
  max-height: 400px;
}

.steps>li{
  display: flex;
  margin-bottom: 10px;
}

.steps-action{
  display: flex;
  align-items: center;
}

.up-down{
  display: flex;
  flex-direction: column;
}
</style>
