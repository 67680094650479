<template>
  <div class="calendar has-text-centered">
    <div class="p-2">
      <div class="is-pulled-right pr-2">
        <a class="has-text-secondary" @click="showTrainingTimeModal()"><b-icon icon="calendar"/></a>
      </div>
      <h2 class="title is-5 is-inline-block calendar-tour-0">{{ $t('nav.calendar') }}</h2>
    </div>
    <div>
      <a @click="previousMonth()"><b-icon icon="chevron-left"/></a>
      <h3 class="title is-4 is-inline-block is-clickable w-11" @click="today()">{{ $t('dates.months.'+currentMonth) }} {{ currentYear }}</h3>
      <a @click="nextMonth()"><b-icon icon="chevron-right"/></a>
    </div>

    <div>
      <table class="w-100p">
        <thead>
          <tr>
            <th>{{ $t('dates.monday').substring(0,3) }}</th>
            <th>{{ $t('dates.tuesday').substring(0,3) }}</th>
            <th>{{ $t('dates.wednesday').substring(0,3) }}</th>
            <th>{{ $t('dates.thursday').substring(0,3) }}</th>
            <th>{{ $t('dates.friday').substring(0,3) }}</th>
            <th>{{ $t('dates.saturday').substring(0,3) }}</th>
            <th>{{ $t('dates.sunday').substring(0,3) }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="week in weekCount()">
            <tr :key="week">
              <template v-for="day in 7">
                <td :key="day" @click="selectDay(week, day)">
                  <DayMobile :date="dateFromWeekDay(week, day)"
                    :selected="selectedDay.isSameDay(dateFromWeekDay(week, day))"
                    :currentMonth="currentMonth"/>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div class="box">
      <DayDetails :date="selectedDay"/>
    </div>

    <PlusButton :date="selectedDay"/>
  </div>
</template>

<script>
import DayMobile from './DayMobile.vue';
import DayDetails from './DayDetails.vue';
import PlusButton from '@/components/PlusButton.vue';

export default {
  name: 'calendar-mobile',
  data() {
    return {
      currentMonth: (new Date()).getMonth(),
      currentYear: (new Date()).getFullYear(),
      firstDayOfMonth: this.LocalDate.new(),
      lastDayOfMonth: this.LocalDate.new(),
      selectedDay: this.LocalDate.today(),
    };
  },
  methods: {
    dateFromWeekDay(week, day){
      var result = this.LocalDate.new(this.firstDayOfMonth);
      result.setDate(result.getDate() + week*7-7+day-1);
      return result;
    },
    updateFirstDayOfMonth(){
      this.firstDayOfMonth = this.LocalDate.date(this.currentYear, this.currentMonth, 1).firstDayOfWeek();
    },
    updateLastDayOfMonth(){
      this.lastDayOfMonth = this.LocalDate.date(this.currentYear, this.currentMonth+1, 0).lastDayOfWeek();
    },
    updateDays(){
      this.updateFirstDayOfMonth();
      this.updateLastDayOfMonth();
    },
    weekCount(){
      return Math.ceil((Math.round((this.lastDayOfMonth - this.firstDayOfMonth)/(1000*60*60*24))) / 7);
    },

    previousMonth(){
      if(--this.currentMonth < 0){
        this.currentMonth = 11;
        this.currentYear--;
      }
      this.updateDays();
    },
    nextMonth(){
      if(++this.currentMonth >= 12){
        this.currentMonth = 0;
        this.currentYear++;
      }
      this.updateDays();
    },
    today(){
      this.currentMonth = (new Date()).getMonth();
      this.currentYear = (new Date()).getFullYear();
      this.updateDays();
    },
    selectDay(week, day){
      this.selectedDay = this.dateFromWeekDay(week, day);
    },

    showTrainingTimeModal(){
      this.$eventBus.$emit('show-modal-training-time', null);
    },

  },
  mounted(){
    if(new Date().getTime() - new Date(localStorage.calendarLastMonthChange).getTime() < 1000000){
      this.currentMonth = localStorage.calendarCurrentMonth ? parseInt(localStorage.calendarCurrentMonth) : this.currentMonth;
      this.currentYear = localStorage.calendarCurrentYear ? parseInt(localStorage.calendarCurrentYear) : this.currentYear;
    }
    this.updateDays();
  },
  watch: {
    currentMonth(){
      localStorage.calendarCurrentMonth = this.currentMonth;
      localStorage.calendarLastMonthChange = new Date();
    },
    currentYear(){
      localStorage.calendarCurrentYear = this.currentYear;
      localStorage.calendarLastMonthChange = new Date();
    }
  },
  components: {
    DayMobile,
    DayDetails,
    PlusButton,
  },
};
</script>

<style lang="scss" scoped>
table{ height: 100%; }
td, th{
  width: 14.2857%;
  height: 100%;
}
</style>
