<template>
  <div class="activitysummary">
    <div class="description">
      {{ activity.description }}
    </div>
    <div class="infos columns has-text-left">
      <div class="column">
        <div class="columns is-mobile is-multiline">
          <div class="column is-half-mobile">
            <div class="p-4">
              <p class="is-uppercase is-size-7">{{ $t('metrics.duration')}}</p>
              <p class="has-text-weight-bold has-text-neutral-dark is-size-4">{{ utimeToString(activity.duration) }}</p>
            </div>
          </div>
          <div class="column is-half-mobile" v-if="activity.data.distance">
            <div class="p-4">
              <p class="is-uppercase is-size-7">{{ $t('metrics.distance')}}</p>
              <p class="has-text-weight-bold has-text-neutral-dark is-size-4">{{ showDistance(activity.data.distance) }}</p>
            </div>
          </div>
          <div class="column is-half-mobile" v-if="activity.date">
            <div class="p-4">
              <p class="is-uppercase is-size-7">{{ $t('dates.date')}}</p>
              <p class="has-text-weight-bold has-text-neutral-dark is-size-4">{{ activity.date.toLocaleDateString() }}</p>
            </div>
          </div>
          <div class="column is-half-mobile" v-if="activity.data.total_ascent">
            <div class="p-4">
              <p class="is-uppercase is-size-7">{{ $t('metrics.ascent')}}</p>
              <p class="has-text-weight-bold has-text-neutral-dark is-size-4">{{ activity.data.total_ascent }}m</p>
            </div>
          </div>
        </div>
        <div class="columns is-mobile is-multiline">
          <div class="column is-half-mobile" v-if="activity.data.normalized_power">
            <div class="p-4">
              <p class="is-uppercase is-size-7">{{ $t('metrics.npower')}}</p>
              <p class="has-text-weight-bold has-text-neutral-dark is-size-4">{{ activity.data.normalized_power }}W</p>
            </div>
          </div>
          <div class="column is-half-mobile" v-if="activity.data.calories">
            <div class="p-4">
              <p class="is-uppercase is-size-7">{{ $t('metrics.calories')}}</p>
              <p class="has-text-weight-bold has-text-neutral-dark is-size-4">{{ activity.data.calories }}Kj</p>
            </div>
          </div>
          <div class="column is-half-mobile" v-if="activity.data.intensity">
            <div class="p-4">
              <p class="is-uppercase is-size-7">{{ $t('metrics.intensity')}}</p>
              <p class="has-text-weight-bold has-text-neutral-dark is-size-4">{{ activity.data.intensity.toFixed(2) }}</p>
            </div>
          </div>
          <div class="column is-half-mobile" v-if="activity.data.load">
            <div class="p-4">
              <p class="is-uppercase is-size-7">{{ $t('metrics.load')}}</p>
              <p class="has-text-weight-bold has-text-neutral-dark is-size-4">{{ activity.data.load }}</p>
            </div>
          </div>
          <!--<div class="column is-narrow" v-if="activity.data.trimp">
            <div class="p-4 has-background-neutral-lighter">
              <p class="has-text-weight-bold has-text-neutral-dark is-uppercase is-size-6">{{ $t('metrics.trimp')}}</p>
              <p class="has-text-weight-bold has-text-primary is-size-4">{{ activity.data.trimp }}</p>
            </div>
          </div>
          <div class="column is-narrow" v-if="activity.data.power_hr_ratio">
            <div class="p-4 has-background-neutral-lighter">
              <p class="has-text-weight-bold has-text-neutral-dark is-uppercase is-size-6">{{ $t('activity.power_hr_ratio')}}</p>
              <p class="has-text-weight-bold has-text-primary is-size-4">{{ activity.data.power_hr_ratio.toFixed(2) }}</p>
            </div>
          </div>
          <div class="column is-narrow" v-if="activity.data.aerobic_decoupling">
            <div class="p-4 has-background-neutral-lighter">
              <p class="has-text-weight-bold has-text-neutral-dark is-uppercase is-size-6">{{ $t('activity.aerobic_decoupling')}}</p>
              <p class="has-text-weight-bold has-text-primary is-size-4">{{ activity.data.aerobic_decoupling.toFixed(2) }}%</p>
            </div>
          </div>-->
        </div>
      </div>

      <div class="column">
        <b-table :data="avgMaxData" :mobile-cards="false">
          <b-table-column field="data" :label="$t('activity.data')" v-slot="props">
            {{ props.row.data }}
          </b-table-column>

          <b-table-column field="planned" :label="$t('metrics.average')" v-slot="props">
            {{ props.row.avg }}
          </b-table-column>

          <b-table-column field="done" :label="$t('metrics.max').toUpperCase()" v-slot="props">
            {{ props.row.max }}
          </b-table-column>
        </b-table>
      </div>
    </div>

    <div class="columns is-mobile is-multiline">
      <TimeInZoneDonut class="column is-narrow" v-if="activity.data.time_power_zones" :zonesType="'power'" :timeInZones="activity.data.time_power_zones" :model="model"/>
      <TimeInZoneDonut class="column is-narrow" v-if="activity.data.time_hr_zones" :zonesType="'hr'" :timeInZones="activity.data.time_hr_zones" :model="model"/>
    </div>


  </div>
</template>

<script>
import TimeInZoneDonut from '@/components/TimeInZoneDonut.vue';


export default {
  name: 'activitySummary',
  props: ['activity', 'model'],
  data() {
    return {
    };
  },
  computed: {
    trainingData(){
      let data = [];
      let workout = this.ugetWorkout(this.activity.bound_workout);

      data.push({ data: this.$t('dates.date'),  planned: workout?.date?.toLocaleDateString() || '_',
        done: this.activity.date.toLocaleDateString() });
      data.push({ data: this.$t('metrics.duration'), planned: this.utimeToString(workout?.duration) || '_',
        done: this.utimeToString(this.activity.duration) });
      data.push({ data: this.$t('metrics.distance'), planned: workout?.data?.distance ? this.showDistance(workout.data.distance) : '_',
        done: this.showDistance(this.activity?.data?.distance || 0) });
      data.push({ data: this.$t('metrics.ascent'), planned: workout?.data?.total_ascent ? this.showHeight(workout.data.total_ascent) : '_',
        done: this.showHeight(this.activity?.data?.total_ascent || 0) });

      if(this.activity.data?.normalized_power)
        data.push({ data: this.$t('metrics.npower'), planned: workout?.data?.np || '_',
          done: this.activity?.data?.normalized_power });
      if(this.activity.data?.load)
        data.push({ data: this.$t('metrics.load'), planned: workout?.data?.load || '_',
          done: this.activity?.data?.load || '0' });
      return data;
    },

    avgSpeed(){
      if(this.activity.data.avg_speed)
        return this.activity.data.avg_speed;

      if(this.activity.duration && this.activity.data.distance){
        return this.activity.data.distance / this.activity.duration * 3600;
      }

      return null;
    },

    avgMaxData(){
      let data = [];

      if(this.avgSpeed || this.activity.data.max_speed)
        data.push({ data: this.$t('metrics.speed'),
          avg: this.showSpeed(this.avgSpeed), max: this.showSpeed(this.activity.data.max_speed) });

      if(this.activity.data.avg_heart_rate || this.activity.data.max_heart_rate)
        data.push({ data: this.$t('metrics.heart_rate'),
          avg: this.activity.data.avg_heart_rate + 'bpm', max: this.activity.data.max_heart_rate + 'bpm' });

      if(this.activity.data.avg_power || this.activity.data.max_power)
        data.push({ data: this.$t('metrics.power'),
          avg: this.activity.data.avg_power + 'w', max: this.activity.data.max_power + 'w' });

      if(this.activity.data.avg_cadence || this.activity.data.max_cadence)
        data.push({ data: this.$t('metrics.cadence'),
          avg: this.activity.data.avg_cadence + 'rpm', max: this.activity.data.max_cadence + 'rpm' });

      return data;
    }
  },
  components: {
    TimeInZoneDonut,
  },
}
</script>

<style lang="scss" scoped>
</style>
