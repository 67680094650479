<template>
  <div class="power-trends" v-if="trends && trends['5'].length > 0">
    <h2 class="title is-4">{{ $t('dashboard.power_trends') }}</h2>
    <table class="has-text-centered">
      <thead>
        <tr> <th>5s</th> <th>1min</th><th>5min</th><th>20min</th><th>60min</th></tr>
      </thead>
      <tbody>
        <template v-for="(t, i) in trends['5']">
          <tr v-bind:key="i">
            <template v-for="time in Object.keys(trends)">
              <td v-bind:key="time" v-if="i<trends[time].length" class="trend" v-bind:class="{ today: LocalDate.new(trends[time][i].date).isToday() }"
              v-on:click="toActivity(trends[time][i].activity)">
                <span class="has-text-weight-bold">{{ trends[time][i].power }}</span> <br/>
                <span>{{ LocalDate.new(trends[time][i].date).toLocaleDateString() }}</span>
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'powertrends',
  data() {
    return {
      trends: null
    };
  },
  methods: {
    toActivity(activity){
      this.$router.push({ name: 'activity', params : { activityToView: activity }, query: { serial: activity.serial_number } });
    },

    calcTrends(durations = [5,60,300,1200,3600]){
      this.trends = {};
      durations.forEach(duration =>{
        this.trends[duration.toString()] = [];
      });

      this.$store.getters.activities.forEach(activity => {
        if(activity.data.power_trends){
          durations.forEach(duration =>{
            var d = duration.toString();
            let i=0;
            var power = activity.data.power_trends[d];
            for(i=0; i<Math.min(this.trends[d].length, 10); i++){
              if(power > this.trends[d][i].power)
                break;
            }
            if(i<10){
              this.trends[d].splice(i, 0, { power: power, date: activity.date, activity: activity });
            if(this.trends[d].length > 10)
              this.trends[d].pop();
            }
          });
        }
      });
    }
  },
  mounted: function(){
    this.calcTrends();
    /*try{
      this.trends = (await this.get('/power_trends')).power_trends;
    }catch(err){
      console.error(err);
    }*/
  },
  components: {
  },
}
</script>

<style lang="scss">
.power-trends table{
  border-collapse:collapse;
  margin: auto;
  width: 95%;
}
.power-trends td{
  border-width:1px;
  border-style:solid;
  border-color:grey;
}

.trend{
  cursor: pointer;
}
.today{
  color: #37a973;
}
</style>
