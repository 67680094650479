<template>
  <div class="progression">
    <div id="tooltip-row" class="is-clipped">
      <div id="tooltip">
        <p class="inline-block">{{ tooltipData.date.toLocaleDateString() }}</p>
      </div>
    </div>
    <div id="progression-charts">
      <MeasureChart v-if="weights && weights.length" ref="weightChart" id="weight-chart"
        :entries="weights" :startDate="dateRange[0]" :name="$t('metrics.weight')"
        elementListener="#progression-charts" :xAxis="false"
        :mouseMoveListener="event => { this.updateTooltip(event.mouse_x); }"/>

      <MeasureChart v-if="restingHrs && restingHrs.length" ref="restingHrChart" id="resting-hr-chart"
        :entries="restingHrs" :startDate="dateRange[0]" :name="$t('metrics.resting_heart_rate')"
        elementListener="#progression-charts" :xAxis="false"/>

      <MeasureChart v-if="thresholdHrs && thresholdHrs.length" ref="thresholdHrChart" id="threshold-hr-chart"
        :entries="thresholdHrs" :startDate="dateRange[0]" :name="$t('metrics.threshold_heart_rate')"
        elementListener="#progression-charts" :xAxis="false"/>

      <MeasureChart v-if="maxHrs && maxHrs.length" ref="maxHrChart" id="max-hr-chart"
        :entries="maxHrs" :startDate="dateRange[0]" :name="$t('metrics.max_heart_rate')"
        elementListener="#progression-charts" :xAxis="false"/>

      <MeasureChart v-if="ftps && ftps.length && $store.getters.hasPremiumAccess" ref="ftpChart" id="ftp-chart"
        :entries="ftps" :startDate="dateRange[0]" :name="$t('metrics.ftp')"
        elementListener="#progression-charts" :xAxis="false"/>

      <MeasureChart v-if="maps && maps.length && $store.getters.hasPremiumAccess" ref="mapChart" id="map-chart"
        :entries="maps" :startDate="dateRange[0]" :name="$t('metrics.map')"
        elementListener="#progression-charts" :xAxis="false"/>

      <MeasureChart v-if="maxPowers && maxPowers.length && $store.getters.hasPremiumAccess" ref="maxPowerChart" id="max-power-chart"
        :entries="maxPowers" :startDate="dateRange[0]" :name="$t('metrics.max_power')"
        elementListener="#progression-charts" :xAxis="false"/>

      <MeasureChart v-if="acapacities && acapacities.length && $store.getters.hasPremiumAccess" ref="anaerobicChart" id="anaerobic-chart"
        :entries="acapacities" :startDate="dateRange[0]" :name="$t('metrics.anaerobic_capacity')"
        elementListener="#progression-charts" :xAxis="false"/>


    </div>
  </div>
</template>

<script>
var PowerModel = require('@/../shared/powerModel.js');
import MeasureChart from './MeasureChart.vue';


export default{
  name: 'progression',
  data(){
    return {
      dateRange: [this.LocalDate.new(0), this.LocalDate.now()],
      nbDays: 0,
      weights: [],
      ftps: [],
      maps: [],
      maxPowers: [],
      acapacities: [],
      thresholdHrs: [],
      maxHrs: [],
      restingHrs: [],
      tooltipData: { date: this.LocalDate.today() },
    };
  },
  methods: {
    updateTooltip(mouse_x){
      var index = this.$refs.weightChart.indexOfPosition(mouse_x);
      this.tooltipData = {
        date: this.dateRange[0].addDay(index),
      };
      const width = document.getElementById('progression-charts').clientWidth;
      var translateValue = Math.max(Math.min(mouse_x, width-300), 50);
      document.getElementById("tooltip").style.transform = "translateX("+translateValue+"px)";
    },

    calcData(){
      var activities = this.$store.getters.activities;
      var workouts= this.$store.getters.workouts;
      var minTime = this.LocalDate.now();
      var maxTime = this.LocalDate.now();

      const forActivities = (activity) => {
        var d = activity.date.dateFormat();
        if(activity.date.getTime() < minTime)
          minTime = new this.LocalDate(d);
        if(activity.date.getTime() > maxTime)
          maxTime = new this.LocalDate(d);
      }
      activities.forEach(activity => forActivities(activity));
      minTime = minTime.addDay(-1);
      maxTime = maxTime.addDay();
      this.dateRange = [minTime, maxTime];
      this.nbDays = this.dateRange[0].daysDiff(this.dateRange[1]);

      var measures = this.$store.getters.measures;
      var currentMeasure = this.ugetLastMeasure(minTime);

      this.weights = new Array(this.nbDays);
      this.ftps = new Array(this.nbDays);
      this.maps = new Array(this.nbDays);

      this.maxPowers = new Array(this.nbDays);
      this.acapacities = new Array(this.nbDays);
      this.thresholdHrs = new Array(this.nbDays);
      this.maxHrs = new Array(this.nbDays);
      this.restingHrs = new Array(this.nbDays);


      for(let i=0; i<this.nbDays; i++){
        let day = minTime.addDay(i);
        let m = this.ugetMeasure(day);
        if(m)
          currentMeasure = m;
        this.weights[i] = parseFloat(this.showWeight(currentMeasure.weight, 1, false));
      }

      var models = this.$store.getters.models;
      var mindex = -1;
      var currentModel = { threshold_power: 0, anaerobic_capacity: 0, max_power: 0, threshold_heart_rate: 0, max_heart_rate: 0, resting_heart_rate: 0, };
      for(let i=0; i<this.nbDays; i++){
        let day = minTime.addDay(i);
        let m = this.ugetModel(day);
        if(m)
          currentModel = m;
        this.ftps[i] = currentModel.threshold_power;
        this.maps[i] = PowerModel.modelMAP(currentModel);
        this.maxPowers[i] = currentModel.max_power;
        this.acapacities[i] = currentModel.anaerobic_capacity;
        this.thresholdHrs[i] = currentModel.threshold_heart_rate;
        this.maxHrs[i] = currentModel.max_heart_rate;
        this.restingHrs[i] = currentModel.resting_heart_rate;
      }
    }
  },
  mounted(){
    this.calcData();
  },
  components: {
    MeasureChart,
  },
}
</script>

<style lang="scss">
#tooltip{
  display: inline-block;
  float: left;
}

#progression-charts{
  padding:0;
  margin:auto;
  display: inline-block;
  width: 100%;
}

.verticalLine{
  stroke: grey;
  stroke-width: 1px;
}
.selectionRect{
  fill: grey;
  stroke: black;
  stroke-width: 1px;
}

.line {
  fill: none;
  stroke-width: 1px;
  stroke: black;
}

</style>
