<template>
  <div class="fitness">
    <TodayFitness/>

    <div id="fitness-chart">
      <div id="fit-tooltip-row" class="is-clipped">
        <div id="fit-tooltip">
          <table class="has-text-centered">
            <tr> <td>{{ $t('dates.date') }} </td> <td>{{ $t('performance.fitness' )}}</td> <td>{{ $t('performance.fatigue' )}}</td> <td>{{ $t('performance.form' )}}</td> </tr>
            <tr> <td>{{ tooltipData.date.toLocaleDateString() }}</td> <td>{{ tooltipData.fitness }}</td> <td>{{ tooltipData.fatigue}}</td> <td>{{ tooltipData.form }}</td></tr>
          </table>
        </div>
      </div>
      <div id="fit-charts">
        <LoadChart v-if="ctl && atl && ctl.length && atl.length" ref="loadChart" id="load-chart"
          :ctl="ctl" :atl="atl" :startDate="dateRange[0]" :name="$t('performance.training_load')"
          elementListener="#fit-charts" :xAxis="false"
          :mouseMoveListener="event => { this.updateTooltip(event.mouse_x); }"/>

        <FormChart v-if="tsb && tsb.length" ref="formChart" id="form-chart"
          :entries="tsb" :startDate="dateRange[0]" :name="$t('performance.form')" elementListener="#fit-charts" />

      </div>
    </div>
  </div>
</template>

<script>
import TodayFitness from './TodayFitness.vue';
import FormChart from './FormChart.vue';
import LoadChart from './LoadChart.vue';

export default {
  name: 'fitness',
  data() {
    return {
      dateRange: [this.LocalDate.new(0), this.LocalDate.now()],
      nbDays: 0,
      loadMap: {},
      ctl: [],
      atl: [],
      tsb: [],
      weeklyLoad: [],
      tooltipData: { date: this.LocalDate.now(), fitness: 0, fatigue: 0, form: 0 }
    };
  },
  methods: {
    calculateFitness(){
      var activities = this.$store.getters.activities;
      var workouts= this.$store.getters.workouts;
      var minTime = this.LocalDate.now();
      var maxTime = this.LocalDate.now();

      const forActivities = (activity, isWorkout = false) => {
        var d = activity.date.dateFormat();
        if(!isWorkout || activity.date > new this.LocalDate() || (this.loadMap[d] == 0 && activity.date.isToday())){
          if(activity.date.getTime() < minTime)
            minTime = new this.LocalDate(d);
          if(activity.date.getTime() > maxTime)
            maxTime = new this.LocalDate(d);

          //Update tss for the day for activity.
          //Same with workout but only if it's planned in the future and not in the past (or if it's planned for today if no activity yet)
          if(!this.loadMap[d])
            this.loadMap[d] = 0;
          this.loadMap[d] += activity.data.load;
        }
      }
      activities.forEach(activity => forActivities(activity));
      workouts.forEach(workout => forActivities(workout, true));
      minTime = minTime.addDay(-1);
      maxTime = maxTime.addDay();
      this.dateRange = [minTime, maxTime];
      this.nbDays = this.dateRange[0].daysDiff(this.dateRange[1]);

      this.ctl = new Array(this.nbDays);
      this.atl = new Array(this.nbDays);
      this.tsb = new Array(this.nbDays);

      this.ctl[0] = 0;
      this.atl[0] = 0;
      this.tsb[0] = 0;

      for(let i=1; i<this.nbDays; i++){
        let day = minTime.addDay(i);
        let load = this.loadMap[day.dateFormat()];
        load = load ? load : 0;
        this.ctl[i] = this.ctl[i-1] + (load-this.ctl[i-1])/42;
        this.atl[i] = this.atl[i-1] + (load-this.atl[i-1])/7;
        this.tsb[i] = this.ctl[i] - this.atl[i];
      }

      for(let i=1; i<this.nbDays; i++){
        this.ctl[i] = Math.round(this.ctl[i]);
        this.atl[i] = Math.round(this.atl[i]);
        this.tsb[i] = Math.round(this.tsb[i]);
      }

    },

    updateTooltip(mouse_x){
      var index = this.$refs.loadChart.indexOfPosition(mouse_x);
      this.tooltipData = {
        date: this.dateRange[0].addDay(index),
        fitness: this.ctl[index],
        fatigue: this.atl[index],
        form: this.tsb[index]
      };
      const width = document.getElementById('fitness-chart').clientWidth;
      var translateValue = Math.max(Math.min(mouse_x, width-300), 50);
      document.getElementById("fit-tooltip").style.transform = "translateX("+translateValue+"px)";
    },
  },
  mounted(){
    this.calculateFitness();
    this.$nextTick(()=>{ this.updateTooltip(this.ctl.length-1); });
  },
  components: {
    TodayFitness,
    FormChart,
    LoadChart,
  }
}
</script>

<style lang="scss">
#fit-tooltip-row{
}
#fit-tooltip{
  display: inline-block;
  float: left;
}

#fit-charts{
  padding:0;
  margin:auto;
  display: inline-block;
  width: 100%;
}

.verticalLine{
  stroke: grey;
  stroke-width: 1px;
}
.selectionRect{
  fill: grey;
  stroke: black;
  stroke-width: 1px;
}

.line {
  fill: none;
  stroke-width: 1px;
  stroke: black;
}

#load-chart .line0 {
  stroke: blue;
}
#load-chart .line1 {
  stroke: red;
}

#form-chart .line{
  stroke: url(#line-gradient);
  stroke-width: 2px;
}
</style>
