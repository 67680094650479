<template>
  <div :class="[ 'border-'+workout.tag, 'workout-summary-big', 'has-text-left', 'box' ]">
    <div class="columns is-mobile">
      <div class="column">
        <p v-if="workout.date">{{ workout.date.toLocaleDateString() }}</p>
      </div>
      <div class="column has-text-right has-text-weight-bold">
        <a class="has-text-secondary-light" @click="toWorkout()">{{ $t('general.see_detail') }}</a>
      </div>
    </div>
    <h4 class="title is-5 is-inline-block mr-4">{{ workout.name }}</h4>
    <span v-if="workout.tag" :class="[ 'tag', 'is-medium', 'tag-'+workout.tag ]">{{ $t('workout.'+workout.tag) }}</span>

    <WorkoutViewer :workout="workout" :showAxis="false" class="workout-view"/>
    <div class="columns is-mobile">
      <p class="column"><b-icon icon="timer-outline"/>{{ utimeToString(workout.duration) }}</p>
      <p class="column" v-if="workout.data.distance"><b-icon icon="chart-timeline-variant"/>{{ showDistance(workout.data.distance, 0) }}</p>
      <p class="column" v-if="workout.data.load"><b-icon icon="thermometer"/>{{ workout.data.load + ' TSS' }}</p>
      <p class="column" v-if="user && $store.getters.hasCoachAccess && workout.data && workout.data.level"><b>Level: </b> {{ workout.data.level }}</p>
    </div>
  </div>
</template>

<script>
import WorkoutViewer from './WorkoutViewer.vue';
const workoutUtil = require('@/../shared/workout/workoutUtil.js');


export default{
  name: 'workout-summary-big',
  props: {
    workout: Object,
    showDate: { type: Boolean, default: false },
  },
  computed: {
    user(){ return this.$store.getters.user; },
  },
  data(){
    return {

    };
  },

  methods: {
    toWorkout(){
      this.$router.push({ name: 'workout', params : { workoutToView: this.workout }, query: { id: this.workout.id } });
    }
  },
  components: {
    WorkoutViewer,
  }
}
</script>

<style lang="scss" scoped>


.workout-summary-big{
  border-left: solid 5px;
  border-color: $secondary-light;
}

.border-recovery{ border-color: $chart-blue; }
.border-endurance{ border-color: $chart-green; }
.border-tempo{ border-color: $chart-yellow; }
.border-threshold{ border-color: $chart-orange; }
.border-vo2max{ border-color: $chart-red; }
.border-anaerobic{ border-color: $chart-red-dark; }
.border-sprint{ border-color: $chart-purple; }
.border-test{ border-color: $chart-purple; }

.tag-recovery{ background-color: $chart-blue-lighter !important; color: $chart-blue-darker !important; }
.tag-endurance{ background-color: $chart-green-lighter !important; color: $chart-green-darker !important; }
.tag-tempo{ background-color: $chart-yellow-lighter !important; color: $chart-yellow-darker !important; }
.tag-threshold{ background-color: $chart-orange-lighter !important; color: $chart-orange-darker !important; }
.tag-vo2max{ background-color: $chart-red-lighter !important; color: $chart-red-darker !important; }
.tag-anaerobic{ background-color: $chart-red-lighter !important; color: $chart-red-darker !important; }
.tag-sprint{ background-color: $chart-purple-lighter !important; color: $chart-purple-darker !important; }
.tag-test{ background-color: $chart-purple-lighter !important; color: $chart-purple-darker !important; }

.workout-view{
  height: 60px;
}
</style>
