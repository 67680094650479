<template>
  <div class="map">
  </div>
</template>

<script>
import L from 'leaflet';


export default {
  name: 'activityMap',
  props: ['positions_lat', 'positions_long', 'marker_position', 'highlight'],
  data() {
    return {
      map: null,
      coords: [],
      marker: null,
      highlightPath: null
    };
  },
  methods: {
    createMap(){
      if(this.positions_lat.length == 0 || this.positions_long.length == 0)
        return;

      this.coords = [];
      for(var i=0; i<this.positions_lat.length; i++){
        if(this.positions_lat[i] && this.positions_long[i])
          this.coords.push(new L.LatLng(this.positions_lat[i], this.positions_long[i]));
      }

      this.map = L.map(this.$el).fitBounds(this.coords);

      L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: process.env.VUE_APP_MAP_BOX_TOKEN
      }).addTo(this.map);

      var path = new L.Polyline(this.coords, {
        color: 'red',
        weight: 3,
        opacity: 0.5,
        smoothFactor: 1
      }).addTo(this.map);


      this.marker = L.circleMarker(this.marker_position ? this.coords[this.marker_position] : [0,0], {
        color: 'white',
        fillColor: 'blue',
        fillOpacity: 0.9,
        radius: 10
      }).addTo(this.map);

    }
  },
  mounted: function(){
    this.createMap();
  },

  watch: {
    marker_position: function(newVal, oldVal){
      if(newVal >= 0 && newVal < this.coords.length)
        this.marker.setLatLng(this.coords[newVal]);
    },

    highlight: function(newVal, oldVal){
      if(this.highlightPath){
        this.highlightPath.remove(this.map);
        delete this.highlightPath;
      }
      if(newVal && newVal.length==2){
        this.highlightPath = new L.Polyline(this.coords.slice(newVal[0], newVal[1]), {
          color: 'blue',
          weight: 3,
          opacity: 1,
          smoothFactor: 1
        }).addTo(this.map);
      }
    }
  },

  components: {
  },
}
</script>

<style lang="scss" scoped>
@import '~leaflet/dist/leaflet.css';

.map{
  height: 250px;
  width: 100%;
  z-index: 0;
}

.leaflet-fade-anim .leaflet-tile,.leaflet-zoom-anim .leaflet-zoom-animated { will-change:auto !important; }
</style>
