<template>
  <div v-if="activity && !ugetWorkout(activity.bound_workout)">
    <div class="activitybox is-clickable is-relative" @click="toActivity()" @contextmenu.prevent="$refs.activityMenu.open">
      <span class="pos-top-right is-clickable" @click.stop="$refs.activityMenu.open"><b-icon icon="dots-vertical"/></span>
      <div class="hero is-info">
        <h6 class="title is-6">
          <b-icon v-if="activity.type=='cycling'" size="is-small" icon="bike"/>
          <b-icon v-if="activity.type=='running'" size="is-small" icon="run"/>
          {{ activity.name }}
        </h6>
      </div>
      <div class="mt-1">
        <p> {{ utimeToString(activity.duration) }} {{ showDistance(activity.data.distance) }}</p>
        <p><span class="hr" v-if="activity.data.avg_heart_rate"> {{ activity.data.avg_heart_rate }}bpm </span> <span class="power" v-if="activity.data.avg_power"> {{ activity.data.avg_power }}w </span> </p>
        <p v-if="activity.data.load"> {{ $t('metrics.load') }} : {{ activity.data.load }}</p>

        <b-tag v-if="activity.intensity_done">{{ $t('metrics.' + activity.intensity_done) }}</b-tag>
        <b-tag v-else-if="!activity.intensity_done && activity.bound_training_ride && boundTrainingRide.planned_intensity">{{ $t('metrics.' + boundTrainingRide.planned_intensity) }}</b-tag>
      </div>
    </div>
    <b-button @click.native="validate()" v-if="!activity.intensity_done && !activity.bound_workout && !ugetWorkoutsForDay(activity.date).length && !ugetTrainingRidesForDay(activity.date).length && (activity.date.isThisWeek() || activity.date.daysDiff(new Date()) < 3)"
      class="is-primary is-small w-100p">
        {{ $t('activity.rate_activity') }}
    </b-button>


    <vue-context ref="activityMenu">
      <li v-if="!activity.bound_training_ride"><a @click="edit()">{{ $t('general.edit') }}</a></li>
      <template v-if="!activity.bound_workout">
        <li v-for="(workout, i) in ugetWorkoutsForDay(activity.date)" :key="i">
          <a v-if="!workout.bound_activity" @click="bindWorkout(workout)">{{ $t('workout.bind_with') + workout.name }}</a>
        </li>
      </template>
      <template v-if="!activity.bound_training_ride">
        <li v-for="(trainingRide, i) in ugetTrainingRidesForDay(activity.date)" :key="i">
          <a v-if="!trainingRide.bound_activity" @click="bindTrainingRide(trainingRide)">{{ $t('workout.bind_with') + trainingRide.name }}</a>
        </li>
      </template>
      <template v-if="activity.bound_training_ride">
        <li><a @click="edit()">{{ $t('activity.edit_activity') }}</a></li>
        <li><a @click="editTrainingRide()">{{ $t('training_ride.edit_training_ride') }}</a></li>
        <li><a @click="unbindTrainingRide()">{{ $t('training_ride.unbind') }}</a></li>
      </template>
      <li><a @click="del()">{{ $t('general.delete') }}</a></li>
    </vue-context>
  </div>
</template>

<script>
import VueContext from 'vue-context';

export default {
  name: 'activitybox',
  props: ['activity'],
  computed: {
    boundTrainingRide(){ return this.ugetTrainingRide(this.activity.bound_training_ride); },
  },
  methods: {
    toActivity(){
      this.$router.push({ name: 'activity', params : { activityToView: this.activity }, query: { serial: this.activity.serial_number } });
    },

    del(){
      this.$store.dispatch('removeActivity', this.activity).then(() =>{
      }).catch(err => {
         console.error(err);
      });
    },

    edit(){
      this.$eventBus.$emit('show-modal-edit-activity', { activity: this.activity });
    },

    bindWorkout(workout){
      this.$store.dispatch('bindWorkoutActivity', { workout: workout, activity: this.activity }).then().catch(err => console.log(err));
    },

    bindTrainingRide(trainingRide){
      this.$store.dispatch('bindTrainingRideActivity', { training_ride: trainingRide, activity: this.activity }).then().catch(err => console.log(err));
    },

    validate(){
      this.$eventBus.$emit('show-modal-rate-activity', { activity: this.activity });
    },


    editTrainingRide(){
      if(this.activity.bound_training_ride)
        this.$eventBus.$emit('show-modal-new-trainingride', { trainingRide: this.boundTrainingRide });
    },

    unbindTrainingRide(){
      if(this.activity.bound_training_ride)
        this.$store.dispatch('unbindTrainingRideActivity', { training_ride: this.boundTrainingRide, activity: this.activity }).then().catch(err => console.log(err));
    },
  },
  components: {
    VueContext
  }
}
</script>

<style lang="scss">
.activitybox{
  border-bottom: solid;
}
</style>
