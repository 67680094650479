<template>
  <div class="stepsDisplay">
    <!-- IF steps descriptions are available display these -->
    <div v-if="stepsDescriptions && stepsDescriptions.length">
      <template v-for="(step, i) in stepsDescriptions">
        <div class="columns is-mobile is-vcentered" :key="i" :set="target = displayDescriptionTarget(step.target, step.duration)">
          <div class="column is-narrow">
            <span class="has-text-weight-bold is-size-4" :class="'has-text-zone-'+step.zone">{{ i+1 }} |</span>
          </div>
          <div class="column is-narrow has-text-left">
            <p>{{ step.description }}</p>
            <p>
              <b-icon size="is-small" icon="timer-outline"/>{{ utimeToString(step.duration) }}
              <b-icon v-if="target.unit === 'rpe'" size="is-small" icon="speedometer"/>
              <b-icon v-if="target.unit === 'heart_rate'" size="is-small" icon="heart"/>
              <b-icon v-if="target.unit === 'power'" size="is-small" icon="flash"/>
              {{ target.text }}
            </p>
          </div>
        </div>
      </template>
    </div>

    <!-- Else display defaults values of each steps -->
    <div v-else>
      <div v-if="isTopLevel" class="block columns is-mobile">
        <div class="column">
          <h5 class="title is-6">{{ $t('metrics.duration')}}</h5>
        </div>
        <div class="column">
          <h5 class="title is-6">{{ $t('workout.target')}}</h5>
        </div>
        <div class="column">
          <h5 class="title is-6">{{ $t('workout.target')}}</h5>
        </div>
        <div class="column" v-if="hasCadenceTarget()">
          <h5 class="title is-6">{{ $t('workout.cadence_target')}}</h5>
        </div>
      </div>


      <template v-for="(step, index) in steps">

        <div class="block" :key="index">
          <h5 class="title is-6" v-if="step.name">{{ step.name }}</h5>
          <p v-if="step.description">{{ step.description }}</p>

          <div v-if="step.type==='REPEAT'" class="columns is-mobile is-vcentered">
            <div class="column is-narrow">
              <p><b>{{ step.repeatitions }}x</b></p>
            </div>
            <div class="column">
              <StepsDisplay :isTopLevel="false" :steps="step.steps" :model="model" :targetUnit="targetUnit"/>
            </div>
          </div>


          <div v-if="step.type==='STEP'" class="columns is-mobile">
            <div class="column p-0">
              <p>{{ utimeToString(step.duration) }}</p>
            </div>
            <div class="column p-0">
              <p>{{ targetValue(step).text }}</p>
            </div>
            <div class="column p-0">
              <p>{{ step.target }}% {{ $t('metrics.'+step.target_type)}} {{ $t('metrics.'+step.target_unit)}}</p>
            </div>
            <div class="column p-0" v-if="hasCadenceTarget()">
              <p v-if="step.cadence_target">{{ step.cadence_target }} rpm</p>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const workoutUtil = require('@/../shared/workout/workoutUtil.js');

export default{
  name: 'StepsDisplay',
  props: {
    stepsDescriptions: { type: Array, default:() => []},
    steps: { type: Array, default:() => [] },
    model: { type: Object, default:() => {} },
    targetUnit: { type: String, default: null },
    isTopLevel: { type: Boolean, default: true },
  },
  mounted(){ },
  methods: {
    hasCadenceTarget(steps = this.steps){
      for(let step of steps){
        if(step.cadence_target || (step.type === 'REPEAT' && this.hasCadenceTarget(step.steps)))
          return true;
      }
      return false;
    },

    targetValue(step){
      var convertedStep = workoutUtil.convertStepWithModel(step, this.model, this.targetUnit);
      const units = { power: 'W', heart_rate: this.$t('metrics.bpm'), rpe: this.$t('metrics.rpe') };

      var unit =  units[convertedStep.target_unit];
      var value = convertedStep.value;
      return { text: value + '' + unit, unit: convertedStep.target_unit };
    },


    displayDescriptionTarget(target, duration){
      let targets = target.split('-'); //Check if it's a value range and if so display as a range
      if(targets.length > 1){
        let t1 = this.displayDescriptionTarget(targets[0], duration);
        let t2 = this.displayDescriptionTarget(targets[1], duration)
        return  { text: t1.text + ' - ' + t2.text, unit: t1.unit };
      }

      let split = target.split('%');
      if(split.length === 1){
        console.error('No % value, either an error or RPE Value are todo');
      }

      let value = parseInt(split[0]);
      const TYPES = { 'ftp': 'threshold', 'max': 'max', 'wbal': 'anaerobic', 'map': 'map'};
      let targetType = TYPES[split[1].toLowerCase()];

      let step = { target: value, target_type: targetType, duration: duration, target_unit: 'power' };
      return this.targetValue(step);
    }


  },
};
</script>

<style lang="scss" scoped>
</style>
