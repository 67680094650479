<template>
  <div>
    <CalendarMobile v-if="$root.isMobile()"/>
    <CalendarDesktop v-else/>

    <Tour name="calendar-tour" :steps="steps" :start="$store.getters.user.onboarding.show_calendar_tour && $planExist()" onboardingVariable="show_calendar_tour"/>
  </div>
</template>

<script>
import CalendarDesktop from './CalendarDesktop.vue';
import CalendarMobile from './mobile/CalendarMobile.vue';
import Tour from '@/components/Tour.vue';

export default{
  name: 'Calendar',
  data(){
    return{
      steps: [
        { content: 'onboarding.calendar0', onNext: this.closePlus },
        { content: 'onboarding.calendar1', onNext: this.openPlus, onPrevious: this.closePlus, },
        { content: 'onboarding.calendar2', onNext: this.openPlus, onPrevious: this.closePlus, },
        { content: 'onboarding.calendar3', onNext: this.openPlus, onPrevious: this.openPlus, },
        { content: 'onboarding.calendar4', onNext: this.closePlus, onPrevious: this.openPlus, },
      ],
    };
  },
  methods: {
    openPlus(){
      if(this.$root.isMobile()){
        this.$eventBus.$emit('show-plus-button-menu');
      }else{
        this.$eventBus.$emit('show-modal-add-to-calendar');
      }
    },

    closePlus(){
      if(this.$root.isMobile()){
        this.$eventBus.$emit('close-plus-button-menu');
      }else{
        this.$eventBus.$emit('close-modal-add-to-calendar');
      }
    },
  },
  components:  {
    CalendarDesktop,
    CalendarMobile,
    Tour,
  },
};


</script>

<style lang="scss" scoped>
</style>
