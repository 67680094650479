<template>
  <div class="day is-flex-direction-column h-100p" :class="{ 'selected-day': selected }">
    <div>
      <p :class="{ 'has-text-primary-dark': date.isToday(),
        'has-text-neutral-light': currentMonth && date.getMonth() != currentMonth }">
        {{ date.getDate() }}
      </p>
    </div>
    <div>
      <template v-for="(activity, i) in ugetActivitiesForDay(date)">
        <span class="has-text-success" :key="'a'+i">⬤</span>
      </template>
      <template v-for="(workout, i) in ugetWorkoutsForDay(date)">
        <span class="has-text-info" :key="'w'+i">⬤</span>
      </template>
      <!--<MeasureBox v-if="ugetMeasure(date)" :measureData="ugetMeasure(date)"/>-->
      <span class="has-text-warning" v-if="ugetModel(date)">⬤</span>
      <template v-for="(objective, i) in ugetObjectivesForDay(date)">
        <span class="has-text-danger" :key="'o'+i">🏆</span>
      </template>
      <template v-for="(trainingRide, i) in ugetTrainingRidesForDay(date)">
        <span class="has-text-danger" :key="'t'+i">⬤</span>
      </template>
    </div>

  </div>
</template>

<script>


export default {
  name: 'day',
  props: ['date', 'selected', 'currentMonth'],
  methods: {

  },
  components: {

  }
}
</script>

<style lang="scss" scoped>


.selected-day{
  margin: auto;
  border-radius: 5%;
  border: solid 1px $primary;
}
</style>
