<template>
  <div class="InstallButton" v-if="!isInstalled">
    <b-button v-if="installable" @click="install()" size="is-medium" icon-left="download">{{ $t('general.install_app') }}</b-button>
  </div>
</template>

<script>


export default{
  name: 'InstallButton',
  data(){
    return {
      installedRelatedApps: false
    };
  },
  computed: {
    installable(){ return 'serviceWorker' in navigator && this.$root.installPrompt;  },
    isInstalled(){
      //Check if app is running in on of the PWA display modes
      return (this.installedRelatedApps || navigator.standalone || window.matchMedia('(display-mode: standalone)').matches ||
      window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: minimal-ui)').matches);
    },

    isIOSDevice(){
      return ['iPad Simulator', 'iPhone Simulator','iPod Simulator', 'iPad','iPhone','iPod'].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document); // iPad on iOS 13
    },

  },
  methods: {
    showIOSInstructions(){

    },

    install(){
      if(this.isIOSDevice) //if ios device, show ios instructions
        return this.showIOSInstructions();

      if(this.$root.installPrompt)
        this.$root.installPrompt.promt();
    },
  },


  async mounted(){
    if ('getInstalledRelatedApps' in window.navigator) //If installed related app, store result if length > 0 (it mean the app is installed)
      this.installedRelatedApps = (await window.navigator.getInstalledRelatedApps()).length > 0;
  },
};
</script>

<style lang="scss" scoped>
</style>
