<template>
  <div class="my-plan has-text-centered">
    <section class="mb-5">
      <h4 class='title is-4'>{{ $t('subscription.my_subscription') }}</h4>
      <p class="has-text-weight-semibold mb-4">
        <span v-if="$store.getters.hasTesterAccess || $store.getters.hasCoachAccess">{{ $t('subscription.currently_special_access') + $store.getters.user.account_type }}</span>
        <span v-else-if="!$store.getters.hasStandardAccess">{{ $t('subscription.currently_not_subscribed') }}</span>
        <span v-else-if="$store.getters.user.has_trial && $store.getters.account_type !== 'BASE'">{{ $t('subscription.currently_free_trial', { type: $store.getters.user.account_type, date: new Date($store.getters.user.premium_end_date).toLocaleDateString() }) }}</span>
        <span v-else-if="$store.getters.hasStandardAccess">{{ $t('subscription.currently_subscribed', { type: $store.getters.user.account_type, date: new Date($store.getters.user.premium_end_date).toLocaleDateString() }) }}</span>
      </p>

      <ManageBilling :showSubButton="true"/>
      <PromoCode/>
    </section>


    <NoTrainingPlanMessage/>
    <MyPlanData v-if="$planExist()"/>



  </div>
</template>

<script>
import ManageBilling from '@/components/ManageBilling.vue';
import MyPlanData from './MyPlanData.vue';
import NoTrainingPlanMessage from '@/components/NoTrainingPlanMessage.vue';
import PromoCode from '@/components/PromoCode.vue';


export default{
  name: 'MyPlan',
  data(){
    return {
    };
  },
  methods: {
  },
  components: {
    ManageBilling,
    MyPlanData,
    NoTrainingPlanMessage,
    PromoCode,
  },
};
</script>

<style lang="scss" scoped>
</style>
