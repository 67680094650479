<template>
  <div class="measurebox is-relative" @contextmenu.prevent="$refs.measureMenu.open">
    <span class="pos-top-right is-clickable" @click.stop="$refs.measureMenu.open"><b-icon size="is-small" icon="dots-vertical"/></span>
    <div class="hero is-warning">
      <h4 class="title is-6">{{ $t('measure.new_measure') }}</h4>
    </div>
    <ul class="mt-1">
      <li v-if="measureData.weight != previousMeasureData.weight"><b>{{ $t('metrics.weight') }}: </b>{{ showWeight(measureData.weight) }}</li>
      <li v-if="measureData.resting_heart_rate != previousMeasureData.resting_heart_rate"><b>{{ $t('metrics.resting_heart_rate') }}: </b>{{ measureData.resting_heart_rate }} bpm</li>
    </ul>


    <vue-context ref="measureMenu">
      <li><a @click="edit()">{{ $t('general.edit') }}</a></li>
      <li><a @click="del()">{{ $t('general.delete') }}</a></li>
    </vue-context>
  </div>
</template>

<script>
import VueContext from 'vue-context';

export default {
  name: 'measurebox',
  props: ['measureData'],
  data() {
    return {
      previousMeasureData: {}
    };
  },
  methods: {
    edit(){
      this.$eventBus.$emit('show-modal-new-measure', { date: this.measureData.date });
    },
    del(){
      this.$store.dispatch('removeMeasure', this.measureData).then(() =>{
      }).catch(err => {
         console.error(err);
      });
    }
  },
  mounted: function(){
    this.previousMeasureData = this.ugetLastMeasure(this.measureData.date);
  },
  components: {
    VueContext
  }
}
</script>

<style lang="scss">
.measurebox{
  border-bottom: solid;
}
</style>
