<template>
  <b-table :data="data" :mobile-cards="false">
    <b-table-column field="zone" :label="this.$t('zone.zone')" v-slot="props">
      <span :class="'has-text-'+props.row.color">⬤</span> {{ props.row.zone }}
    </b-table-column>

    <b-table-column field="desc" :label="descLabel" v-slot="props">
      {{ props.row.desc }}
    </b-table-column>
  </b-table>
</template>

<script>

const DESCLABELS = { 'power': 'metrics.power', 'heart_rate': 'metrics.heart_rate'}

export default{
  name: 'zones-table',
  props: {
    type: String,
    model: {
      type: Object,
      default: function(){ return null; },
    },
  },
  computed:{
    getModel(){ return this.model || this.ugetSetModel(); },
    descLabel(){ return this.$t(DESCLABELS[this.type]); },
  },
  data(){
    return {
      data: [],
    };
  },
  methods:{
    powerZones(){
      if(!this.getModel || !this.getModel.threshold_power)
        return [];

      var ftp = this.getModel.threshold_power;
      return [
        { zone : this.$t('zone.recovery'), color: 'chart-blue', desc: '0-' + Math.round(ftp*0.55).toString() + 'W' },
        { zone : this.$t('zone.endurance'), color: 'chart-green', desc: Math.round(ftp*0.55).toString() + '-' + Math.round(ftp*0.75).toString() + 'W' },
        { zone : this.$t('zone.tempo'), color: 'chart-yellow', desc: Math.round(ftp*0.75).toString() + '-' + Math.round(ftp*0.9).toString() + 'W' },
        { zone : this.$t('zone.threshold'), color: 'chart-orange', desc: Math.round(ftp*0.9).toString() + '-' + Math.round(ftp*1.05).toString() + 'W' },
        { zone : this.$t('zone.vo2max'), color: 'chart-red', desc: Math.round(ftp*1.05).toString() + '-' + Math.round(ftp*1.2).toString() + 'W' },
        { zone : this.$t('zone.anaerobic'), color: 'chart-red-dark', desc: Math.round(ftp*1.2).toString() + '-' + Math.round(ftp*1.5).toString() + 'W' },
        { zone : this.$t('zone.sprint'), color: 'chart-purple', desc: '> ' + Math.round(ftp*1.5).toString() + 'W' },
      ];
    },

    //Hr zones from threshold heart rate
    hrZones(){
      if(!this.getModel || !this.getModel.threshold_heart_rate)
        return [];

      var fhr = this.getModel.threshold_heart_rate;
      let min = this.getModel.resting_heart_rate || 0;
      return [
        { zone : this.$t('zone.recovery'), color: 'chart-blue', desc: min + '-' + Math.round(fhr*0.68).toString() + 'bpm' },
        { zone : this.$t('zone.endurance'), color: 'chart-green', desc: Math.round(fhr*0.68).toString() + '-' + Math.round(fhr*0.83).toString() + 'bpm' },
        { zone : this.$t('zone.tempo'), color: 'chart-yellow', desc: Math.round(fhr*0.83).toString() + '-' + Math.round(fhr*0.95).toString() + 'bpm' },
        { zone : this.$t('zone.threshold'), color: 'chart-orange', desc: Math.round(fhr*0.95).toString() + '-' + Math.round(fhr*1.05).toString() + 'bpm' },
        { zone : this.$t('zone.vo2max'), color: 'chart-red', desc: '> ' + Math.round(fhr*1.05).toString() +' bpm' },
      ];
    },
  },
  mounted(){
    switch(this.type){
      case 'power': this.data = this.powerZones(); break;
      case 'heart_rate': this.data = this.hrZones(); break;
      default: console.error('Zone table wrong props type: ' + this.type);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
