<template>
  <div>
    <h2 class="title is-3">
      {{ $t('objective.my_objectives')}}
      <Helper :label="$t('plan.help.objectives')" position="is-left"/>
    </h2>
    <p v-if="$store.getters.isBaseAccount" v-html="$t('plan.free_limit_objectives')"/>
    <section class="block">
      <div>
        <div class="m-auto is-inline-block">
          <b-button @click="addObjective()">{{ $t('objective.add_objective') }}</b-button>
        </div>
        <b-field :label="$t('objective.only_show')" horizontal class="float-right">
          <b-select v-model="show">
            <option value="all">{{ $t('general.all') }}</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </b-select>
        </b-field>
      </div>

      <div id="objectives" class="columns is-multiline mt-5">
        <template v-for="(objective, index) in objectives">
          <div v-if="show === 'all' || show === objective.priority" :key="index" class="column is-half">
            <Objective :objective="objectives[index]"/>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import Objective from './Objective.vue';
import Helper from '@/components/Helper.vue';
import { ObjectID } from 'bson';

export default{
  name: 'objectives-step',
  data(){
    return {
      show: 'all',
    };
  },
  computed:{
    objectives(){ return this.$store.getters.objectives.filter(objective => objective.date >= this.LocalDate.today()); },
  },
  methods: {
    showError(err){
      this.$buefy.toast.open({
        duration: 3000,
        message: this.$t(err),
        position: 'is-bottom',
        type: 'is-danger'
      });
    },

    addObjective(){
      this.$eventBus.$emit('show-modal-new-objective', { date: this.LocalDate.today() });
    },

  },
  components: {
    Objective,
    Helper,
  },
}
</script>

<style lang="scss" scoped>
</style>
