<!-- Steps reuse Buefy BSteps code with some modifications in design -->
<template>
  <div class="b-steps" :class="wrapperClasses">
    <div class="top-bar">
      <h2 class="title is-5">{{ title }}</h2>
      <progress class="progress-step" :max="items.length" :value="value+1"/>
    </div>
    <div class="content p-5">
      <p class="has-text-left">{{ value+1 }} / {{ items.length }}</p>

      <section class="step-content" :class="{'is-transitioning': isTransitioning}">
          <slot/>
      </section>
      <slot
        name="navigation"
        :previous="navigationProps.previous"
        :next="navigationProps.next">
        <nav v-if="hasNavigation" class="step-navigation mt-5">
            <b-button size="is-medium" :disabled="navigationProps.previous.disabled" class="mr-3"
              @click.prevent="navigationProps.previous.action">
              {{ $t('general.previous') }}
            </b-button>
            <b-button v-if="value+1 < items.length" size="is-medium" type="is-primary"
              :disabled="navigationProps.next.disabled" @click.prevent="() => { if(check()) navigationProps.next.action(); }">
              {{ $t('general.next') }}
            </b-button>
            <b-button v-if="value+1 >= items.length" size="is-medium" type="is-primary" @click.prevent="validate">
              {{ validationText }}
            </b-button>
        </nav>
      </slot>
    </div>
  </div>
</template>

<script>
import TabbedMixin from 'buefy/src/utils/TabbedMixin.js'
export default {
  name: 'Steps',
  mixins: [TabbedMixin('step')],
  props: {
    title: String,
    validationText: { type: String, default(){ return this.$t('general.validate'); } },
    type: [String, Object],
    hasNavigation: {
      type: Boolean,
      default: true
    },
    labelPosition: {
      type: String,
      validator(value) {
        return [
          'bottom',
          'right',
          'left'
        ].indexOf(value) > -1
      },
      default: 'bottom'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    mobileMode: {
      type: String,
      validator(value) {
        return [
          'minimalist',
          'compact'
        ].indexOf(value) > -1
      },
      default: 'minimalist'
    },
    check: { type: Function, default: () => true },
    validate: { type: Function, default: () => {} },
  },
  computed: {
    // Override mixin implementation to always have a value
    activeItem() {
        return this.childItems.filter((i) => i.value === this.activeId)[0] || this.items[0]
    },
    wrapperClasses() {
      return [
        this.size,
        {
          'is-vertical': this.vertical,
          [this.position]: this.position && this.vertical
        }
      ]
    },
    mainClasses() {
      return [
        this.type,
        {
          'has-label-right': this.labelPosition === 'right',
          'has-label-left': this.labelPosition === 'left',
          'is-animated': this.animated,
          'is-rounded': this.rounded,
          [`mobile-${this.mobileMode}`]: this.mobileMode !== null
        }
      ]
    },
    /**
     * Check if previous button is available.
     */
    hasPrev() {
      return this.prevItemIdx !== null
    },
    /**
     * Retrieves the next visible item index
     */
    nextItemIdx() {
      let idx = this.activeItem ? this.items.indexOf(this.activeItem) : 0
      return this.getNextItemIdx(idx)
    },
    /**
     * Retrieves the next visible item
     */
    nextItem() {
      let nextItem = null
      if (this.nextItemIdx !== null) {
        nextItem = this.items[this.nextItemIdx]
      }
      return nextItem
    },
    /**
    * Retrieves the next visible item index
    */
    prevItemIdx() {
      if (!this.activeItem) { return null }
      let idx = this.items.indexOf(this.activeItem)
      return this.getPrevItemIdx(idx)
    },
    /**
     * Retrieves the previous visible item
     */
    prevItem() {
      if (!this.activeItem) { return null }
      let prevItem = null
      if (this.prevItemIdx !== null) {
        prevItem = this.items[this.prevItemIdx]
      }
      return prevItem
    },
    /**
     * Check if next button is available.
     */
    hasNext() {
      return this.nextItemIdx !== null
    },
    navigationProps() {
      return {
        previous: {
          disabled: !this.hasPrev,
          action: this.prev
        },
        next: {
          disabled: !this.hasNext,
          action: this.next
        }
      }
    }
  },
  methods: {
    /**
     * Return if the step should be clickable or not.
     */
    isItemClickable(stepItem) {
      if (stepItem.clickable === undefined) {
        return stepItem.index < this.activeItem.index
      }
      return stepItem.clickable
    },
    /**
     * Previous button click listener.
     */
    prev() {
      if (this.hasPrev) {
        this.activeId = this.prevItem.value
      }
    },
    /**
     * Previous button click listener.
     */
    next() {
      if (this.hasNext) {
        this.activeId = this.nextItem.value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-step{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0;
  height: 2px;
  color: $primary;
  background-color: $primary-lighter;
}

.progress-step::-webkit-progress-bar{ background-color: $primary-lighter; width: 100%;}
.progress-step::-webkit-progress-value{ background-color: $primary !important; }
.progress-step::-moz-progress-bar{ background-color: $primary !important; }
</style>
