<template>
  <div class="performances has-text-left p-5">
    <h1 class="title is-3">{{ $t('performance.performances') }}</h1>
    <b-tabs multiline>
      <b-tab-item :label="$t('performance.progression')">
        <Progression/>
      </b-tab-item>

      <b-tab-item :label="$t('performance.heart_rate_curve')" :disabled="!$store.getters.hasStandardAccess">
        <HeartRateCurve/>
      </b-tab-item>

      <b-tab-item :label="$t('performance.power_curve')" :disabled="!$store.getters.hasPremiumAccess">
        <PowerCurve/>
      </b-tab-item>

      <b-tab-item :label="$t('performance.records')" :disabled="!$store.getters.hasPremiumAccess">
        <PowerTrends/>
      </b-tab-item>

      <b-tab-item :label="$t('performance.form')" :disabled="!$store.getters.hasPremiumAccess">
        <Fitness/>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import Progression from './Progression.vue';
import Fitness from './Fitness.vue';
import PowerCurve from '../curves/PowerCurve.vue';
import HeartRateCurve from '../curves/HeartRateCurve.vue';
import PowerTrends from './PowerTrends.vue';

export default{
  name: 'Performances',
  data(){
    return {

    };
  },
  components: {
    Progression,
    Fitness,
    PowerCurve,
    HeartRateCurve,
    PowerTrends,
  },
};
</script>

<style lang="scss" scoped>
</style>
