<template>
  <b-step-item :label="$t('objective.objectives')" value="objectives">
    <ObjectivesList/>
  </b-step-item>
</template>

<script>
import ObjectivesList from '@/views/objective/ObjectivesList.vue';
import Helper from '@/components/Helper.vue';
import { ObjectID } from 'bson';

export default{
  name: 'objectives-step',
  data(){
    return {
      show: 'all',
    };
  },
  computed:{
    objectives(){ return this.$store.getters.objectives.filter(objective => objective.date >= this.LocalDate.today()); },
  },
  methods: {
    checkStep(){
      /*var aObjectives = this.objectives.filter(objective => objective.priority === 'A');
      if(aObjectives == 0){
        this.showError('errors.plan.no_A_objective');
        return false;
      }*/
      return true;
    },
  },
  components: {
    ObjectivesList,
  },
}
</script>

<style lang="scss">
#objectives>li{
  display: flex;
  margin-bottom: 10px;
}

.objective-action{
  display: flex;
  align-items: center;
  margin-left: 20px;
}
</style>
