<template>
  <div class="profile has-text-left p-5">
    <h1 class="title is-2 is-size-4-mobile is-inline-block">{{ $t('account.profile') }}</h1>
    <router-link class="is-pulled-right has-text-secondary" to="/settings"><b-icon icon="cog"/></router-link>

    <b-tabs multiline>
      <b-tab-item :label="$t('plan.my_plan')">
        <MyPlan/>
      </b-tab-item>
      <b-tab-item :label="$t('objective.my_objectives')">
        <ObjectivesList/>
      </b-tab-item>

      <b-tab-item :label="$t('zone.my_zones')">
        <MyZones/>
      </b-tab-item>

      <b-tab-item :label="$t('measure.my_measures')">
        <MeasuresList/>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import MyPlan from './MyPlan.vue';
import MyZones from './MyZones.vue';
import ObjectivesList from '@/views/objective/ObjectivesList.vue';
import MeasuresList from '@/views/measure/MeasuresList.vue';

export default{
  name: 'profile',
  data(){
    return{

    };
  },
  components: {
    MyPlan,
    MyZones,
    ObjectivesList,
    MeasuresList,
  },
};
</script>

<style lang="scss" scoped>
</style>
