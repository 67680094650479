<template>
  <div id="workout" class="box container" v-if="workout">
    <WorkoutViewer v-bind:workout="workout" class="workout-viewer" showToolTip/>
    <section class="block">
      <h2 class="title is-4">{{ workout.name }}</h2>
      <p class="has-description pb-3"> {{ workout.description }} </p>
      <div class="columns is-mobile is-multiline mt-1" v-if="workout.date >= LocalDate.today() && !workout.bound_activity">
        <b-button :disabled="!$store.getters.hasPremiumAccess" v-if="targetUnit() !== 'power'" @click.native="changeWorkoutTargetUnit('power')">{{ $t('workout.change_to_power') }}</b-button>
        <b-button v-if="targetUnit() !== 'heart_rate'" @click.native="changeWorkoutTargetUnit('heart_rate')">{{ $t('workout.change_to_hr') }}</b-button>
        <b-button v-if="targetUnit() !== 'rpe'" @click.native="changeWorkoutTargetUnit('rpe')">{{ $t('workout.change_to_rpe') }}</b-button>
      </div>
      <div class="columns is-mobile is-multiline">
        <div class="column">
          <h5 class="title is-6">{{ $t('metrics.duration')}}</h5>
          <p>{{ utimeToString(workout.duration) }}</p>
        </div>
        <div class="column" v-if="workout.data.distance">
          <h5 class="title is-6">{{ $t('metrics.distance')}}</h5>
          <p>{{ showDistance(workout.data.distance) }}</p>
        </div>
        <div class="column" v-if="workout.data.load">
          <h5 class="title is-6">{{ $t('metrics.load')}}</h5>
          <p>{{ workout.data.load }}</p>
        </div>
        <div class="column" v-if="workout.data.intensity">
          <h5 class="title is-6">{{ $t('metrics.intensity')}}</h5>
          <p>{{ workout.data.intensity }}</p>
        </div>
        <div class="column" v-if="workout.data.np">
          <h5 class="title is-6">{{ $t('metrics.npower')}}</h5>
          <p>{{ workout.data.np }}</p>
        </div>
        <div class="column" v-if="workout.data.rpe">
          <h5 class="title is-6">{{ $t('metrics.rpe')}}</h5>
          <p>{{ workout.data.rpe }}</p>
        </div>
        <div class="column" v-if="workout.tag">
          <h5 class="title is-6">{{ $t('general.tag')}}</h5>
          <p>{{ $t('workout.'+workout.tag) }}</p>
        </div>
        <div class="column" v-if="workout.access">
          <h5 class="title is-6">{{ $t('general.access')}}</h5>
          <p>{{ $t('workout.'+workout.access) }}</p>
        </div>
        <div class="column">
          <h5 class="title is-6">{{ $t('zone.zones') }}</h5>
          <TimeInZoneDonut :zonesType="'power'" :timeInZones="timeInPowerZones()" :showTargets="workout.target_unit === 'power'"/>
        </div>
      </div>
    </section>
    <section class="block">
      <button class="button" @click="exportWorkout()">{{ $t('general.export') }}</button>
    </section>
    <section class="block">
      <div class="panel-heading" role="button">
        <h4 class="title is-4">{{ $t('workout.steps') }}</h4>
      </div>
      <StepsDisplay :stepsDescriptions="workout.steps_descriptions" :steps="workout.steps" :model="model" :targetUnit="workout.target_unit"/>
    </section>

    <section class="block">
      <button class="button" @click="exportWorkout()">{{ $t('general.export') }}</button>
    </section>


  </div>
</template>

<script>
import WorkoutViewer from './WorkoutViewer.vue';
import TimeInZoneDonut from '@/components/TimeInZoneDonut.vue';
import StepsDisplay from './StepsDisplay.vue';
const workoutUtil = require('@/../shared/workout/workoutUtil.js');


export default{
  name: 'workout',
  props: ['workoutToView'],
  data(){
    return {
      workout: null,
      model: null,
    };
  },
  computed: {
    steps(){ return workoutUtil.toSimpleSteps(this.workout.steps); },
  },
  methods: {
    //return target unit of workout
    targetUnit(){
      if(this.workout.target_unit) //if already defined return it
        return this.workout.target_unit;

      //If not, look if there is a common target unit for all steps
      var target_unit = '';
      for(let step of this.steps){
        let convertedStep = workoutUtil.convertStepWithModel(step, this.model);
        if(!target_unit)
          target_unit = convertedStep.target_unit;
        if(convertedStep.target_unit !== target_unit){ //if steps have differents target units
          if(target_unit === 'rpe' && convertedStep.target_unit === 'heart_rate')
            target_unit = 'heart_rate'; //if it's rpe and heart rate, it's allowed. Update target unit if needed
          if(target_unit === 'power' || convertedStep.target_unit === 'power') //If it's power and something else, return empty
            return '';
        }
      }

      return target_unit;
    },


    hasCadenceTarget(){
      for(let step of this.steps)
        if(step.cadence_target)
          return true;
      return false;
    },

    exportWorkout(){
      this.$eventBus.$emit('show-modal-workout-exporter', { workout: this.workout });
    },

    targetValue(step){
      var convertedStep = workoutUtil.convertStepWithModel(step, this.model, this.workout.target_unit);
      const units = { power: 'W', heart_rate: this.$t('metrics.bpm'), rpe: this.$t('metrics.rpe') };

      var unit =  units[convertedStep.target_unit];
      var value = convertedStep.value;
      return value + ' ' + unit;
    },

    timeInPowerZones(){
      return workoutUtil.timeInPowerZones(this.workout, this.model);
    },

    async changeWorkoutTargetUnit(unit){
      this.workout.target_unit = unit;
      try{
        await this.$store.dispatch('updateWorkout', this.workout);
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
        console.error(err);
       }
    },
  },
  mounted: function(){
    this.workout = this.workoutToView;
    if(!this.workout)
      this.workout = this.ugetWorkout(this.$route.query.id);

    this.model = this.ugetSetModel(this.workout.date || this.LocalDate.now());
  },
  components: {
    WorkoutViewer,
    TimeInZoneDonut,
    StepsDisplay,
  },
};
</script>

<style lang="scss" scoped>
.workout-viewer{
  width: 90%;
  height: 300px;
}
</style>
