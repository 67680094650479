<template>
  <div class="rendeem-code container box p-5 mt-10">
    <PromoCode/>
  </div>
</template>

<script>
import PromoCode from '@/components/PromoCode.vue';

export default{
  name: 'RendeemCode',
  components: {
    PromoCode
  },
}
</script>

<style land="scss" scoped>
</style>
