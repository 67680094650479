<template>
  <b-step-item :label="$t('infos.infos')" value="infos">
    <h2 class="title is-3">{{ $t('infos.infos')}}</h2>
    <section class="block columns">
      <b-field class="column">
        <template #label>
          {{ $t('plan.start_date') }}
          <Helper :label="$t('plan.help.start_date')"/>
        </template>
        <b-datepicker v-model="startDate" icon="calendar" editable trap-focus :first-day-of-week="1" :minDate="new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())"/>
      </b-field>

      <b-field class="column">
        <template #label>
          {{ $t('plan.practice_years') }}
          <Helper :label="$t('plan.help.practice_years')"/>
        </template>
        <b-input type="number" v-model.number="practiceYears"/>
      </b-field>

      <b-field :label="$t('plan.training_level')" class="column">
        <template #label>
          {{ $t('plan.training_level') }}
          <Helper position="is-left">
            <template v-slot:content>
              <p v-if="$store.getters.hasStandardAccess" v-html="$t('plan.help.training_level')"/>
              <p v-else v-html="$t('plan.help.training_level_base')"/>
            </template>
          </Helper>
        </template>
        <b-select v-model.number="trainingLevel">
          <option value="1">{{ $t('plan.levels[0]') }}</option>
          <option value="2">{{ $t('plan.levels[1]') }}</option>
          <option value="3" v-if="$store.getters.hasStandardAccess">{{ $t('plan.levels[2]') }}</option>
          <option value="4" v-if="$store.getters.hasStandardAccess">{{ $t('plan.levels[3]') }}</option>
          <option value="5" v-if="$store.getters.hasStandardAccess">{{ $t('plan.levels[4]') }}</option>
          <option value="6" v-if="$store.getters.hasStandardAccess">{{ $t('plan.levels[5]') }}</option>
        </b-select>
      </b-field>
    </section>

    <section class="block columns">
      <b-field class="column">
        <template #label>
          {{ $t('metrics.weight') }}
          <Helper :label="$t('metrics.help.weight')"/>
        </template>
        <UnitInput v-model.number="weight" unit="weight"/>
      </b-field>
      <b-field class="column">
        <template #label>
          {{ $t('metrics.resting_heart_rate') }}
          <Helper :label="$t('metrics.help.resting_heart_rate')"/>
        </template>
        <b-input type="number" v-model.number="restingHeartRate"/> <span class="pl-1 pt-2"><b>bpm</b></span>
      </b-field>
    </section>

    <section class="block">
      <b-field grouped>
        <template #label>
          {{ $t('equipment.equipment') }}
          <Helper :label="$t('equipment.help')"/>
        </template>
        <b-checkbox v-model="heartRateMonitor">{{$t('equipment.heart_rate_monitor')}}</b-checkbox>
        <b-checkbox v-model="powerMeter">{{$t('equipment.power_meter')}}</b-checkbox>
        <b-checkbox v-model="homeTrainerPower">{{$t('equipment.home_trainer_power')}}</b-checkbox>
      </b-field>
    </section>
  </b-step-item>
</template>

<script>
import Helper from '@/components/Helper.vue';
import UnitInput from '@/components/UnitInput.vue';

export default{
  name: 'infos-step',
  data(){
    return {
      weight: this.ugetLastMeasure(this.LocalDate.now()).weight,
      practiceYears: this.$store.getters.user.training_plan_data.practice_years,
      trainingLevel: (this.$store.getters.isBaseAccount ? 1 : this.$store.getters.user.training_plan_data.training_level),
      restingHeartRate: this.ugetLastMeasure(this.LocalDate.now()).resting_heart_rate,
      startDate: this.defaultStartDate(),
      heartRateMonitor: this.$store.getters.user.infos.equipment.heart_rate_monitor || false,
      powerMeter: this.$store.getters.user.infos.equipment.power_meter || false,
      homeTrainerPower: this.$store.getters.user.infos.equipment.home_trainer_power || false,
    };
  },
  methods: {
    defaultStartDate(){
      var startDate = new Date(this.$store.getters.user.training_plan_data.start_date);
      if(!isNaN(startDate) && this.LocalDate.dayFromDate(startDate) >= this.LocalDate.today()) //If start date is already defined and it's today or after
        return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()); //Return as the first default date
      const now = new Date(); //Else return today date
      return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    },

    checkStep(){
      if(!this.trainingLevel){
        this.showError('errors.plan.no_training_level');
        return false;
      }
      return true;
    },

    showError(err){
      this.$buefy.toast.open({
        duration: 3000,
        message: this.$t(err),
        position: 'is-bottom',
        type: 'is-danger'
      });
    },
  },
  mounted(){
  },
  components: {
    Helper,
    UnitInput,
  },
}
</script>

<style lang="scss">
</style>
