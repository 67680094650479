<template>
  <div class="next-workouts">
    <h2 class="title is-4 has-text-left">{{ $t('workout.next_workouts') }}</h2>

    <b-carousel v-if="$root.isMobile()"  :autoplay="false" :pause-info="false" :arrow-hover="false" :indicator="false" :repeat="false" icon-size="is-large">
      <b-carousel-item v-for="(n, i) in 5" :key="i">
        <div>
          <template v-for="(workout, j) in ugetWorkoutsForDay(LocalDate.today().addDay(i))">
            <WorkoutSummaryBig class="content" :key="i+','+j" :workout="workout" :showDate="true"/>
          </template>
          <div class="box content" v-if="ugetWorkoutsForDay(LocalDate.today().addDay(i)).length === 0">
            <p class="has-text-left">{{ LocalDate.today().addDay(i).toLocaleDateString() }}</p>
            <h2 class="title is-4"> {{ $t('workout.rest')}}</h2>
          </div>
        </div>
      </b-carousel-item>
    </b-carousel>

    <div v-if="$root.isTablet()">
      <template v-for="(workout, i) in nextWorkouts">
        <WorkoutSummaryBig :key="i" :workout="workout" :showDate="true"/>
      </template>
    </div>

  </div>
</template>

<script>
import WorkoutSummaryBig from '../workout/WorkoutSummaryBig.vue';

export default{
  name: 'next-workouts',
  computed: {
    nextWorkouts(){ return this.$store.getters.workouts.filter(workout => workout.date >= this.LocalDate.today()).slice(0, 3); },
  },
  data(){
    return {

    };
  },

  components:{
    WorkoutSummaryBig,
  },
}
</script>

<style lang="scss" scoped>
.content{
  min-height: 260px;
}
</style>
