<template>
  <b-step-item :label="$t('general.options')" value="options">
    <Notifications ref="notifications"/>
    <!--<InstallButton class="mt-5"/>-->
  </b-step-item>
</template>

<script>
import Helper from '@/components/Helper.vue';
import Notifications from '@/views/account/Notifications.vue';
import InstallButton from '@/components/InstallButton.vue';

export default{
  name: 'OptionsStep',
  methods: {
    checkStep(){
      return true;
    },

    getNotifications(){
      return this.$refs.notifications.getNotifications();
    },
  },
  components:{
    //InstallButton,
    Notifications,
  },
}
</script>

<style lang="scss" scoped>
</style>
