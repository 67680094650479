<template>
  <div class="activity-course">
    <ActivityMap v-if="activityData.position_lat" v-bind:positions_lat="activityData.position_lat" v-bind:positions_long="activityData.position_long"
      v-bind:marker_position="markerPosition"  v-bind:highlight="selectionDomain"/>

    <div id="charts-top">
      <div id="charts-menu">
        <div id="distance-time-buttons">
          <label><input class="button" type="radio" id="time" name="toggle" value="time" v-model="axisUnit" checked><span><b-icon icon="timer"/></span></label>
          <label><input class="button" type="radio" id="distance" value="distance" v-model="axisUnit" name="toggle"><span><b-icon icon="map-marker"/></span></label>
        </div>
        <div id="selection-data" v-show="selectionData.selection">
          <ul>
            <li><b>{{ $t('activity.selection') }}: </b></li>
            <li>{{ upreciseTimeToString(selectionData.time) }}</li>
            <li v-if="selectionData.distance">{{ showDistance(selectionData.distance) }}</li>
            <li v-if="selectionData.gradient">{{ selectionData.gradient.toFixed(1) }}%</li>
            <li v-if="selectionData.ascent">{{ showHeight(selectionData.ascent) }}</li>
          </ul>
        </div>
      </div>
    </div>


    <div id="charts-list">
      <div id="charts-tooltip-row" class="is-clipped">
        <div id="charts-tooltip">
          <table><tr>
            <td class="pr-2">{{ upreciseTimeToString(tooltipData.time) }}</td>
            <td class="pr-2" v-if="tooltipData.distance != null">{{ showDistance(tooltipData.distance) }}</td>
            <td class="pr-2" v-if="tooltipData.gradient != null">{{ tooltipData.gradient.toFixed(1) }}%</td>
            <td class="pr-2" v-if="tooltipData.calories != null">{{ Math.round(tooltipData.calories/1000) }}Kj</td>
          </tr></table>
        </div>
      </div>

      <ActivityChart v-if="activityData && activityData.speed" ref="speedChart" id="speed-chart"
        :entries="activityData.speed" :distance="activityData.distance" :axisUnit="axisUnit"
        :name="this.$t('metrics.speed')" elementListener="#charts-list" :xAxisOnBottom="false" :domainStartAt="0"
        :convertFunction="val => parseFloat(this.showSpeed(val, 1, false))"
        :zoomListener="() => { this.onChartZoom(); }" :mouseMoveListener="event => { this.mouseMove(event); }"/>

      <ActivityChart v-if="activityData && arrayNonZero(activityData.altitude)" ref="altitudeChart" id="altitude-chart"
        :entries="activityData.altitude" :distance="activityData.distance" :axisUnit="axisUnit"
        :name="$t('metrics.altitude')" elementListener="#charts-list" fillArea :xAxis="false"/>

      <ActivityChart v-if="activityData && arrayNonZero(activityData.power)" ref="powerChart" id="power-chart"
        :entries="activityData.power" :distance="activityData.distance" :axisUnit="axisUnit" isPowerChart
        :name="$t('metrics.power')" elementListener="#charts-list" :xAxis="false" :domainStartAt="0"
        :zoomListener="() => { this.powerSelection = this.$refs.powerChart.getArrayFromDomain(); }"/>

      <ActivityChart v-if="$store.getters.hasPremiumAccess && activityData && arrayNonZero(activityData.wbal)" ref="wbalChart" id="wbal-chart"
        :entries="activityData.wbal" :distance="activityData.distance" :axisUnit="axisUnit"
        :name="$t('metrics.wbal')" elementListener="#charts-list" :xAxis="false" :domainStartAt="0"/>

      <ActivityChart v-if="activityData && arrayNonZero(activityData.heart_rate)" ref="hrChart" id="hr-chart"
        :entries="activityData.heart_rate" :distance="activityData.distance" :axisUnit="axisUnit"
        :name="$t('metrics.heart_rate')" elementListener="#charts-list" :xAxis="false" :domainStartAt="0"
        :zoomListener="() => { this.hrSelection = this.$refs.hrChart.getArrayFromDomain(); }"/>

      <ActivityChart v-if="activityData && arrayNonZero(activityData.cadence)" ref="cadenceChart" id="cadence-chart"
        :entries="activityData.cadence" :distance="activityData.distance" :axisUnit="axisUnit" :avgCountZeroes="false"
        :name="$t('metrics.cadence')" elementListener="#charts-list" :xAxis="false" :domainStartAt="0"/>

      <ActivityChart v-if="$store.getters.hasPremiumAccess && activityData && arrayNonZero(activityData.hrv)" ref="hrvChart" id="hrv-chart"
        :entries="activityData.hrv" :distance="activityData.distance" :axisUnit="axisUnit"
        :name="$t('metrics.hrv')" elementListener="#charts-list" :xAxis="false" :domainStartAt="0"/>

      <ActivityChart v-if="activityData && arrayNonZero(activityData.temperature)" ref="temperatureChart" id="temperature-chart"
        :entries="activityData.temperature" :distance="activityData.distance" :axisUnit="axisUnit"
        :name="$t('metrics.temperature')" elementListener="#charts-list" :xAxis="false" :domainStartAt="0"
        :convertFunction="val => parseFloat(this.showTemperature(val, 0, false))"/>

      <ActivityChart v-if="$store.getters.hasPremiumAccess && activityData && arrayNonZero(activityData.vo2)" ref="vo2Chart" id="vo2-chart"
        :entries="activityData.vo2" :distance="activityData.distance" :axisUnit="axisUnit"
        :name="$t('metrics.vo2_est')" elementListener="#charts-list" :xAxis="false" :domainStartAt="0"/>

    </div>
  </div>
</template>

<script>
import ActivityChart from './ActivityChart.vue';
import ActivityMap from './ActivityMap.vue';


export default{
  name: 'activity-course',
  props: ['activity', 'activityData'],
  data(){
    return {
      powerSelection: [],
      hrSelection: [],
      markerPosition: 0,
      selectionDomain: [],
      axisUnit: 'time',
      tooltipData: { time: 0, distance: null, gradient: null, calories: null },
      selectionData: { selection: false, time: 0, distance: null, gradient: null, ascent: null }
    };
  },
  methods: {
    chartWidth(){ return window.innerWidth*95/100 - 100; },

    arrayNonZero(array){
      if(!array)
        return false;
      for(var i=0; i<array.length; i++)
        if(array[i] != 0)
          return true;
      return false;
    },

    updateTooltip(mouse_x){
      var index = this.$refs.speedChart.indexOfPosition(mouse_x);
      this.tooltipData = { time: index };

      if(this.activityData.distance.length > index)
        this.tooltipData.distance = this.activityData.distance[index];

      if(this.activityData.altitude.length > index+20 && this.activityData.distance.length > index+20)
        this.tooltipData.gradient = (this.activityData.altitude[index+20] - this.activityData.altitude[index])/((this.activityData.distance[index+20] - this.activityData.distance[index])*10);

      if(this.activityData.accumulated_power.length > index)
        this.tooltipData.calories = this.activityData.accumulated_power[index];

      var translateValue = Math.max(Math.min(mouse_x, this.chartWidth()-100), 100);
      document.getElementById("charts-tooltip").style.transform = "translateX("+translateValue+"px)";
    },


    mouseMove(event){
      this.markerPosition = this.$refs.speedChart.indexOfPosition(event.mouse_x);
      this.updateTooltip(event.mouse_x);
    },


    onChartZoom(){
      if(this.$refs.speedChart.isZoomed()){
        this.selectionDomain = this.$refs.speedChart.getCurrentDomainIndexes();
        var i0 = this.selectionDomain[0];
        var i1 = this.selectionDomain[1];

        this.selectionData = { selection: true, time : i1-i0 };

        if(this.activityData.distance.length > i1)
          this.selectionData.distance = this.activityData.distance[i1] - this.activityData.distance[i0];

        if(this.activityData.altitude.length > i1){
          this.selectionData.ascent = this.activityData.altitude[i1] - this.activityData.altitude[i0];
          if(this.tooltipData.distance)
            this.selectionData.gradient = this.selectionData.ascent / (this.selectionData.distance*10);
        }


      }else{
        this.selectionDomain = [];
        this.selectionData.selection = false;
      }
    },
  },
  components: {
    ActivityChart,
    ActivityMap,
  },
};

</script>

<style lang="scss">


#charts-list{
  padding:0;
  margin:auto;
  display: inline-block;
  border-color:black;
  border-style:solid;
  border-width:1px;
  width: 100%;
}

#charts-menu::after {
   content: " ";
   display: block;
   height: 0;
   clear: both;
}

#charts-tooltip-row{
}
#charts-tooltip{
  display: inline-block;
  float: left;
}

#selection-data{
  margin-left: -30%;
}
#selection-data li{
  list-style: none;
  display: inline-block;
  padding-right: 10px;
}

#distance-time-buttons{
  float: left;
}
#distance-time-buttons label{
  float: left;
  width: 28px;
  margin: 4px;
  background-color: #EFEFEF;
  border-radius: 4px;
  border: 1px solid #D0D0D0;
  overflow:auto;
}
#distance-time-buttons span{
  text-align:center;
  padding: 2px;
  display:block;
}
#distance-time-buttons input{
  display: none;
}
#distance-time-buttons input:checked + span{
  background-color:#404040;
  color:#F7F7F7;
}
#distance-time-buttons .icon{
  width: 24px;
}
#distance-time-buttons input:not(:checked) + span{
  cursor: pointer;
}


.verticalLine{
  stroke: grey;
  stroke-width: 1px;
}
.selectionRect{
  fill: grey;
  stroke: black;
  stroke-width: 1px;
}

.line {
  fill: none;
  stroke-width: 1px;
  stroke: black;
}


#speed-chart .line{
  stroke: $chart-blue-dark;
}
#speed-chart .text-value{
  fill: $chart-blue-dark;
}
#altitude-chart .line{
  fill: $accent;
}
#power-chart .line {
  stroke: $chart-purple;
}
#power-chart .text-value{
  fill: $chart-purple;
}
#wbal-chart .line{
  stroke: $chart-red-darker;
}
#wbal-chart .text-value{
  fill: $chart-red-darker;
}
#hr-chart .line {
  stroke: $chart-red;
}
#hr-chart .text-value{
  fill: $chart-red;
}
#cadence-chart .line {
  stroke: $chart-green;
}
#cadence-chart .text-value{
  fill: $chart-green;
}
#temperature-chart .line {
  stroke: $chart-orange;
  stroke-width: 2px;
}
#temperature-chart .text-value{
  fill: $chart-orange;
}
</style>
