<template>
  <div class="day-details is-flex-direction-column">
    <div class="is-flex-direction-column">
      <template v-for="(activity, i) in ugetActivitiesForDay(date)">
        <ActivityBox :key="'a'+i" :activity="activity"/>
      </template>
      <template v-for="(workout, i) in ugetWorkoutsForDay(date)">
        <WorkoutBox :key="'w'+i" :workout="workout"/>
      </template>
      <MeasureBox v-if="ugetMeasure(date)" :measureData="ugetMeasure(date)"/>
      <ModelBox v-if="ugetModel(date)" :model="ugetModel(date)"/>
      <template v-for="(objective, i) in ugetObjectivesForDay(date)">
        <ObjectiveBox :key="'o'+i" :objective="objective"/>
      </template>
      <template v-for="(trainingRide, i) in ugetTrainingRidesForDay(date)">
        <TrainingRideBox :key="'t'+i" :trainingRide="trainingRide"/>
      </template>
    </div>
  </div>
</template>

<script>
import ActivityBox from '../ActivityBox.vue';
import WorkoutBox from '../WorkoutBox.vue';
import MeasureBox from '../MeasureBox.vue';
import ModelBox from '../ModelBox.vue';
import ObjectiveBox from '../ObjectiveBox.vue';
import TrainingRideBox from '../TrainingRideBox.vue';

export default {
  name: 'day-details',
  props: ['date'],
  methods: {

  },
  components: {
    ActivityBox,
    WorkoutBox,
    MeasureBox,
    ModelBox,
    ObjectiveBox,
    TrainingRideBox,
  }
}
</script>

<style lang="scss" scoped>
.day{
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
