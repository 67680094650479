<template>
  <b-step-item :label="$t('general.validation')" value="validation">
    <h2 class="title is-4">{{ $t('general.options') }}</h2>
    <section class="block" >
      <b-field>
        <b-checkbox v-model="preparationWeek">
          {{ $t('plan.include_preparation_week') }}
        </b-checkbox>
        <p class="control">
          <Helper :label="$t('plan.help.include_preparation_week')" position="is-left"/>
        </p>
      </b-field>
      <b-field>
        <b-checkbox v-model="testingWeek">
          {{ $t('plan.include_testing_week') }}
        </b-checkbox>
        <p class="control">
          <Helper :label="$t('plan.help.include_testing_week')" position="is-left"/>
        </p>
      </b-field>
    </section>
  </b-step-item>
</template>

<script>
import Helper from '@/components/Helper.vue';

function defaultValue(val){ return val == null ?  true : val; } //If null or undefined, default value is true

export default{
  name: 'options-step',
  data(){
    return {
      preparationWeek: defaultValue(this.$store.getters.user.training_plan_data.preparation_week),
      testingWeek: defaultValue(this.$store.getters.user.training_plan_data.testing_week),
    };
  },
  components: {
    Helper,
  },
};
</script>

<style lang="scss">
</style>
