<template>
  <div class="calendar has-text-centered p-6">
    <div class="has-text-left">
      <div>
        <h1 class="title is-2 is-inline-block calendar-tour-0"> {{ $t('nav.calendar') }}</h1>
        <div class="is-pulled-right">
          <b-button @click="addToCalendar" type="is-primary" icon-left="plus" class="mr-2 calendar-tour-1">{{ $t('general.add_item') }}</b-button>
          <b-button :disabled="!$planExist()" @click="showTrainingTimeModal" icon-left="calendar">{{ $t('general.modify') }}</b-button>
        </div>
      </div>
      <div>
        <h3 class="title is-4 is-inline-block w-11"><span class="is-clickable" @click="today()">{{ $t('dates.months.'+currentMonth) }} {{ currentYear }}</span></h3>
        <a @click="previousMonth()"><b-icon icon="chevron-left"/></a>
        <a @click="nextMonth()"><b-icon icon="chevron-right"/></a>
      </div>
    </div>

    <div>
      <table class="calendar-table table is-bordered is-fullwidth">
        <thead>
          <tr>
            <th>{{ $t('dates.monday') }}</th>
            <th>{{ $t('dates.tuesday') }}</th>
            <th>{{ $t('dates.wednesday') }}</th>
            <th>{{ $t('dates.thursday') }}</th>
            <th>{{ $t('dates.friday') }}</th>
            <th>{{ $t('dates.saturday') }}</th>
            <th>{{ $t('dates.sunday') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="week in weekCount()">
            <tr :key="week">
              <template v-for="day in 7">
                <td class="" :key="day">
                  <Day :date="dateFromWeekDay(week, day)" :currentMonth="currentMonth"/>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Day from './Day.vue';

export default {
  name: 'calendar-desktop',
  data() {
    return {
      currentMonth: (new Date()).getMonth(),
      currentYear: (new Date()).getFullYear(),
      firstDayOfMonth: this.LocalDate.new(),
      lastDayOfMonth: this.LocalDate.new(),
    };
  },
  methods: {
    dateFromWeekDay(week, day){
      var result = this.LocalDate.new(this.firstDayOfMonth);
      result.setDate(result.getDate() + week*7-7+day-1);
      return result;
    },
    updateFirstDayOfMonth(){
      this.firstDayOfMonth = this.LocalDate.date(this.currentYear, this.currentMonth, 1).firstDayOfWeek();
    },
    updateLastDayOfMonth(){
      this.lastDayOfMonth = this.LocalDate.date(this.currentYear, this.currentMonth+1, 0).lastDayOfWeek();
    },
    updateDays(){
      this.updateFirstDayOfMonth();
      this.updateLastDayOfMonth();
    },
    weekCount(){
      return Math.ceil((Math.round((this.lastDayOfMonth - this.firstDayOfMonth)/(1000*60*60*24))) / 7);
    },

    previousMonth(){
      if(--this.currentMonth < 0){
        this.currentMonth = 11;
        this.currentYear--;
      }
      this.updateDays();
    },
    nextMonth(){
      if(++this.currentMonth >= 12){
        this.currentMonth = 0;
        this.currentYear++;
      }
      this.updateDays();
    },
    today(){
      this.currentMonth = (new Date()).getMonth();
      this.currentYear = (new Date()).getFullYear();
      this.updateDays();
    },


    showTrainingTimeModal(){
      this.$eventBus.$emit('show-modal-training-time', this.LocalDate.thisWeek());
    },

    addToCalendar(){
      this.$eventBus.$emit('show-modal-add-to-calendar');
    },

  },
  mounted(){
    if(new Date().getTime() - new Date(localStorage.calendarLastMonthChange).getTime() < 1000000){
      this.currentMonth = localStorage.calendarCurrentMonth ? parseInt(localStorage.calendarCurrentMonth) : this.currentMonth;
      this.currentYear = localStorage.calendarCurrentYear ? parseInt(localStorage.calendarCurrentYear) : this.currentYear;
    }
    this.updateDays();
  },
  watch: {
    currentMonth(){
      localStorage.calendarCurrentMonth = this.currentMonth;
      localStorage.calendarLastMonthChange = new Date();
    },
    currentYear(){
      localStorage.calendarCurrentYear = this.currentYear;
      localStorage.calendarLastMonthChange = new Date();
    }
  },
  components: {
    Day,
  }
}
</script>

<style lang="scss" scoped>
table, td, th{
  width: 14.2857%;
  height: 100%;
  background-color: rgba(0,0,0,0);
}
</style>
