<template>
  <div class="measure-list">
    <div class="is-flex is-justify-content-space-between">
      <h2 class="title is-3 is-inline-block">{{ $t('measure.my_measures') }}</h2>
      <b-button size="is-medium" @click="addMeasure">+ {{ $t('measure.measure') }}</b-button>
    </div>
    <b-table :data="Object.values($store.getters.measures)" :mobile-cards="false">
      <b-table-column field="date" :label="$t('dates.date')" v-slot="props">
        {{ props.row.date.toLocaleDateString() }}
      </b-table-column>

      <b-table-column field="weight" :label="$t('metrics.weight')" v-slot="props">
        <span v-if="props.row.weight">{{ showWeight(props.row.weight) }}</span>
      </b-table-column>

      <b-table-column field="resting_heart_rate" :label="$t('metrics.resting_heart_rate')" v-slot="props">
        <span v-if="props.row.resting_heart_rate">{{ props.row.resting_heart_rate }} bpm</span>
      </b-table-column>

      <b-table-column width="100" v-slot="props">
        <b-icon @click.native="editMeasure(props.row)" icon="pencil" class="mr-2 is-clickable"/>
        <b-icon @click.native="deleteMeasure(props.row)" icon="delete" class="is-clickable"/>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>

export default{
  name: 'measures-list',
  data(){
    return {
    };
  },
  methods:{
    addMeasure(){
      this.$eventBus.$emit('show-modal-new-measure', { date: this.LocalDate.today() });
    },

    editMeasure(measure){
      this.$eventBus.$emit('show-modal-new-measure', { date: measure.date });
    },
    deleteMeasure(measure){
      this.$store.dispatch('removeMeasure', measure).then(() =>{
      }).catch(err => {
         console.error(err);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
