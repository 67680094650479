<script>
import * as d3 from 'd3';
import LineChart from '@/helpers/LineChart.vue';

//Index in power curve to equivalent time
function getTimeForIndex(index){
  index++;
  if(index < 60)
    return index;
  index-=60;
  if(index < 120)
    return 60+index*2;
  index-=120;
  if(index < 60)
    return 300+index*5;
  index -= 60;
  if(index < 120)
    return 600+index*10;
  index-=120;
  if(index < 60)
    return 1800+index*30;
  index-=60;
  return 3600+index*60;
}


export default{
  name: 'CurveChart',
  extends: LineChart,
  props:{
    globalCurve: {
      type: Object,
      default(){ return null; },
    },
    powerModelCurve: {
      type: Array,
      default: null,
    },
    curveType: {
      type: String,
      default: 'power',
    },
    dates: {
      type: Array,
      default: null,
    },
  },
  computed: {
    haveDates(){ return this.dates && this.dates.length > 0; },
    haveGlobalCurve(){ return !!this.globalCurve; },
    havePowerModelCurve(){ return this.powerModelCurve && this.powerModelCurve.length > 0; },
  },
  data(){
    return {
    };
  },
  methods:{
    initialize(){
      LineChart.methods.initialize.call(this); //call super initialize
      this.data = [new Array(this.entries.length)];

      this.entries.forEach((value, i) => {
        this.data[0][i] = { data: this.entries[i], date: getTimeForIndex(i) };
      });

      //If dates data for each points, store it inside data
      if(this.haveDates){
        this.data[0].forEach((d, i) => {
          d.fromDay = new Date(this.dates[i]);
        });
      }

      //If global curve (red curve), add it
      if(this.haveGlobalCurve){
        this.data.push(new Array(this.entries.length));
        this.entries.forEach((value, i) => {
          this.data[1][i] = { data: this.globalCurve.curve_data[i], date: this.data[0][i].date, fromDay: new Date(this.globalCurve.dates[i]) };
        });
      }

      if(this.havePowerModelCurve){
        this.data.push(new Array(this.entries.length));
        this.entries.forEach((power, i) => {
          this.data[this.data.length-1][i] = { data: this.powerModelCurve[i], date: getTimeForIndex(i) };
        });
      }
    },

    createChart(){
      LineChart.methods.createChart.call(this);
      this.createToolTip();
    },

    rebuild(){
      LineChart.methods.rebuild.call(this);
    },

    createToolTip(){
      this.tooltip = this.svg.append("g")
        .attr("id", "tooltip");
        //.style("display", "none");

        //circle
      this.tooltip.append("circle")
        .attr("fill", "#CCE5F6")
        .attr("r", 10);

      this.tooltip.append("circle")
        .attr("fill", "#3498db")
        .attr("stroke", "#fff")
        .attr("stroke-width", "1.5px")
        .attr("r", 4);

      var height = 40;
      if(this.haveDates)
        height += 20; //More space to display date
      if(this.haveGlobalCurve)
        height += 20; //More space to display % of best

      //tooltip
      this.tooltip.append("polyline")
        .attr("points","0,0 0,"+height+" 70,"+height+" 75,"+(height+5)+" 80,"+height+" 150,"+height+" 150,0 0,0")
        .style("fill", "#fafafa")
        .style("stroke","#3498db")
        .style("opacity","0.9")
        .style("stroke-width","1")
        .attr("transform", "translate(-75, -"+(height+5)+")");

      // Parent text element
      this.valueText = this.tooltip.append("text")
        .style("font-size", "13px")
        .style("font-family", "Segoe UI")
        .style("color", "#333333")
        .style("fill", "#333333")
        .attr("transform", "translate(-50, -"+(height-10)+")");

      //Text content
      this.valueText.append("tspan")
        .text(this.$t('metrics.duration')+": ");

      this.valueText.append("tspan")
        .attr("id", "tooltip-duration")
        .style("font-weight", "bold");

      var metricText = this.curveType === 'power' ? this.$t('metrics.power') : this.$t('metrics.hr');

      this.valueText.append("tspan")
        .attr("x", "0")
        .attr("dy", "1.2em")
        .text(metricText+": ");

      this.valueText.append("tspan")
        .attr("id", "tooltip-value")
        .style("font-weight", "bold");

      //Display date
      if(this.haveDates){
        this.valueText.append("tspan")
          .attr("x", "0")
          .attr("dy", "1.2em")
          .text(this.$t('dates.date')+": ");

        this.valueText.append("tspan")
          .attr("id", "tooltip-day")
          .style("font-weight", "bold");
      }

      //Display % of best
      if(this.haveGlobalCurve){
        this.valueText.append("tspan")
          .attr("x", "0")
          .attr("dy", "1.2em")
          .text(this.$t('curves.percent_best')+": ");

        this.valueText.append("tspan")
          .attr("id", "tooltip-percent")
          .style("font-weight", "bold");
      }
    },

    setAxisRange(){
      this.axisX.range([0, this.domainWidth]).exponent(0.1);
      this.axisY.range([this.domainHeight, 0]);
    },

    setAxis(){
      this.axisX = d3.scalePow();  //Power curve have better display with non linear scale
      this.axisY = d3.scaleLinear();
      this.setAxisRange();
      this.axisX.domain([this.minTime, this.maxTime]);
      this.axisY.domain([this.minDomain, this.maxDomain]);
      this.lineFunction = d3.line().x((d) => { return this.axisX(d.date); }).y((d) => { return this.axisY(d.data); });
    },

    axisXCall(){
      return LineChart.methods.axisXCall.call(this).tickValues([1,5,15,30,60,120,300,600,1200,1800,3600,7200,18000,36000,86400]);
    },

    mouseMoveCall(event, mouse_x, mouse_y){
      var selectedTime = this.axisX.invert(mouse_x);
      var i = d3.bisector(d => d.date).left(this.data[0], selectedTime);
      var d = this.data[0][i];
      if(!d)
        return;

      //On mouse move update tooltip

      this.tooltip.attr("transform", "translate(" + this.axisX(d.date) + "," + this.axisY(d.data) + ")");

      var unit = this.curveType === 'power' ? 'W' : 'bpm';

      d3.select(this.$refs.root).select('#tooltip-duration')
          .text(this.axisXFormat(d.date));
      d3.select(this.$refs.root).select('#tooltip-value')
          .text(d.data + unit);

      if(this.haveDates){
        var day = this.data[0][i].fromDay.toLocaleDateString();
        d3.select(this.$refs.root).select('#tooltip-day').text(day);
      }
      if(this.haveGlobalCurve){
        var gd = this.data[1][i];
        d3.select(this.$refs.root).select('#tooltip-percent').text(Math.round((d.data/gd.data)*100) + '%');
      }
    },

    //Time format to display for a duration
    axisXFormat(date){
      var hr = Math.floor(date/3600);
      var min = Math.floor(date/60)%60;
      var s = date%60;
      var str = "";
      if(hr > 0)
        str += hr.toString() + "h";
      if(min > 0)
        str += min.toString() + "m";
      if(s > 0)
        str += s.toString() + "s";
      return str;
    },
  },
}
</script>
<style lang="scss" scoped>
.linechart{
  height: 800px;
}
</style>
