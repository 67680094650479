<template>
  <section class="section has-background-secondary FeaturesList">
    <div class="container pt-5 pb-3">
      <h1 class="title is-2 has-text-white" :class="(showFree && showStandard && showPremium) ? 'move-title' : ''" v-html="$t(title)"/>


      <div v-if="!$root.isMobile()" class="mt-6 pb-5">
        <div class="columns">
          <div class="column has-text-white has-text-left">
            <div class="features-title"/>

            <template v-for="(texts, i) in $t('landing.features_list')">
              <div class="ftext" :key="i">
                <p>{{ texts[0] }}</p>
              </div>
            </template>
          </div>

          <div class="column" v-if="showFree">
            <div class="has-text-white rounded white-border">
              <div class="features-title pt-5">
                <h2 class="title is-2 has-text-white">{{ $t('landing.free') }}</h2>
              </div>

              <template v-for="(texts, i) in $t('landing.features_list')">
                <div class="ftext" :key="i">
                  <p>
                    {{ texts[1] !== 'check' && texts[1] !== 'cross' ? texts[1] : '' }}
                    <img v-if="texts[1] === 'check'" src="~@/assets/landing/check.png" width="24" height="24"/>
                    <img v-if="texts[1] === 'cross'" src="~@/assets/landing/cross.png" width="24" height="24"/>
                  </p>
                </div>
              </template>
            </div>

            <b-button v-if="showSignups" tag="router-link" to="/signup?plan=free"
              class="mt-5 mb-5 w-100p" type="is-primary" size="is-large" :label="$t('account.signup')" outlined/>

            <b-button v-if="showSelect" @click.native="selectPlan('free')"
              class="mt-5 mb-5 w-100p" type="is-primary" size="is-large" :label="$t('general.select')" outlined/>
          </div>

          <div class="column" v-if="showStandard">
            <div class="has-text-secondary rounded has-background-white">
              <div class="features-title pt-5">
                <h2 class="title is-2 has-text-secondary mb-0">{{ $t('landing.standard') }}</h2>
                <p class="is-size-5"><span class="is-size-2 has-text-primary has-text-weight-bold">4.99€</span>/{{ $t('dates.month').toLowerCase() }}</p>
              </div>

              <template v-for="(texts, i) in $t('landing.features_list')">
                <div class="ftext" :key="i">
                  <p>
                    {{ texts[2] !== 'check' && texts[2] !== 'cross' ? texts[2] : '' }}
                    <img v-if="texts[2] === 'check'" src="~@/assets/landing/check.png" width="24" height="24"/>
                    <img v-if="texts[2] === 'cross'" src="~@/assets/landing/cross.png" width="24" height="24"/>
                  </p>
                </div>
              </template>
            </div>

            <b-button v-if="showSignups" tag="router-link" to="/signup?plan=standard"
              class="mt-5 mb-5 w-100p" type="is-primary" size="is-large" :label="$t('account.signup')"/>

            <b-button v-if="showSelect" @click.native="selectPlan('standard')"
              class="mt-5 mb-5 w-100p" type="is-primary" size="is-large" :label="$t('general.select')"/>
          </div>

          <div class="column" v-if="showPremium">
            <div class="has-text-white rounded white-border">
              <div class="features-title pt-5">
                <h2 class="title is-2 has-text-white mb-0">{{ $t('landing.premium') }}</h2>
                <p class="is-size-5"><span class="is-size-2 has-text-primary has-text-weight-bold">12.99€</span>/{{ $t('dates.month').toLowerCase() }}</p>
              </div>

              <template v-for="(texts, i) in $t('landing.features_list')">
                <div class="ftext" :key="i">
                  <p>
                    {{ texts[3] !== 'check' && texts[3] !== 'cross' ? texts[3] : '' }}
                    <img v-if="texts[3] === 'check'" src="~@/assets/landing/check.png" width="24" height="24"/>
                    <img v-if="texts[3] === 'cross'" src="~@/assets/landing/cross.png" width="24" height="24"/>
                  </p>
                </div>
              </template>
            </div>
            <b-button v-if="showSignups" tag="router-link" to="/signup?plan=premium"
              class="mt-5 mb-5 w-100p" type="is-primary" size="is-large" :label="$t('account.signup')" outlined/>

            <b-button v-if="showSelect" @click.native="selectPlan('premium')"
              class="mt-5 mb-5 w-100p" type="is-primary" size="is-large" :label="$t('general.select')" outlined/>
          </div>
        </div>
      </div>


      <!-- different design on mobile. -->
      <div v-if="$root.isMobile()">
        <div v-if="showFree" class="mb-5">
          <div class="features-title mb-5">
            <h2 class="title is-2 has-text-white">{{ $t('landing.free') }}</h2>
          </div>

          <div class="columns is-mobile">
            <div class="column has-text-white has-text-left">
              <template v-for="(texts, i) in $t('landing.features_list')">
                <div class="ftext" :key="i">
                  <p>{{ texts[0] }}</p>
                </div>
              </template>
            </div>

            <div class="column">
              <div class="has-text-secondary rounded has-background-white">
                <template v-for="(texts, i) in $t('landing.features_list')">
                  <div class="ftext" :key="i">
                    <p>
                      {{ texts[1] !== 'check' && texts[1] !== 'cross' ? texts[1] : '' }}
                      <img v-if="texts[1] === 'check'" src="~@/assets/landing/check.png" width="24" height="24"/>
                      <img v-if="texts[1] === 'cross'" src="~@/assets/landing/cross.png" width="24" height="24"/>
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <b-button v-if="showSignups" tag="router-link" to="/signup?plan=free"
            class="mt-5 mb-5 w-100p" type="is-primary" size="is-large" :label="$t('account.signup')"/>

          <b-button v-if="showSelect" @click.native="selectPlan('free')"
            class="mt-5 mb-5 w-100p" type="is-primary" size="is-large" :label="$t('general.select')"/>
        </div>

        <div v-if="showStandard" class="mb-5">
          <div class="features-title">
            <h2 class="title is-2 has-text-white mb-0">{{ $t('landing.standard') }}</h2>
            <p class="is-size-5 has-text-white"><span class="is-size-2 has-text-primary has-text-weight-bold">4.99€</span>/{{ $t('dates.month').toLowerCase() }}</p>
          </div>

          <div class="columns is-mobile">
            <div class="column has-text-white has-text-left">
              <template v-for="(texts, i) in $t('landing.features_list')">
                <div class="ftext" :key="i">
                  <p>{{ texts[0] }}</p>
                </div>
              </template>
            </div>

            <div class="column">
              <div class="has-text-secondary rounded has-background-white">
                <template v-for="(texts, i) in $t('landing.features_list')">
                  <div class="ftext" :key="i">
                    <p>
                      {{ texts[2] !== 'check' && texts[2] !== 'cross' ? texts[2] : '' }}
                      <img v-if="texts[2] === 'check'" src="~@/assets/landing/check.png" width="24" height="24"/>
                      <img v-if="texts[2] === 'cross'" src="~@/assets/landing/cross.png" width="24" height="24"/>
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <b-button v-if="showSignups" tag="router-link" to="/signup?plan=standard"
            class="mt-5 mb-5 w-100p" type="is-primary" size="is-large" :label="$t('account.signup')"/>

          <b-button v-if="showSelect" @click.native="selectPlan('standard')"
            class="mt-5 mb-5 w-100p" type="is-primary" size="is-large" :label="$t('general.select')"/>
        </div>

        <div v-if="showPremium" class="mb-5">
          <div class="features-title">
            <h2 class="title is-2 has-text-white mb-0">{{ $t('landing.premium') }}</h2>
            <p class="is-size-5 has-text-white"><span class="is-size-2 has-text-primary has-text-weight-bold">12.99€</span>/{{ $t('dates.month').toLowerCase() }}</p>
          </div>
          <div class="columns is-mobile">
            <div class="column has-text-white has-text-left">
              <template v-for="(texts, i) in $t('landing.features_list')">
                <div class="ftext" :key="i">
                  <p>{{ texts[0] }}</p>
                </div>
              </template>
            </div>

            <div class="column">
              <div class="has-text-secondary rounded has-background-white">
                <template v-for="(texts, i) in $t('landing.features_list')">
                  <div class="ftext" :key="i">
                    <p>
                      {{ texts[3] !== 'check' && texts[3] !== 'cross' ? texts[3] : '' }}
                      <img v-if="texts[3] === 'check'" src="~@/assets/landing/check.png" width="24" height="24"/>
                      <img v-if="texts[3] === 'cross'" src="~@/assets/landing/cross.png" width="24" height="24"/>
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <b-button v-if="showSignups" tag="router-link" to="/signup?plan=premium"
            class="mt-5 mb-5 w-100p" type="is-primary" size="is-large" :label="$t('account.signup')"/>

          <b-button v-if="showSelect" @click.native="selectPlan('premium')"
            class="mt-5 mb-5 w-100p" type="is-primary" size="is-large" :label="$t('general.select')"/>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

export default{
  name: 'FeaturesList',
  props: {
    title: { type: String },
    showFree: { type: Boolean, default: true },
    showStandard: { type: Boolean, default: true },
    showPremium: { type: Boolean, default: true },
    showSignups: { type: Boolean, default: false },
    showSelect: { type: Boolean, default: false },
  },
  methods: {
    selectPlan(plan){
      this.$eventBus.$emit('features-selected-plan', plan);
    },
  },
}
</script>

<style lang="scss" scoped>
.white-border{
  border: solid white 1px;
}
.features-title{
  height: 120px;
}
.ftext{
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: $tablet){ height: 70px;}
}
.move-title{
  @media only screen and (min-width: $tablet){
    transform: translate(14%,0);
  }
}
</style>
