<template>
  <div class="PromoCode">
    <div v-if="canUseCode()">
      <b-field :label="$t('account.have_a_code')" position="is-centered">
        <b-input v-model="code" :placeholder="$t('account.code')"/>
        <p class="control">
          <b-button class="button is-primary" @click="sendCode">{{ $t('general.validate') }}</b-button>
        </p>
      </b-field>
      <p v-if="success" class="has-text-success">{{ $t('account.code_validated') }}</p>
      <p v-if="error" class="has-text-danger">{{ $t(errormsg) }}</p>
    </div>
  </div>
</template>

<script>

export default{
  name: 'PromoCode',
  data(){
    return {
      code: '',
      success: false,
      error: false,
      errormsg: '',
    };
  },
  methods: {
    canUseCode(){
      return !this.$store.getters.hasTesterAccess && !this.$store.getters.hasCoachAccess;/* &&
        (this.$store.getters.isBaseAccount || this.$store.getters.hasTrial) && !this.$store.getters.user.used_code;*/
    },
    async sendCode(){
      try{
        this.error = false;
        this.errormsg = '';
        this.success = false;
        await this.post('/set_code', { code: this.code.trim() }); //trim promo code to remove unwanted white space from both end
        this.success = true;
        setTimeout(() => { window.location.reload(); }, 2500);
      }catch(err){
        this.error = true;
        this.errormsg = err;
      }
    },

    /*async sendCode(){
      try{
        await this.post('/set_code', { code: this.code });
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t('account.code_validated'),
          position: 'is-bottom',
          type: 'is-success',
        });
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger',
        });
      }
    },*/
  },
}
</script>

<style lang="scss" scoped>
</style>
