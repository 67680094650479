<template>
  <div id="setup-steps" class="container pt-5">
    <h1 class="title is-3"> {{ $t('setup.setup')}}</h1>

    <Steps v-model="currentStep" ref="steps" :check="checkStep" :validate="validate" :validationText="$t('general.continue')">
      <ProfileStep ref="profile"/>
      <!--<SyncsStep ref="syncs"/>-->
      <OptionsStep ref="options"/>
      <SubscribeStep ref="subscribe"/>
    </Steps>
  </div>
</template>

<script>
import Steps from '@/components/Steps.vue';
//import SyncsStep from './SyncsStep.vue';
import ProfileStep from './ProfileStep.vue';
import OptionsStep from './OptionsStep.vue';
import SubscribeStep from './SubscribeStep.vue';

export default{
  name: 'SetupSteps',
  data(){
    return {
      currentStep: parseInt(localStorage.getItem('setupCurrentStep')) || 0,
      steps: [],
      isLoading: false,
    };
  },
  computed: {
    selectedPlan(){ return localStorage.getItem('selected_plan'); },
  },

  mounted(){
    this.steps = [this.$refs.profile, /*this.$refs.syncs,*/ this.$refs.options, this.$refs.subscribe];

    this.post('/log', { log: 'Start setup' }).then().catch(err=>{});

    if(this.$store.getters.user.referral && this.$store.getters.user.has_trial){
      this.$buefy.dialog.alert({
        title: this.$t('account.referrals.have_free_month'),
        message: this.$t('account.referrals.have_free_month_description', { accountType: this.$store.getters.user.account_type }),
        confirmText: this.$t('general.ok'),
      });
    }
  },

  methods: {
    checkStep(){
      return this.steps[this.currentStep].checkStep();
    },

    async validate(){
      try{
        await this.updateUserData();
        this.$buefy.dialog.confirm({
          title: this.$t('setup.continue_to_plan'),
          message: this.$t('setup.want_to_continue_to_plan'),
          confirmText: this.$t('general.yes'),
          cancelText: this.$t('general.later'),
          onConfirm: () => this.$router.push('/generate_training_plan'),
          onCancel: () => {
            this.$router.push('/dashboard');
            setTimeout(() => this.$eventBus.$emit('show-modal-syncs-if-no-syncs'), 1000);
          },
        });
        this.post('/log', { log: 'setup done' }).then().catch(err=>{});
        localStorage.removeItem('selected_plan');
      }catch(err){
        this.showError(err);
      }
    },

    showError(err){
      console.error(err);
      this.$buefy.toast.open({
        duration: 3000,
        message: this.$t(err),
        position: 'is-bottom',
        type: 'is-danger'
      });
    },

    getData(){
      return {
        birthday: this.$refs.profile.birthday,
        sex: this.$refs.profile.sex,
        weight: this.$refs.profile.weight,
        restingHeartRate: this.$refs.profile.restingHeartRate,
        equipment: {
          heart_rate_monitor: this.$refs.profile.heartRateMonitor,
          power_meter: this.$refs.profile.powerMeter,
          home_trainer_power: this.$refs.profile.home_trainer_power,
        },
      };
    },


    async updateUserData(){
      var data = this.getData();

      var measure = this.ugetLastMeasure(this.LocalDate.now());
      if((data.weight && data.weight != measure.weight) || (data.restingHeartRate && data.restingHeartRate != measure.resting_heart_rate)){

        measure.weight = data.weight || measure.weight;
        measure.resting_heart_rate = data.restingHeartRate || measure.resting_heart_rate;
        measure.date = this.LocalDate.today();
        await this.$store.dispatch('newMeasure', measure);
      }


      var infos = {
        birthday: data.birthday,
        sex: data.sex,
        equipment: data.equipment,
      };

      await this.$store.dispatch('updateUserInfos', infos);

      var settings = this.$store.getters.user.settings;
      settings.notifications = this.$refs.options.getNotifications();
      await this.$store.dispatch('updateSettings', settings);

      var onboarding = this.$store.getters.user.onboarding;
      onboarding.show_setup_steps = false;
      await this.$store.dispatch('updateOnboarding', onboarding);

      this.$buefy.toast.open({
        duration: 2000,
        message: this.$t('setup.success'),
        position: 'is-bottom',
        type: 'is-success'
      });

      localStorage.setItem('setupCurrentStep', 0);

      window.fbq('track', 'StartTrial');
    },

  },

  watch: {
    currentStep: function(){
      localStorage.setItem('setupCurrentStep', this.currentStep);
    }
  },

  components: {
    Steps,
    //SyncsStep,
    ProfileStep,
    OptionsStep,
    SubscribeStep,
  }
}
</script>

<style lang="scss">
</style>
