<template>
  <Curve dataType="globalData" curveType="heart_rate"/>
</template>

<script>
import Curve from './Curve.vue';

export default{
  name:'heartratecurve',
  components: {
    Curve
  }
}
</script>
