<script>
import DayChart from '@/helpers/DayChart.vue';

export default{
  name: 'measurechart',
  extends: DayChart,
  methods: {
    initialize(){
      DayChart.methods.initialize.call(this); //call super initialize
      this.data = [new Array(this.entries.length)];
      for(var i=0; i<this.entries.length; i++){
        var date = new Date(this.startDate.getTime()+this.ONEDAY*i);
        this.data[0][i] = { data: this.entries[i], date: date};
      }
    },

    createChart(){
      DayChart.methods.createChart.call(this);
      this.createChartElements();
    },

    createChartElements(){
      //Value to display
      this.textValue = this.svg.append("text")
        .attr("class", "text-value")
        .attr("fill", "#000")
        .attr("x", -this.margin.left/3)
        .attr("y", this.height/2)
        .attr("dy", "0.5em")
        .style("text-anchor", "middle")
        .text("0");
    },

    mouseMoveCall(event, mouse_x, mouse_y){
      if(this.data[0][this.indexOfPosition(mouse_x)])
        this.textValue.text(this.data[0][this.indexOfPosition(mouse_x)].data);
    },
  },
};
</script>
<style lang="scss" scoped>
.linechart{
  height: 100px;
}
</style>
