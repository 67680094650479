<template>
  <div class="workouttext">
    <b-field :label="$t('workout.text_description')" label-position="on-border">
      <b-input maxlength="2000" type="textarea" v-model="text" @change.native="updateSteps"/>
    </b-field>
    <p class="has-text-danger" v-if="errormsg.length > 0">{{ errormsg }}</p>
    <b-button @click="updateSteps()">{{ $t('general.update') }}</b-button>

    <b-collapse class="panel" aria-controls="contentIdForA11y2" animation="slide" v-model="isOpen">
      <template #trigger>
        <div class="panel-heading" role="button" aria-controls="contentIdForA11y2">
          <h4 class="title is-4">{{ $t('workout.text_description_help') }} <b-icon :icon="isOpen ? 'chevron-down' : 'chevron-up'"/></h4>
        </div>
      </template>
      <section class="section has-text-left" v-html="$t('workout.text_description_help_content')"/>
    </b-collapse>


  </div>
</template>

<script>
const workoutTextParser = require('@/../shared/workout/workoutTextParser');

export default{
  name: 'workout-text',
  data(){
    return {
      text: '',
      errormsg: '',
      isOpen: false,
    };
  },
  methods: {
    updateSteps(){
      this.errormsg = '';
      try{
        if(this.text.length > 0){
          if(workoutTextParser.textMatch(this.text))
            this.$emit('input', workoutTextParser.parseSteps(this.text));
          else
            this.errormsg = workoutTextParser.textMatchErrorMessage(this.text);
        }
      }catch(err){
        this.errormsg = err;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
