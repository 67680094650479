<template>
  <div class="SetModelField">
    <h2 class="title is-4">Changer son modèle</h2>
    <p>
      {{ $t('model.current_estimated_model')}}
      <span v-if="lastModel.threshold_power"><b>{{ lastModel.threshold_power }}W</b> {{ $t('metrics.ftp') }}</span>
      <span v-if="lastModel.threshold_heart_rate"><b>{{ lastModel.threshold_heart_rate }}bpm</b> {{ $t('metrics.threshold_hr') }}</span>
    </p>

    <form @submit.prevent="updateSetModel()" class="mt-3">
      <b-field grouped class="is-centered" group-multiline>
        <b-field :label="$t('metrics.threshold_power')" label-position="on-border" v-if="$store.getters.hasPremiumAccess">
          <b-input v-model.number="set_model.threshold_power" type="number" step="1"/>
        </b-field>
        <b-field :label="$t('metrics.map')" label-position="on-border" v-if="$store.getters.hasPremiumAccess">
          <b-input v-model.number="set_model.map" type="number" step="1"/>
        </b-field>
        <b-field :label="$t('metrics.threshold_heart_rate')" label-position="on-border">
          <b-input v-model.number="set_model.threshold_heart_rate" type="number" step="1"/>
        </b-field>
        <p class="control">
          <b-icon icon="close-thick" type="is-primary" size="is-medium" @click.native="resetModel()" class="is-clickable"/>
          <b-button :label="$t('general.update')" class="is-primary" @click="updateSetModel()"/>
        </p>
      </b-field>
    </form>
  </div>
</template>

<script>

export default{
  name: 'SetModelField',
  computed: {
    lastModel(){ return this.ugetLastModel(); },
    getSetModel(){ return this.$store.getters.set_model; }
  },
  data(){
    return {
      set_model: {
        threshold_power: 0,
        map: 0,
        threshold_heart_rate: 0,
        max_heart_rate: 0,
      },
    };
  },
  mounted(){
    this.setSetModel();
  },
  methods: {
    setSetModel(){
      if(this.getSetModel)
        this.set_model = this.getSetModel;
    },

    async resetModel(){
      this.set_model = {
        threshold_power: 0,
        threshold_heart_rate: 0,
        max_heart_rate: 0,
      };
      await this.updateSetModel();
    },

    async updateSetModel(){
      try{
        await this.$store.dispatch('updateSetModel', this.set_model);
        this.$buefy.toast.open({
          duration: 2000,
          message: this.$t('model.updated'),
          position: 'is-bottom',
          type: 'is-success'
        });
      }catch(err){
        console.error(err);
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
