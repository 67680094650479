<template>
  <div id="workout-creator" class="container">
    <WorkoutViewer v-bind:workout="getWorkout()" class="workout-viewer" showToolTip/>
    <div id="workout-editor" class="columns">
      <div id="workout-details" class="column">
        <b-field :label="$t('general.name')" :type="{'is-danger': errors.has('name')}" :message="errors.get('name')">
          <b-input type="text" v-model="name"/>
        </b-field>
        <b-field grouped>
          <b-field :label="$t('general.tag')">
            <b-select v-model="tag">
              <option value="recovery">{{ $t('workout.recovery') }}</option>
              <option value="endurance">{{ $t('workout.endurance') }}</option>
              <option value="tempo">{{ $t('workout.tempo') }}</option>
              <option value="threshold">{{ $t('workout.threshold') }}</option>
              <option value="vo2max">{{ $t('workout.vo2max') }}</option>
              <option value="anaerobic">{{ $t('workout.anaerobic') }}</option>
              <option value="sprint">{{ $t('workout.sprint') }}</option>
              <option value="other">{{ $t('workout.other') }}</option>
            </b-select>
          </b-field>
          <!--<b-field :label="$t('general.access')">
            <b-select v-model="access">
              <option value="public">{{ $t('general.public') }}</option>
              <option value="private">{{ $t('general.private') }}</option>
            </b-select>
          </b-field>-->
        </b-field>
        <b-field :label="$t('general.description')">
          <b-input v-model="description" type="textarea"/>
        </b-field>
        <h4 class="title is-6">{{ $t('workout.estimations') }}</h4>
        <p><b>{{ $t('metrics.load') }}:</b> {{ data.load }}   <b>{{ $t('metrics.intensity') }}:</b> {{ data.intensity }}</p>
        <p><b>{{ $t('metrics.duration') }}:</b> {{ utimeToString(getDuration()) }}</p>
        <h4 class="title is-6">{{ $t('zone.zones') }}</h4>
        <TimeInZone :zonesType="'power'" :timeInZones="timeInPowerZones()"/>
      </div>


      <div id="workout-steps" class="column">
        <b-tabs>
          <b-tab-item :label="$t('workout.steps')">
            <WorkoutStepList v-model="steps"/>
          </b-tab-item>
          <b-tab-item :label="$t('workout.text_description')">
            <WorkoutText v-model="steps"/>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
    <div id="workout-bottom-menu">
      <button class="button" @click="save()">{{ $t('general.save') }}</button>
      <button class="button" @click="exportWorkout()">{{ $t('general.export') }}</button>
      <button class="button" @click="newWorkout()">{{ $t('workout.new') }}</button>
    </div>
  </div>
</template>

<script>
import WorkoutStepList from './WorkoutStepList.vue';
import WorkoutViewer from './WorkoutViewer.vue';
import WorkoutText from './WorkoutText.vue';
import TimeInZone from '../activity/TimeInZone';
import { ObjectID } from 'bson';
const workoutUtil = require('@/../shared/workout/workoutUtil.js');


export default{
  name: 'workout-creator',
  props: ['workout'],
  data(){
    return {
      id: new ObjectID(),
      owner: null,
      access: 'public',
      name: '',
      description: '',
      tag: '',
      steps: [],
      data: {},
      errors: new Map(),
    };
  },
  computed:{
    model(){ return this.ugetSetModel(this.LocalDate.now()); }
  },
  methods: {
    getDuration(){
      return this.steps.reduce((duration, step) => duration+step.duration, 0);
    },

    getWorkout(){
      return {
        name: this.name,
        _id: this.id,
        owner: this.owner,
        access: this.access,
        tag: this.tag,
        description: this.description,
        duration: this.getDuration(),
        sport: 'cycling',
        steps: this.steps,
        data: {},
      };
    },

    updateData(){
      this.data = workoutUtil.calcData(this.getWorkout(), this.model);
    },

    timeInPowerZones(){
      return workoutUtil.timeInPowerZones({ steps: this.steps }, this.model);
    },

    check(){
      this.errors = new Map();
      if(!this.name){
        this.errors.set('name', this.$t('errors.general.enter_name'));
      }
      return this.errors.size <= 0;
    },

    save(){
      if(!this.check())
        return;

      var workout = this.getWorkout();
      this.post('/workout_library/save_workout', { workout: workout }).then(()=>{
        this.$buefy.toast.open({
          duration: 2000,
          message: this.$t('workout.saved'),
          position: 'is-bottom',
          type: 'is-success'
        });
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
        console.error(err);
      });
    },

    setWorkout(){
      if(this.workout){
        if(this.workout._id)
          this.id = this.workout._id;
        if(this.workout.owner)
          this.owner = this.workout.owner;
        this.tag = this.workout.tag;
        this.access = this.workout.access;
        this.name = this.workout.name;
        this.description = this.workout.description;
        this.duration = this.workout.duration;
        this.sport = this.workout.sport;
        this.steps = this.workout.steps;
      }
      this.updateData();
    },

    exportWorkout(){
      if(!this.check())
        return;

      this.$eventBus.$emit('show-modal-workout-exporter', { workout: this.getWorkout() });
    },

    newWorkout(){
      //todo ask if previous workout change not saved

      this.id = new ObjectID();
      this.owner = null;
      this.access = 'public';
      this.name = '';
      this.description = '';
      this.tag = '';
      this.steps = [];
      this.data = {};
    },
  },
  mounted(){
    this.setWorkout();
  },
  watch: {
    steps: {
      handler(){
        this.updateData();
      },
      deep: true
    }
  },
  components: {
    WorkoutStepList,
    WorkoutViewer,
    WorkoutText,
    TimeInZone,
  }
}
</script>

<style lang="scss" scoped>

.workout-viewer{
  width: 90%;
  height: 400px;
}

</style>
