<template>
  <div class="workout-step">
    <b-field grouped>
      <b-field>
        <b-input type="text" name="name" v-model="name"/>
      </b-field>
      <b-field :label="$t('workout.repeatitions')" label-position="on-border">
        <b-input type="number" name="repeatitions" min="1" v-model.number="repeatitions"/>
      </b-field>
    </b-field>
    <div>
      <WorkoutStepList v-model="steps" />
    </div>
  </div>
</template>

<script>

export default{
  name: 'workout-step-repeat',
  props: ['value'],
  data(){
    return {
      name: this.value.name,
      notes: this.value.notes,
      repeatitions: this.value.repeatitions,
      steps: this.value.steps,
    }
  },
  methods: {
    onChange(){
      var step = {
        type: 'REPEAT',
        name: this.name,
        notes: this.notes,
        duration: this.getDuration(),
        repeatitions: this.repeatitions,
        steps: this.steps,
      };

      this.$emit('input', step);
    },

    getDuration(){
      return this.steps.reduce((duration, step) => duration+step.duration, 0)*this.repeatitions;
    }
  },
  watch: {
    value: {
      handler(){
        this.name = this.value.name;
        this.notes = this.value.notes;
        this.repeatitions = this.value.repeatitions;
        this.steps = this.value.steps;
      },
      deep: true
    }
  },
  updated(){
    this.onChange();
  },
  components: {
    WorkoutStepList: () => import('./WorkoutStepList')
  },
}
</script>

<style lang="scss">
</style>
