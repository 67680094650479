<script>
import DayChart from '@/helpers/DayChart.vue';

export default{
  name: 'formchart',
  extends: DayChart,
  methods: {
    initialize(){
      DayChart.methods.initialize.call(this); //call initialize super
      this.data = [new Array(this.entries.length)];
      for(var i=0; i<this.entries.length; i++){
        var date = new Date(this.startDate.getTime()+this.ONEDAY*i);
        this.data[0][i] = { data: this.entries[i], date: date};
      }
    },

    createChart(){
      DayChart.methods.createChart.call(this);
      

      //Add colors to line
      this.svg.append("linearGradient")
          .attr("id", "line-gradient")
          .attr("gradientUnits", "userSpaceOnUse")
          .attr("x1", 0).attr("y1", this.axisY(-500))
          .attr("x2", 0).attr("y2", this.axisY(500))
      .selectAll("stop")
          .data([
              {offset: "0%", color: "red"},
              {offset: "47%", color: "red"},
              {offset: "47%", color: "green"},
              {offset: "49%", color: "green"},
              {offset: "50%", color: "blue"},
              {offset: "51%", color: "blue"},
              {offset: "51%", color: "orange"},
              {offset: "100%", color: "orange"},
          ])
      .enter().append("stop")
          .attr("offset", function(d) { return d.offset; })
          .attr("stop-color", function(d) { return d.color; });
    },
  },
}
</script>
<style lang="scss" scoped>
.linechart{
  height: 200px;
}
</style>
