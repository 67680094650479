<template>
  <div class="Stats" v-if="$store.getters.hasPremiumAccess">
    <h2 class="title is-4">{{ $t('dashboard.statistics') }}</h2>
    <b-tabs>
      <b-tab-item :label="$t('performance.form')">
        <TodayFitness/>
      </b-tab-item>

      <b-tab-item :label="$t('performance.performances')">
        <div class="columns is-mobile is-multiline has-text-left" v-if="model">
          <div class="column is-narrow" v-if="model.threshold_power">
            <div class="p-4 has-background-neutral-lighter">
              <p class="has-text-weight-bold has-text-neutral-dark is-uppercase is-size-6">{{ $t('metrics.ftp')}}</p>
              <p class="has-text-weight-bold has-text-primary is-size-4">{{ model.threshold_power }}W</p>
            </div>
          </div>
          <div class="column is-narrow" v-if="this.MAP">
            <div class="p-4 has-background-neutral-lighter">
              <p class="has-text-weight-bold has-text-neutral-dark is-uppercase is-size-6">{{ $t('metrics.map')}}</p>
              <p class="has-text-weight-bold has-text-primary is-size-4">{{ this.MAP }}W</p>
            </div>
          </div>
          <div class="column is-narrow" v-if="model.max_power">
            <div class="p-4 has-background-neutral-lighter">
              <p class="has-text-weight-bold has-text-neutral-dark is-uppercase is-size-6">{{ $t('metrics.pmax')}}</p>
              <p class="has-text-weight-bold has-text-primary is-size-4">{{ model.max_power }}W</p>
            </div>
          </div>

          <div class="column is-narrow" v-if="model.threshold_heart_rate">
            <div class="p-4 has-background-neutral-lighter">
              <p class="has-text-weight-bold has-text-neutral-dark is-uppercase is-size-6">{{ $t('metrics.threshold_hr')}}</p>
              <p class="has-text-weight-bold has-text-primary is-size-4">{{ model.threshold_heart_rate }}</p>
            </div>
          </div>
          <div class="column is-narrow" v-if="model.max_heart_rate">
            <div class="p-4 has-background-neutral-lighter">
              <p class="has-text-weight-bold has-text-neutral-dark is-uppercase is-size-6">{{ $t('metrics.max_heart_rate')}}</p>
              <p class="has-text-weight-bold has-text-primary is-size-4">{{ model.max_heart_rate }}</p>
            </div>
          </div>
          <div class="column is-narrow" v-if="model.resting_heart_rate">
            <div class="p-4 has-background-neutral-lighter">
              <p class="has-text-weight-bold has-text-neutral-dark is-uppercase is-size-6">{{ $t('metrics.resting_hr')}}</p>
              <p class="has-text-weight-bold has-text-primary is-size-4">{{ model.resting_heart_rate }}</p>
            </div>
          </div>
        </div>

        <div v-if="!model || !(model.threshold_power && model.threshold_heart_rate)">
          <p class="has-text-weight-bold has-text-neutral-dark is-uppercase is-size-5 has-text-left">{{ $t('model.no_current_model') }}</p>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
const PowerModel = require('@/../shared/powerModel');
import TodayFitness from '../performance/TodayFitness.vue';

export default{
  name: 'stats',
  computed:{
    model(){ return this.ugetLastModel(); },
    MAP(){ return PowerModel.modelMAP(this.model); },
  },
  components:{
    TodayFitness,
  },
};
</script>

<style lang="scss" scoped>
</style>
