<template>
  <div class="objective box">
    <div class="is-pulled-right">
      <b-tooltip :label="$t('objective.edit_objective')">
        <a @click="editObjective" class="has-text-secondary"><b-icon icon="pencil"/></a>
      </b-tooltip>
      <b-tooltip :label="$t('objective.remove_objective')">
        <a @click="removeObjective" class="has-text-secondary"><b-icon icon="delete-forever"/></a>
      </b-tooltip>
    </div>
    <p>{{ objective.date.toLocaleDateString() }}</p>

    <h3 class="title is-4">
      <b-icon v-if="objective.sport=='cycling'" icon="bike"/>
      <b-icon v-if="objective.sport=='running'" icon="run"/>
      {{ objective.name }} <span class="has-text-primary">{{ objective.priority }}</span>
    </h3>
    <div class="columns is-mobile">
      <p class="column"><b-icon icon="timer-outline"/>{{ utimeToString(objective.estimated_duration) }}</p>
      <p class="column" v-if="objective.distance"><b-icon icon="chart-timeline-variant"/>{{ showDistance(objective.distance, 0) }}</p>
      <p class="column" v-if="objective.elevation"><b-icon icon="image-filter-hdr"/>{{ showHeight(objective.elevation, 0) }}</p>
    </div>
    <b-taglist v-if="objective.skills || objective.profile_type">
      <b-tag type="is-primary" class="has-text-weight-bold" v-if="objective.profile_type">{{ $t('objective.'+objective.profile_type) }}</b-tag>
      <b-tag v-for="skill in objective.skills" :key="skill">{{ $t('objective.'+skill) }}</b-tag>
    </b-taglist>
  </div>
</template>

<script>

export default{
  name: 'objective',
  props: ['objective'],
  methods: {
    removeObjective(){
      this.$store.dispatch('removeObjective', this.objective).then(() =>{
      }).catch(err => {
         console.error(err);
      });
    },

    editObjective(){
      this.$eventBus.$emit('show-modal-new-objective', { objective: this.objective });
    },
  },
}
</script>

<style lang="scss">
</style>
