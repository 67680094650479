<template>
  <v-tour :name="name" :steps="tourSteps" :callbacks="{ onSkip: onEnd, onFinish: onEnd, onNextStep: onNextStep, onPreviousStep: onPreviousStep }" :options="options"/>
</template>

<script>

export default{
  name: 'Tour',
  props: {
    name: { type: String },
    steps: { type: Array },
    start: { type: Boolean, default: false },
    onboardingVariable: { type: String },
  },
  computed: {
    tourSteps(){
      let tourSteps = [];
      for(let i=0; i<this.steps.length; i++){
        let step = this.steps[i];
        tourSteps.push({
          target: '.'+this.name+'-'+i,
          content: this.$t(step.content),
          params: step.params || {},
          onNext: step.onNext || (() => {}),
          onPrevious: step.onPrevious || (() => {}),
        });
      }
      return tourSteps;
    },

    options(){
      return {
        labels: {
          buttonSkip: this.$t('onboarding.skip'),
          buttonPrevious: this.$t('onboarding.previous'),
          buttonNext: this.$t('onboarding.next'),
          buttonStop: this.$t('onboarding.finish'),
        }
      };
    }
  },
  data(){
    return {
    };
  },
  methods: {
    startTour(){ this.$tours[this.name].start(); },

    async onEnd(){
      this.onNextStep(this.steps.length-1);
      if(this.onboardingVariable){
        let onboarding = this.$store.getters.onboarding;
        onboarding[this.onboardingVariable] = false;
        await this.$store.dispatch('updateOnboarding', onboarding);
      }
    },

    onNextStep(currentStep){
      this.tourSteps[currentStep].onNext();
    },

    onPreviousStep(currentStep){
      this.tourSteps[currentStep].onPrevious();
    },

  },
  mounted(){
    if(this.start)
      this.startTour();
  },

  watch: {
    start(){
      if(this.start)
        this.startTour();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
