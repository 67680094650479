<template>
  <div class="referrals">
    <div class="mt-5">
      <section>
        <h1 class="title is-2">{{ $t('account.referrals.referrals') }}</h1>
        <h3 class="subtitle">{{ $t('account.referrals.offer_month') }}</h3>
      </section>

      <section class="mt-5">
        <p v-html="$t('account.referrals.description', { type: offerType() })"/>
        <p class="has-text-weight-bold has-text-black is-size-2">{{ $store.getters.user._id }}</p>
      </section>
    </div>
  </div>
</template>

<script>

export default{
  name: 'Referrals',
  methods: {
    offerType(){
      if(this.$store.getters.hasPremiumAccess && !this.$store.getters.hasTrial)
        return 'Premium';
      return 'Standard';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
