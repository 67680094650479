<template>
  <div class="TimeInZoneDonut columns is-mobile is-vcentered">
    <div class="column is-narrow">
      <canvas ref="canvas" width="120" height="120"/>
    </div>
    <div class="column has-text-left is-narrow">
      <template v-for="(zone, i) in zonesData">
        <p :key="i" v-if="zone.time > 0">
          <span :class="'has-text-'+zonesStyles[i].color">⬤</span>
          <span class="has-text-weight-semibold">{{ zone.zone }}</span>
          <span v-if="showTargets">({{ zone.desc }})</span>
          <span :class="zonesStyles[i].text">({{ utimeToString(zone.time)}})</span>
        </p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name:'TimeInZoneDonut',
  props: {
    data: {
      type: Array,
      default: function(){ return []; },
    },
    zonesType: {
      type: String,
      required: true,
    },
    model: {
      type: Object,
      default: function(){ return null; },
    },
    timeInZones: {
      type: Array,
      default: function(){ return null; },
    },
    showTargets: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getModel(){ return this.model || this.ugetLastModel(); },
    zonesColors(){
      return ['#4582CE', '#2AA876', '#FDCA4F', '#F19C65', '#CE4D45', '#A3322B', '#7A2BA0'];
    },
    zonesStyles(){
      return [
        { color: 'chart-blue',    background: 'is-chart-blue-lighter',   text: 'has-text-chart-blue-dark', },
        { color: 'chart-green',   background: 'is-chart-green-lighter',  text: 'has-text-chart-green-dark', },
        { color: 'chart-yellow',  background: 'is-chart-yellow-lighter', text: 'has-text-chart-yellow-darker', },
        { color: 'chart-orange',  background: 'is-chart-orange-lighter', text: 'has-text-chart-orange-dark', },
        { color: 'chart-red',     background: 'is-chart-red-lighter',    text: 'has-text-chart-red-dark', },
        { color: 'chart-red-dark',background: 'is-chart-red-lighter',    text: 'has-text-chart-red-darker', },
        { color: 'chart-purple',  background: 'is-chart-purple-lighter', text: 'has-text-chart-purple-dark', },
      ]
    }
  },
  data() {
    return {
      zonesData: []
    };
  },
  methods: {
    drawDonutChart() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Set the chart properties
      const chartRadius = 60;
      const chartInnerRadius = 30;
      const chartCenterX = canvas.width / 2;
      const chartCenterY = canvas.height / 2;

      // Draw the chart
      let startAngle = 0;
      for(let i = 0; i < this.zonesData.length; i++) {
        const sliceAngle = (2 * Math.PI * this.zonesData[i].percent) / 100;
        const endAngle = startAngle + sliceAngle;

        ctx.fillStyle = this.zonesColors[i];
        ctx.beginPath();
        ctx.arc(
          chartCenterX,
          chartCenterY,
          chartRadius,
          startAngle,
          endAngle
        );
        ctx.arc(
          chartCenterX,
          chartCenterY,
          chartInnerRadius,
          endAngle,
          startAngle,
          true
        );
        ctx.closePath();
        ctx.fill();

        startAngle = endAngle;
      }

      // Add text in the center of the chart
      //const chartTotal = chartData.reduce((a, b) => a + b, 0);
      const chartText = '';//chartTotal + "%";
      ctx.fillStyle = "#000";
      ctx.font = "12px Roboto";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(chartText, chartCenterX, chartCenterY);
    },

    //Width and color of each zones bar
    getStyle(percent){
      var highestPercent = Math.max.apply(Math, this.zonesData.map(function(zone) { return zone.percent; }));
      const WIDTH = this.$root.isMobile() ? 120 : 200; //200px width for the biggest bar (120 on mobile)
      var width = Math.round(WIDTH * (percent/highestPercent)); //width according to zone percent and highest zone percent
      return 'width: '+width.toString()+'px;'
    },

    //Power zones from threshold power
    powerZonesDescription(){
      var ftp = this.getModel.threshold_power || 0;
      return [
        { zone : this.$t('zone.recovery'), desc: '0-'+Math.round(ftp*0.55).toString()+'W', limit: [0, Math.round(ftp*0.55)] },
        { zone : this.$t('zone.endurance'), desc: Math.round(ftp*0.55).toString()+'-'+Math.round(ftp*0.75).toString()+'W', limit: [Math.round(ftp*0.55), Math.round(ftp*0.75)] },
        { zone : this.$t('zone.tempo'), desc: Math.round(ftp*0.75).toString()+'-'+Math.round(ftp*0.9).toString()+'W', limit: [Math.round(ftp*0.75), Math.round(ftp*0.9)] },
        { zone : this.$t('zone.threshold'), desc: Math.round(ftp*0.9).toString()+'-'+Math.round(ftp*1.05).toString()+'W', limit: [Math.round(ftp*0.9), Math.round(ftp*1.05)] },
        { zone : this.$t('zone.vo2max'), desc: Math.round(ftp*1.05).toString()+'-'+Math.round(ftp*1.2).toString()+'W', limit: [Math.round(ftp*1.05), Math.round(ftp*1.2)] },
        { zone : this.$t('zone.anaerobic'), desc: Math.round(ftp*1.2).toString()+'-'+Math.round(ftp*1.5).toString()+'W', limit: [Math.round(ftp*1.2), Math.round(ftp*1.5)] },
        { zone : this.$t('zone.sprint'), desc: Math.round(ftp*1.5).toString()+'+ W', limit: [Math.round(ftp*1.5), 1000000] },
      ];
    },

    //Hr zones from threshold heart rate
    hrZonesDescription(){
      var fhr = this.getModel.threshold_heart_rate || 0;
      return [
        { zone : this.$t('zone.recovery'), desc: '0-'+Math.round(fhr*0.68).toString()+'bpm', limit: [0, Math.round(fhr*0.68)] },
        { zone : this.$t('zone.endurance'), desc: Math.round(fhr*0.68).toString()+'-'+Math.round(fhr*0.83).toString()+'bpm', limit: [Math.round(fhr*0.68), Math.round(fhr*0.83)] },
        { zone : this.$t('zone.tempo'), desc: Math.round(fhr*0.83).toString()+'-'+Math.round(fhr*0.95).toString()+'bpm', limit: [Math.round(fhr*0.83), Math.round(fhr*0.95)] },
        { zone : this.$t('zone.threshold'), desc: Math.round(fhr*0.95).toString()+'-'+Math.round(fhr*1.05).toString()+'bpm', limit: [Math.round(fhr*0.95), Math.round(fhr*1.05)] },
        { zone : this.$t('zone.vo2max'), desc: Math.round(fhr*1.05).toString()+'+ bpm', limit: [Math.round(fhr*1.05), 1000] },
      ];
    },

    zonesDescription(){
      switch(this.zonesType){
        case 'power': return this.powerZonesDescription();
        case 'hr': return this.hrZonesDescription();
      }
      console.error('undefined zone type : ' + this.zonesType);
      return [];
    },

    //Parse data into each zone to calculate time in zone and percent
    calcZonesData(){
      var zones = this.zonesDescription();
      this.zonesData = [];

      if(this.timeInZones){ //If time in zones already given as parameter, no need to calculate from data array
        var totalDuration = this.timeInZones.reduce((acc, val) => acc + val, 0);
        var showDesc = Boolean(zones[0].limit[1]); //Only show description if there was threshold value inside the given model (if it's the case the first upper limit is > 0)
        for(var i=0; i<zones.length; i++){
          let zone = zones[i];
          this.zonesData.push({ zone: zone.zone, desc: showDesc ? zone.desc : '', color: zone.color, time: new Date(this.timeInZones[i]), percent: 100*this.timeInZones[i]/totalDuration });
        }
        return;
      }


      for(var zone of zones){
        var nb = 0;
        for(var d of this.data){
          if(d.data >= zone.limit[0] && d.data < zone.limit[1])
            nb++;
        }
        this.zonesData.push({ zone: zone.zone, desc: zone.desc, color: zone.color, time: new Date(nb), percent: 100*nb/this.data.length });
      }
    }
  },
  watch: {
    //update on every change in data
    data(){
      this.calcZonesData();
      this.drawDonutChart();
    },
    timeInZones(){
      this.calcZonesData();
      this.drawDonutChart();
    },
    model(){
      this.calcZonesData();
      this.drawDonutChart();
    },
  },
  mounted(){
    this.calcZonesData();
    this.drawDonutChart();
  },
};
</script>

<style lang="scss" scoped>
  canvas {
    display: block;
    margin: auto;
  }

.zoneDef{
  border-collapse:collapse;
  border: 1px solid black;
}
.zoneDef td{
  border-bottom: 1px solid black;
}

.zone-rect{
  height: 21px;
  border: 1px solid black;
}

.time-in-zone
  .zone-1{ background-color: $chart-blue; }
  .zone-2{ background-color: $chart-green; }
  .zone-3{ background-color: $chart-yellow; }
  .zone-4{ background-color: $chart-orange; }
  .zone-5{ background-color: $chart-red; }
  .zone-6{ background-color: $chart-red-dark; }
  .zone-7{ background-color: $chart-purple; }
</style>
