<template>
  <div class="my-plan-data">
    <h2 class="title is-4">{{ $t('plan.training_time')}}</h2>

    <section class="block columns is-flex is-centered">
      <b-field>
        <template #label>
          {{ $t('plan.week_training_time') }}
          <Helper :label="$t('plan.help.week_training_time')"/>
        </template>
        <DurationPicker class="is-inline-block" v-model="weekTime" :minutesIncrement="15" :maxHours="40"/>
      </b-field>
    </section>
    <section class="block columns is-centered">
      <b-field>
        <template #label>
          {{ $t('plan.estimated_annual_time') }}
          <Helper :label="$t('plan.help.estimated_annual_time')"/>
        </template>
        <p class="box has-text-weight-semibold is-size-5 is-inline-block"> {{ Math.round((weekTime * 50)/3600) }} h</p>
      </b-field>
    </section>


    <section class="block columns is-flex is-centered">
      <b-field>
        <template #label>
          {{ $t('plan.max_week_training_time') }}
          <Helper :label="$t('plan.help.max_week_training_time')"/>
        </template>
        <DurationPicker class="is-inline-block" v-model="maxWeekTime" :minutesIncrement="15" :maxHours="40"/>
      </b-field>
    </section>

    <section class="block">
      <b-field grouped group-multiline position="is-centered">
        <template #label>
          {{ $t('plan.day_training_time') }}
          <Helper :label="$t('plan.help.day_training_time')"/>
          <p v-if="$store.getters.isBaseAccount" class="has-text-weight-normal" v-html="$t('plan.free_limit_days')"/><br/>
        </template>
        <template v-for="(i, day) in (0,7)">
          <b-field :label="$t('dates.week_days.'+day)" :key="day" grouped>
            <div class="is-flex is-flex-direction-column">
              <DurationPicker v-model="daysTime[day]" :minutesIncrement="15" :maxHours="6"/>
              <TrainingTypePicker v-model="trainingTypeDays[day]"/>
            </div>
          </b-field>
        </template>
      </b-field>
    </section>



    <section class="block pt-5">
      <h2 class="title is-5 mb-1">{{ $t('plan.plan_data')}}</h2>

      <b-field :label="$t('plan.training_level')">
        <template #label>
          {{ $t('plan.training_level') }}
          <Helper position="is-left">
            <template v-slot:content>
              <p v-html="$t('plan.help.training_level')"/>
            </template>
          </Helper>
        </template>
        <b-select v-model.number="trainingLevel">
          <option value="1">{{ $t('plan.levels[0]') }}</option>
          <option value="2">{{ $t('plan.levels[1]') }}</option>
          <option value="3">{{ $t('plan.levels[2]') }}</option>
          <option value="4">{{ $t('plan.levels[3]') }}</option>
          <option value="5">{{ $t('plan.levels[4]') }}</option>
          <option value="6">{{ $t('plan.levels[5]') }}</option>
        </b-select>
      </b-field>
    </section>


    <b-button @click="regeneratePlan">{{ $t('plan.update_my_plan') }}</b-button> <br/><br/>

    <b-button @click="deletePlan" type="is-danger">{{ $t('plan.delete_my_plan') }}</b-button>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
      <div class="box">
        <h1 class="title is-2"> {{ $t('plan.updating_plan')}} </h1>
        <section class="block">
          <b-icon icon="sync" size="is-large" custom-class="mdi-spin-reverse"/>
        </section>
      </div>
    </b-loading>
  </div>
</template>

<script>
import Helper from '@/components/Helper.vue';
import DurationPicker from '@/components/DurationPicker.vue';
import TrainingTypePicker from '@/components/TrainingTypePicker.vue';

export default{
  name: 'MyPlanData',
  props: ['value'],
  data(){
    return {
      weekTime: 0,
      maxWeekTime: 0,
      daysTime: [0,0,0,0,0,0,0],
      trainingTypeDays: ['outdoor', 'outdoor', 'outdoor', 'outdoor', 'outdoor', 'outdoor', 'outdoor'],
      isLoading: false,
      trainingLevel: this.$store.getters.user.training_plan_data.training_level,
    };
  },
  methods: {
    //Check for base account that it's within the limit of 3 days and one indoor day
    checkForBaseAccount(){
      if(this.$store.getters.hasStandardAccess) //If not base account return
        return true;

      //Check number of training days and indoor days
      if(!(this.daysTime.filter(t => t > 0).length <= 3 && this.trainingTypeDays.filter(t => t === 'indoor').length <=1)){
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t('errors.plan.free_limit_days'),
          position: 'is-bottom',
          type: 'is-danger'
        });
        return false;
      }
      return true;
    },

    async regeneratePlan(){
      if(!this.check())
        return;


      this.isLoading = true;
      try{
        var trainingPlanData = this.$store.getters.user.training_plan_data;
        trainingPlanData.week_available_time = this.weekTime || trainingPlanData.week_available_time;
        trainingPlanData.max_week_available_time = this.maxWeekTime || trainingPlanData.week_available_time;
        trainingPlanData.available_time_days = this.daysTime;
        trainingPlanData.training_type_days = this.trainingTypeDays;
        trainingPlanData.training_level = this.trainingLevel;

        await this.$store.dispatch('updateTrainingPlanData', trainingPlanData);


        this.onSuccess();
      }catch(err){
        this.showError(err);
      }
      this.isLoading = false;
    },

    async deletePlan(){
      let self = this;
      const confirmResult = await new Promise((resolve, reject) => {
        this.$buefy.dialog.confirm({
          title: this.$t('plan.want_delete'),
          message: this.$t('plan.delete_details'),
          cancelText: this.$t('general.no'),
          confirmText: this.$t('general.delete'),
          onConfirm: () => resolve(true),
          onCancel: () => resolve(false),
        });
      });
      if(confirmResult)
        await this.$store.dispatch('deleteTrainingPlan');
    },


    async regenerateWorkouts(){
      this.isLoading = true;
      try{
        await this.$store.dispatch('regenerateWorkouts');

        this.onSuccess();
      }catch(err){
        this.showError(err);
      }
      this.isLoading = false;
    },

    async updateGlobalTime(){
      if(!this.check())
        return;

      this.isLoading = true;
      try{
        var trainingPlanData = this.$store.getters.user.training_plan_data;
        trainingPlanData.week_available_time = this.weekTime || trainingPlanData.week_available_time;
        trainingPlanData.max_week_available_time = this.maxWeekTime || trainingPlanData.week_available_time;
        trainingPlanData.available_time_days = this.daysTime;
        trainingPlanData.training_type_days = this.trainingTypeDays;

        await this.$store.dispatch('updateTrainingPlanData', trainingPlanData);

        this.onSuccess();
      }catch(err){
        this.showError(err);
      }
      this.isLoading = false;
    },

    check(){
      var totalDaysTime = this.daysTime.reduce((acc, time) => acc + time, 0);
      var daysTraining = this.daysTime.reduce((acc, time) => acc + (time > 0 ? 1 : 0), 0);

      if(this.weekTime === 0){
        this.showError('errors.plan.week_time_0');
        return false;
      }

      if(totalDaysTime == 0){
        this.showError('errors.plan.days_time_0');
        return false;
      }

      if(daysTraining < 2){
        this.showError('errors.plan.training_days_less_2');
        return false;
      }

      //if(totalDaysTime < this.maxWeekTime){
      //  this.showError('errors.plan.days_time_inferior_max_week_time');
      //  return false;
      //}

      if(!this.checkForBaseAccount())
        return false;

      return true;
    },

    showError(err){
      this.$buefy.toast.open({
        duration: 5000,
        message: this.$t(err),
        position: 'is-bottom',
        type: 'is-danger'
      });
    },

    onSuccess(){
      this.$buefy.toast.open({
        duration: 2000,
        message: this.$t('plan.time_updated'),
        position: 'is-bottom',
        type: 'is-success'
      });
    },
  },
  mounted(){
    const training_plan_data = this.$store.getters.user.training_plan_data;
    if(training_plan_data){
      this.weekTime = training_plan_data.week_available_time || 0;
      this.maxWeekTime = training_plan_data.max_week_available_time || this.weekTime;
      if(training_plan_data.available_time_days && training_plan_data.available_time_days.length == 7)
        this.daysTime = training_plan_data.available_time_days.map(t => t || 0);

      if(training_plan_data.training_type_days && training_plan_data.training_type_days.length == 7)
        this.trainingTypeDays = training_plan_data.training_type_days.map(t => t || 0);
    }
  },
  watch: {
    weekTime(){
      if(this.weekTime > this.maxWeekTime)
        this.maxWeekTime = this.weekTime;
    },
    maxWeekTime(){
      if(this.maxWeekTime < this.weekTime)
        this.weekTime = this.maxWeekTime;
    },
  },
  components: {
    Helper,
    DurationPicker,
    TrainingTypePicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
