<template>
  <div id="dashboard" class="p-4">
    <h1 class="title is-1 has-text-left">{{ $t('general.welcome') }}</h1>

    <div class="columns" v-if="$root.isDesktop()">
      <div class="column is-two-thirds is-flex is-flex-direction-column">
        <div class="">
          <WeekSummary/>
        </div>
        <div class="">
          <Stats/>
        </div>
      </div>
      <div class="column">
          <NextWorkouts/>
          <NoTrainingPlanMessage/>
      </div>
    </div>

    <div v-else>
      <WeekSummary/>
      <NextWorkouts/>
      <NoTrainingPlanMessage/>
      <Stats/>
    </div>

    <PlusButton/>
  </div>
</template>

<script>
import WeekSummary from './WeekSummary.vue';
import NextWorkouts from './NextWorkouts.vue';
import Stats from './Stats.vue';
import NoTrainingPlanMessage from '@/components/NoTrainingPlanMessage.vue';
import PlusButton from '@/components/PlusButton.vue';

export default {
  name: 'dashboard',
  data() {
    return {
    };
  },
  computed: {
    activities() { return this.$store.getters.activities.slice(1).slice(-5).reverse(); }
  },
  methods: {

  },
  mounted: function(){

  },
  components: {
    WeekSummary,
    NextWorkouts,
    Stats,
    NoTrainingPlanMessage,
    PlusButton,
  },
};
</script>

<style lang="scss">

</style>
