<template>
  <div class="today-fitness columns is-mobile is-multiline has-text-left">
    <div class="column is-narrow">
      <div class="p-4 has-background-neutral-lighter">
        <p class="has-text-weight-bold has-text-neutral-dark is-uppercase is-size-6">{{ $t('performance.fatigue')}}</p>
        <p class="has-text-weight-bold has-text-primary is-size-4">{{ atl }}</p>
      </div>
    </div>
    <div class="column is-narrow">
      <div class="p-4 has-background-neutral-lighter">
        <p class="has-text-weight-bold has-text-neutral-dark is-uppercase is-size-6">{{ $t('performance.fitness')}}</p>
        <p class="has-text-weight-bold has-text-primary is-size-4">{{ ctl }}</p>
      </div>
    </div>
    <div class="column is-narrow">
      <div class="p-4 has-background-neutral-lighter">
        <p class="has-text-weight-bold has-text-neutral-dark is-uppercase is-size-6">{{ $t('performance.form')}}</p>
        <p class="has-text-weight-bold has-text-primary is-size-4">{{ tsb }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default{
  name: 'today-fitness',
  data(){
    return {
      ctl: 0,
      atl: 0,
      tsb: 0,
    };
  },
  computed: {
    activities(){ return this.$store.getters.activities; },
  },
  methods: {
    calcFitness(){
      var loadMap = new Map();

      var minTime = this.LocalDate.today();

      const forActivities = (activity) => {
        var d = activity.date.dateFormat();
        if(activity.date > new this.LocalDate(0)){ //Remove odd activity that start at 0
          if(activity.date.getTime() < minTime)
            minTime = new this.LocalDate(d);

          if(!loadMap[d])
            loadMap[d] = 0;
          loadMap[d] += activity.data.load;
        }
      }
      this.activities.forEach(activity => forActivities(activity));

      var nbDays = this.LocalDate.today().daysDiff(minTime);

      this.ctl = 0;
      this.atl = 0;
      this.tsb = 0;

      for(let i=1; i <= nbDays; i++){
        let day = minTime.addDay(i);
        let load = loadMap[day.dateFormat()];
        load = load ? load : 0;
        this.ctl = this.ctl + (load-this.ctl)/42;
        this.atl = this.atl + (load-this.atl)/7;
      }

      this.ctl = Math.round(this.ctl);
      this.atl = Math.round(this.atl);
      this.tsb = this.ctl - this.atl;
    }
  },

  mounted(){
    this.calcFitness();
  },

  watch: {
    activities: function(){
      this.calcFitness();
    },
  },
};
</script>

<style lang="scss" scoped>
.today-fitness{

}
</style>
