<template>
  <div v-if="!$planExist()" class="box">
    <h2 class="title is-3">{{ $t('plan.no_current_plan') }}</h2>
    <b-button @click="$router.push('/generate_training_plan')" class="is-primary">
      {{ $t('plan.generate_training_plan') }}
    </b-button>
  </div>
</template>

<script>

export default{
  name: 'no-trainingplan-message',
};
</script>

<style lang="scss" scoped>
</style>
