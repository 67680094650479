<template>
  <div class="notifications">
    <h2 class="title is-5">{{ $t('notifications.notifications') }}</h2>
    <div v-if="askForNotifications">
      <p>{{ $t('notifications.enable_devices') }} <b-button @click="enableNotifications">{{ $t('notifications.enable') }}</b-button></p>
    </div>
    <p v-if="!notificationsSupport" class="has-text-weight-bold has-text-accent-darker">{{ $t('notifications.not_supported') }}</p>

    <div class="columns is-mobile is-vcentered">
      <div class="column">
      </div>
      <div class="column is-narrow-mobile">
        <h6 class="title is-6">{{ $t('notifications.notifications') }}</h6>
      </div>
      <div class="column is-narrow-mobile">
        <h6 class="title is-6">{{ $t('notifications.mail') }}</h6>
      </div>
    </div>

    <div class="columns is-mobile is-vcentered">
      <div class="column has-text-left">
        <h6 class="title is-6 mb-0">{{ $t('notifications.day_workout') }}</h6>
        <p>{{ $t('notifications.day_workout_text') }}</p>
      </div>
      <div class="column is-narrow-mobile">
        <b-switch size="is-medium" v-model="settingsNotifications.day_workout_notification"/>
      </div>
      <div class="column is-narrow-mobile">
        <b-switch size="is-medium" v-model="settingsNotifications.day_workout_mail"/>
      </div>
    </div>

    <div class="columns is-mobile is-vcentered">
      <div class="column has-text-left">
        <h6 class="title is-6 mb-0">{{ $t('notifications.model_change') }}</h6>
        <p>{{ $t('notifications.model_change_text') }}</p>
      </div>
      <div class="column is-narrow-mobile">
        <b-switch size="is-medium" v-model="settingsNotifications.model_change_notification"/>
      </div>
      <div class="column is-narrow-mobile">
        <b-switch size="is-medium" v-model="settingsNotifications.model_change_mail"/>
      </div>
    </div>
  </div>
</template>

<script>

const publicVapidKey = 'BAmJ28LnBpO8KlfmYBAuGUetS--Xj0vZ2vL2Ncy1UdmCjkG9PiIMId0b5cHUuHbp9KS6mXxO8_GYSffGOtQep0o';

// Copied from the web-push documentation
const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  //eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export default{
  name: 'notifications',
  computed:{
    notificationsSupport(){ return 'PushManager' in window && 'serviceWorker' in navigator; },
    askForNotifications(){ return true;  this.notificationsSupport && !(Notification.permission === 'granted' || Notification.permission === 'denied'); },
  },
  data(){
    return {
      settingsNotifications: {
        day_workout_notification: false,
        day_workout_mail: false,
        model_change_notification: false,
        model_change_mail: false,
      },
    };
  },
  methods: {
    async enableNotifications(){
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
      });

      await this.post('/notifications/subscribe', subscription);
    },

    getNotifications(){
      return this.settingsNotifications;
    },

    dispatch(){
      let settings = this.$store.getters.user.settings;
      settings.notifications = this.settingsNotifications;
      this.$store.dispatch('updateSettings', this.settings).then(()=>{
        this.$buefy.toast.open({
          duration: 2000,
          message: this.$t('settings.settings_saved'),
          position: 'is-bottom',
          type: 'is-success'
        });
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
      });
    },
  },
  watch: {
    settingsNotifications: {
      async handler(newValue){
        //If can ask for notifications and a notification is being enabled
        if(this.askForNotifications && (newValue.day_workout_notification || newValue.model_change_notification)){
          if(!(await this.get('/notifications/have_subscription')).have_subscription) //check if there is already a subscription
            await this.enableNotifications(); //If not enable subscription
        }
      },
      deep: true,
    },
  },
  mounted(){
    this.settingsNotifications = this.$store.getters.user.settings.notifications;
  },
};
</script>

<style lang="scss" scoped>
</style>
