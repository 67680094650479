<template>
  <div v-if="$root.isMobile()">
    <b-button v-if="!isActive" icon-right="plus" type="is-primary" class="plus-button is-circle calendar-tour-1"
      size="is-large" @click="addElement"/>

    <div v-if="isActive" class="plus-background" @click="stop">
      <div class="plus-menu has-text-white has-text-right">
        <div class="mb-4">
          <p class="mb-2">
            {{ $t('activity.activity') }}
            <b-button icon-right="bike" class="is-circle v-align" size="is-medium" @click="addActivity"/>
          </p>

          <p class="mb-2">
            {{ $t('measure.measure') }}
            <b-button icon-right="ruler" class="is-circle v-align" size="is-medium" @click="addMeasure"/>
          </p>

          <p class="mb-2 calendar-tour-4">
            {{ $t('workout.training') }}
            <b-button icon-right="alarm-plus" class="is-circle v-align" size="is-medium" @click="addTraining"/>
          </p>

          <p class="mb-2 calendar-tour-3">
            {{ $t('objective.objective') }}
            <b-button icon-right="flag-outline" class="is-circle v-align" size="is-medium" @click="addObjective"/>
          </p>

          <p class="mb-2 calendar-tour-2">
            {{ $t('plan.change_week_time') }}
            <b-button icon-right="calendar" class="is-circle v-align" size="is-medium" @click="changeWeekTime"/>
          </p>

          <p class="mb-2">
            Chat
            <b-button icon-right="comment-question" class="is-circle v-align" size="is-medium" @click="openChat"/>
          </p>
        </div>


        <b-button icon-right="close" type="is-primary" class="is-circle"
          size="is-large" @click="stop"/>
      </div>
    </div>
  </div>
</template>

<script>

export default{
  name: 'plus-button',
  props: {
    date: { type: Object, default(){ return this.LocalDate.today(); } },
  },
  data(){
    return {
      isActive: false,
    };
  },
  methods: {
    addElement(){
      this.isActive = true;
    },

    stop(){
      this.isActive = false;
    },

    addActivity(){
      this.isActive = false;
      this.$eventBus.$emit('show-modal-add-activity', { date: this.date });
    },
    addMeasure(){
      this.isActive = false;
      this.$eventBus.$emit('show-modal-new-measure', { date: this.date });
    },
    addTraining(){
      this.isActive = false;
      if(!this.$store.getters.hasPremiumAccess)
        this.$eventBus.$emit('show-modal-new-trainingride', { date: this.date });
      else
        this.$eventBus.$emit('show-modal-plan-training', { date: this.date }); //Only allow to plan workout for premium users
    },
    addObjective(){
      this.isActive = false;
      this.$eventBus.$emit('show-modal-new-objective', { date: this.date });
    },
    changeWeekTime(){
      this.isActive = false;
      this.$eventBus.$emit('show-modal-training-time', this.date.firstDayOfWeek());
    },
    openChat(){
      this.isActive = false;
      window.Tawk_API.maximize();
    },
  },

  mounted(){
    this.$eventBus.$on('show-plus-button-menu', () => {
      this.isActive = true;
    });
    this.$eventBus.$on('close-plus-button-menu', () => {
      this.isActive = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.plus-button{
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 39; /* Modal z-index are set to 40. So set button to 39 to stay below any modals */
}

.plus-background{
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vh;
  z-index: 38;
  background-color: rgba(0.5, 0.5, 0.5, 0.5);
}

.plus-menu{
  position: fixed;
  bottom: 80px;
  right: 20px;
  padding: 0;
}
</style>
