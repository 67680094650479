<script>
import DayChart from '@/helpers/DayChart.vue';

export default{
  name: 'loadchart',
  extends: DayChart,
  props: {
    ctl: Array,
    atl: Array,
  },
  methods: {
    initialize(){
      DayChart.methods.initialize.call(this); //call initialize super
      this.data = [new Array(this.ctl.length), new Array(this.atl.length)];
      for(var i=0; i<this.ctl.length; i++){
        var date = new Date(this.startDate.getTime()+this.ONEDAY*i);
        this.data[0][i] = { data: this.ctl[i], date: date};
        this.data[1][i] = { data: this.atl[i], date: date};
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.linechart{
  height: 400px;
}
</style>
